import React, { useEffect, useState } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { withRouter } from "react-router-dom";
import Loader from "../../components/Common/Loader";
import { toast } from "react-toastify";
import toastrOptions from "../../helpers/toastr-options/toastr-options";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Switch from "react-switch";
import Select from "react-select";
import { getGameList } from "../../services/game_api_helper";
import {
  getEditDemoDataById,
  getPinnedList,
  editDemoPage,
} from "../../services/demo_api_helper";
import { Multiselect } from "multiselect-react-dropdown";
import EditorToolbar, { formats } from "../CMS/EditorToolbar";
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from "../../helpers/StringConstant";
import { OffSymbol } from "../../components/Switch/OffSymbol";
import { OnSymbol } from "../../components/Switch/OnSymbol";
import { GoBack } from "../../components/GoBack";

const EditTrophyPage = (props) => {
  const goToListing = () => {
    props.history.push("/gameDemo");
  };

  useEffect(() => {
    gameList();
    pinnedList();
    getEditDemoData();
  }, []);
  const [isLoading, setLoading] = useState(false);
  const [optionGroup, setOptionGroup] = useState(null);
  const [selectedGame, setSelectedGame] = useState(null);

  const [contentText, setContents] = useState(null);

  const [contentFieldErrorEdit, setContentFieldErrorEdit] = useState("");
  const [selectGameError, setGameError] = useState("");
  const [activeEdit, setActiveEdit] = useState(false);
  const [errMsg, setErrorMessage] = useState("");
  const [options, setOptions] = useState([]);
  const [replaceWith, setReplaceWith] = useState(null);
  const [pinDemoEdit, setPinDemoEdit] = useState(false);
  const [data, setData] = useState({});

  const [game, setGame] = useState({});
  const gameList = () => {
    getGameList().then((res) => {
      let dataN = res
        .map((item) => ({
          ...item,
          label: item.name,
          value: item.id,
          slug: item.slug,
        }))
        .filter((item) => item.game_status == 1);
      setOptionGroup(dataN);
    });
  };
  const pinnedList = () => {
    getPinnedList().then((res) => {
      let pinnedData = res.results.map((item) => ({
        name: item.title,
        id: item.id,
      }));
      setOptions(pinnedData);
    });
  };
  const getEditDemoData = () => {
    setLoading(true);
    getEditDemoDataById(props.match.params.id).then((res) => {
      setData(res);
      setGame({
        label: res.game.name,
        value: res.game.id,
        slug: res.game.slug,
      });
      setSelectedGame(res.game.id);
      setContents(res.content);
      setActiveEdit(res.status === 1 ? true : false);
      setPinDemoEdit(res.is_pinned);
    });
    setLoading(false);
  };
  const handleSelectGroup = (selected) => {
    setSelectedGame(selected.value);
    setGame(selected);
    setGameError("");
  };
  const setContent = (content, delta, source, editor) => {
    let rules = editor.getHTML();
    setContents(rules + "");
    setContentFieldErrorEdit("");
  };

  const handleValidSubmitEdit = async (event, values) => {
    event.preventDefault();
    if (
      selectedGame === null &&
      (contentText.length === 11 || contentText === "")
    ) {
      setGameError(ERROR_MESSAGES.validGame);
      setContentFieldErrorEdit(ERROR_MESSAGES.addContent);
    } else if (selectedGame === null) {
      setGameError(ERROR_MESSAGES.validGame);
    } else if (contentText.length === 11 || contentText === "") {
      setContentFieldErrorEdit(ERROR_MESSAGES.addContent);
    } else {
      let model = {
        id: props.match.params.id,
        game_id: selectedGame,
        title: values.title,
        content: contentText,
        video: values.youtube_link,
        status: activeEdit ? 1 : 2,
        is_pinned: pinDemoEdit,
        replace_with: replaceWith === null ? 0 : replaceWith,
      };
      setLoading(true);
      await editDemoPage(model).then(
        () => {
          setLoading(false);
          toast.success(SUCCESS_MESSAGES.gameDemoUpdateSuccess, toastrOptions);
          goToListing();
          setShowRawEdit(false);
        },
        (err) => {
          setLoading(false);
          setErrorMessage(err);
          setShowRawEdit(false);
        }
      );
    }
  };
  const selectPinnedEdit = (optionsSelected) => {
    setReplaceWith(optionsSelected[0].id);
  };
  const [raw_htmledit, setRawHTMLEdit] = useState("");
  const [show_rawedit, setShowRawEdit] = useState(false);
  const handleClickShowRaw = () => {
    setShowRawEdit(!show_rawedit);
    if (show_rawedit) setRawHTMLEdit(contentText === null ? "" : contentText);

    syncViewsEdit(show_rawedit);
  };

  const syncViewsEdit = (fromRaw) => {
    if (fromRaw) {
      setContents(raw_htmledit + "");
    } else {
      setRawHTMLEdit(contentText === null ? "" : contentText);
    }
  };
  const handleChangeRawEdit = (html) => {
    setRawHTMLEdit(html + "");
    syncViewsEdit(show_rawedit);
  };
  return (
    <React.Fragment>
      <Loader showLoader={isLoading} />
      <div className="page-content">
        <Breadcrumbs breadcrumbItem={"Edit Demo page"} />
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <GoBack backUrl="/gameDemo" />
                <Row className="add-staff-member">
                  <Col className="col-lg-8 col-md-8 col-sm-8 col-12">
                    <AvForm
                      onValidSubmit={(e, v) => {
                        handleValidSubmitEdit(e, v);
                      }}
                    >
                      <div className="mb-3">
                        <label>Game Name*</label>
                        <Select
                          value={game}
                          onChange={(event) => {
                            handleSelectGroup(event);
                          }}
                          options={optionGroup}
                          name="game"
                          classNamePrefix="select2-selection"
                        />
                        <label
                          className="errorMsgGames"
                          style={{ paddingTop: "10px" }}
                        >
                          {selectGameError}
                        </label>
                      </div>
                      <div className="mb-3">
                        <AvField
                          name="title"
                          label="Add Title*"
                          placeholder="Enter here"
                          type="text"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Title is required",
                            },
                            maxLength: {
                              value: 50,
                              errorMessage: "Title can have 50 characters max",
                            },
                          }}
                          value={data.title}
                        />
                      </div>
                      <div className={show_rawedit ? "mb-3 showRaw" : "mb-3"}>
                        <label>Add Content*</label>
                        <EditorToolbar
                          toolbarId="toolbar-1"
                          onClickRaw={handleClickShowRaw}
                        />
                        <ReactQuill
                          theme="snow"
                          modules={{
                            toolbar: {
                              container: `#toolbar-1`,
                            },
                            clipboard: {
                              matchVisual: false,
                            },
                          }}
                          formats={formats}
                          onChange={setContent}
                          value={contentText}
                          placeholder="Enter content here"
                        />
                        <textarea
                          className={"raw-editor"}
                          onChange={(e) => handleChangeRawEdit(e.target.value)}
                          value={raw_htmledit}
                        />
                        <label
                          className="errorMsgGames"
                          // style={{ paddingTop: "70px" }}
                        >
                          {contentFieldErrorEdit}
                        </label>
                      </div>
                      <div className="mb-3">
                        <AvField
                          name="youtube_link"
                          label="Youtube Link*"
                          placeholder="Enter Youtube Link Here"
                          type="text"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Youtube Link is required",
                            },
                            pattern: {
                              value:
                                "^(https?://)?(www.youtube.com|youtu.be)/.+$",
                              errorMessage: "Invalid Youtube Link",
                            },
                          }}
                          value={data.video}
                        />
                      </div>

                      <div className="mt-3">
                        <Row className="align-items-center verified-check-row">
                          <Col className="col-lg-3 col-sm-3">
                            <p className="verified-check">Pin Demo</p>
                          </Col>
                          <Col className="col-lg-3 col-sm-3">
                            <Switch
                              uncheckedIcon={<OffSymbol />}
                              checkedIcon={<OnSymbol />}
                              onColor="#626ed4"
                              className={
                                pinDemoEdit
                                  ? "form-label active-toggle"
                                  : "form-label"
                              }
                              onChange={() => {
                                setPinDemoEdit(!pinDemoEdit);
                              }}
                              checked={pinDemoEdit}
                            />
                          </Col>
                        </Row>
                      </div>
                      <div className="mt-3">
                        <Row className="align-items-center verified-check-row">
                          <Col className="col-lg-3 col-sm-3">
                            <p className="verified-check">Replace With</p>
                          </Col>
                          <Col className="col-lg-9 col-sm-9">
                            <Multiselect
                              placeholder="Select"
                              options={options}
                              displayValue="name"
                              onSelect={(optionSelect) =>
                                selectPinnedEdit(optionSelect)
                              }
                              singleSelect
                            />
                          </Col>
                        </Row>
                      </div>
                      <div className="mt-3">
                        <Row className="align-items-center verified-check-row">
                          <Col className="col-lg-3 col-sm-3">
                            <p className="verified-check">Status</p>
                          </Col>
                          <Col className="col-lg-3 col-sm-3">
                            <Switch
                              uncheckedIcon={<OffSymbol />}
                              checkedIcon={<OnSymbol />}
                              onColor="#626ed4"
                              className={
                                activeEdit
                                  ? "form-label active-toggle"
                                  : "form-label"
                              }
                              onChange={() => {
                                setActiveEdit(!activeEdit);
                              }}
                              checked={activeEdit}
                            />
                          </Col>
                        </Row>
                      </div>
                      {errMsg ? <p className="error-msg">{errMsg}</p> : null}
                      <FormGroup className="mt-4">
                        <div>
                          <Button
                            type="submit"
                            color="primary"
                            className="ms-1"
                            disabled={isLoading}
                          >
                            Submit
                          </Button>
                        </div>
                      </FormGroup>
                    </AvForm>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default withRouter(EditTrophyPage);
