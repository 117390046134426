import React from "react";
import { appendSuffixToTournamentPosition } from "../../../helpers/util";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
const ScoreListModal = ({ show, scorelist, closeModal }) => {
  return (
    <Modal isOpen={show} scrollable={true} className="custom-modal w-706">
      <ModalHeader toggle={closeModal} tag="h4">
        Scores
      </ModalHeader>

      <ModalBody className="events">
        <div className="d-flex flex-wrap prizes">
          {scorelist
            .sort((first, second) => {
              return first.position > second.position ? 1 : -1;
            })
            .map((item, idx) => {
              return (
                <div key={idx} className="prize-div" id={idx}>
                  <p className="mb-0 color-white">
                    {`${item.position}${appendSuffixToTournamentPosition(
                      item.position
                    )}`}{" "}
                    Points
                  </p>
                  <p className="mb-0 theme-color">{item.points}</p>
                </div>
              );
            })}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ScoreListModal;
