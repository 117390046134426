import React from "react";
import { appendSuffixToTournamentPosition } from "../../../helpers/util";
import { useState } from "react";
import { Button } from "reactstrap";
import ScoreListModal from "../Modal/ScoreListModal";
const ScoreList = ({  score }) => {
  const [showMore, setShowMore] = useState(false);
  const [showMoreScore, setShowMoreScoreData] = useState([]);
  const handleShowMore = (showMoreScoreData) => {
    setShowMore(true);
    setShowMoreScoreData(showMoreScoreData);
  };
  const handleClose = () => {
    setShowMore(false);
    setShowMoreScoreData([]);
  };
  return (
    <>
      {score
        .sort((first, second) => {
          return first.position > second.position ? 1 : -1;
        })
        .slice(0, 3)
        .map((scoreList, id) => {
          return (
            <p key={id} className="mb-0">
              {scoreList.position}
              {appendSuffixToTournamentPosition(scoreList.position)} points - {" "} 
              {scoreList.points}
            </p>
          );
        })}
      {score.length > 3 ? (
        <Button
          onClick={() => handleShowMore(score)}
          variant="link"
          className="btn btn-primary btn-sm view-button theme-color mt-2"
        >
          View more
        </Button>
      ) : null}
      <ScoreListModal
        show={showMore}
        scorelist={showMoreScore}
        closeModal={handleClose}
      />
    </>
  );
};

export default ScoreList;
