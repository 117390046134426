import { Link, withRouter } from "react-router-dom";

import {
    Card,
    CardBody,
    Col,
    Row,
    Button,
    TabContent,
    TabPane,
    NavLink,
    NavItem,
    Nav,
    Table,
    FormGroup,
} from "reactstrap";
import {
    AvForm,
    AvField,
    AvGroup,
} from "availity-reactstrap-validation";
import { toast } from "react-toastify";
import editIcon from "../../assets/images/edit.svg";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
    getUserDetail,
    activateDeactivateUser,
    updateUserReferralSettings
} from "../../services/user_api_helper";

import React, { Component } from "react";

// Alert
import SweetAlert from "react-bootstrap-sweetalert";
import classnames from "classnames";
import LatestTransaction from "../Dashboard/latest-transaction";
import EarningTimeline from "../../helpers/EarningTimeline";
import FilterPermission from "../../helpers/FilterPermission";
import { filterOutPermissionToShowHide } from "../../helpers/PermissionUtils";
import EditReferral from "../../helpers/EditReferral";
import EditFreeLobby from "../../helpers/EditFreeLobby";
import { isEmpty } from "lodash";
import EditGameIdUser from "../../components/EditGameId/EditGameIdUser";
import { updateGameAccount } from "../../services/user_api_helper";
import IconsToolTip from "../../components/IconsToolTip";
import info from "../../assets/images/info-icon.svg";
import toastrOptions from "../../helpers/toastr-options/toastr-options";

class UserDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            loader: true,
            deactivate_alert: false,
            activate_alert: false,
            deactivateReason: null,
            activeTab: this.props.location?.data?.activeTab == "1" ? "2" : "1",
            //confirm_alert_For_Seize_Wallet: false,
            selectedUser: null,
            deactivateUserSuccessMessage: false,
            disableSwal: false,

            disableSwal2: false,
            editEarning: false,

            permission: [],
            permissionTransaction: [],
            changePermission: false,
            deletePermission: false,
            viewPermission: false,
            changePermissionTransaction: false,
            viewPermissionTransaction: false,
            editGameId: false,
            selectedGameAccount: null,
            listView: true,
            editGameData: { gaming_account: '', gaming_account_id: '', gameId: '', gameName: '' },
            tier_1_amount_free_lobby: '',
            tier_2_amount_paid_lobby: '',
            tier_system_payout_days: '',
            pay_per_sign_up_referral_system: false,
            override_pay_per_signup: false,
            override_tier_amounts: false,
            initialValues: {
                tier_1_amount_free_lobby: "",
                tier_2_amount_paid_lobby: "",
                tier_system_payout_days: "",
                pay_per_sign_up_referral_system: false,
                override_pay_per_signup: false,
                override_tier_amounts: false
            },
            disableSubmit: true
        };
    }

    componentDidMount() {
        this.setState({ loader: true });
        let userId = this.props.match.params.userId;
        if (userId) {
            this.getDetailOfUser(userId);
        }
        if (isEmpty(this.props.permission)) {
            this.setState({
                changePermission: true,
                deletePermission: true,
                viewPermission: true,
            });
        } else {
            this.callSetPermission();
        }
    }
    callSetPermission = () => {
        const type = "Users";
        const typeChange = "change_user";
        const typeDelete = "delete_user";
        const typeView = "view_user";

        const filteredPermission = FilterPermission(this.props.permission, type);
        if (filteredPermission.length !== 0) {
            const setchange =
                filteredPermission.length === 0
                    ? false
                    : filterOutPermissionToShowHide(
                        filteredPermission[0]?.permissions,
                        typeChange
                    );
            const setdelete =
                filteredPermission.length === 0
                    ? false
                    : filterOutPermissionToShowHide(
                        filteredPermission[0]?.permissions,
                        typeDelete
                    );
            const setview =
                filteredPermission.length === 0
                    ? false
                    : filterOutPermissionToShowHide(
                        filteredPermission[0]?.permissions,
                        typeView
                    );
            this.setState(
                {
                    changePermission: setchange,
                    deletePermission: setdelete,
                    viewPermission: setview,
                    permission: filteredPermission[0].permissions,
                },
                () => { }
            );
        }
        const typeTransaction = "Transaction Detail";
        const typeChangeTransaction = "change_transactiondetail";
        const typeViewTransaction = "view_transactiondetail";
        const filteredPermissionTransaction = FilterPermission(
            this.props.permission,
            typeTransaction
        );
        if (filteredPermissionTransaction.length !== 0) {
            const setchangetransaction =
                filteredPermissionTransaction.length === 0
                    ? false
                    : filterOutPermissionToShowHide(
                        filteredPermissionTransaction[0]?.permissions,
                        typeChangeTransaction
                    );
            const setviewtransaction =
                filteredPermissionTransaction.length === 0
                    ? false
                    : filterOutPermissionToShowHide(
                        filteredPermissionTransaction[0]?.permissions,
                        typeViewTransaction
                    );
            this.setState(
                {
                    permissionTransaction: filteredPermissionTransaction[0].permissions,
                    changePermissionTransaction: setchangetransaction,
                    viewPermissionTransaction: setviewtransaction,
                },
                () => { }
            );
        }
    };

    enableListView = () => {
        this.setState({
            listView: true,
            editGameId: false
        });
    };

    handleDeactivateText(e) {
        e.preventDefault();
        this.setState({
            deactivateReason: e.target.value,
        });
    }

    async getDetailOfUser(userId) {
        try {
            this.setState({ loader: true });
            const res = await getUserDetail(userId);
            const profile = res?.profile;
            this.setState({
                user: res, loader: false,
                pay_per_sign_up_referral_system: profile?.pay_per_sign_up_referral_system,
                tier_1_amount_free_lobby: profile?.tier_1_amount_free_lobby,
                tier_2_amount_paid_lobby: profile?.tier_2_amount_paid_lobby,
                tier_system_payout_days: profile?.tier_system_payout_days,
                override_pay_per_signup: profile?.override_pay_per_signup,
                override_tier_amounts: profile?.override_tier_amounts,
                initialValues: {
                    pay_per_sign_up_referral_system: profile?.pay_per_sign_up_referral_system,
                    tier_1_amount_free_lobby: profile?.tier_1_amount_free_lobby,
                    tier_2_amount_paid_lobby: profile?.tier_2_amount_paid_lobby,
                    tier_system_payout_days: profile?.tier_system_payout_days,
                    override_pay_per_signup: profile?.override_pay_per_signup,
                    override_tier_amounts: profile?.override_tier_amounts
                }
            });
        } catch (error) {
            this.setState({ loader: false });
        }
    }

    async deactivateUserStatus() {
        this.setState({
            disableSwal: true,
            deactivate_alert: false,
            activate_alert: false,
        });

        if (this.state.selectedUser.status === 1)
            if (!this.state.deactivateReason || this.state.deactivateReason === "") {
                return;
            }

        let currentStatus = this.state.user.status === 1 ? 2 : 1;

        await activateDeactivateUser(
            this.state.user.id,
            currentStatus,
            this.state.deactivateReason
        )
            .then(() => {
                this.setState({
                    deactivateUserSuccessMessage: true,
                    disableSwal: false,
                });

                this.closeAlert();
                setTimeout(() => {
                    this.goToListing();
                }, 2000);
            })
            .catch(() => {
                this.closeAlert();
                this.setState({
                    disableSwal: false,
                    deactivate_alert: false,
                    activate_alert: false,
                });
            });
    }

    openAlert(user) {
        this.setState({
            deactivateReason: null,
            deactivate_alert: user.status === 1,
            activate_alert: user.status === 2,
            selectedUser: user,
        });
    }

    closeAlert() {
        this.setState({
            deactivate_alert: false,
            deactivateReason: null,
            activate_alert: false,
        });
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
        if (tab == 1) {
            let userId = this.props.match.params.userId;
            if (userId) {
                this.getDetailOfUser(userId);
            }
        }
    }
    goToListing() {
        this.props.history.push("/users");
    }
    handleEditEarning = () => {
        this.setState({
            editEarning: true,
        });
    };
    handleCLose = () => {
        this.setState({
            editEarning: false,
        });
    };
    callUserDetail = () => {
        let userId = this.props.match.params.userId;
        if (userId) {
            this.getDetailOfUser(userId);
        }
    };
    handleEditGameId = (gameAccount) => {
        this.setState({
            editGameId: true,
            selectedGameAccount: gameAccount,
            listView: false,
            editGameData: { gaming_account: gameAccount.gaming_account, gaming_account_id: gameAccount.id, gameId: gameAccount.game.id, gameName: gameAccount.game.name }

        });
    }

    handleCloseGameId = () => {
        this.setState({
            editGameId: false,
            selectedGameAccount: null
        });
    }


    onEditGameId = async (model) => {
        try {
            const body = {
                gaming_account: model.payload.gaming_account,
                gaming_account_id: model.payload.gaming_account_id,
            };
            await updateGameAccount(model.payload.game_id, body);
            toast.success(`Game name for ${model.payload.game_name} ID updated successfully`, toastrOptions);
        } catch (error) {
            toast.error(error.message || "Failed to update game ID");
        }
        finally {
            this.setState({
                editGameId: false
            })
            this.setState({ loader: true });
            let userId = this.props.match.params.userId;
            if (userId) {
                this.getDetailOfUser(userId);
            }
        }
    };

    handleReferralSettingsSubmit = async (e, values) => {
        e.preventDefault();
        try {
            const settings = {
                tier_1_amount_free_lobby: this.state.tier_1_amount_free_lobby,
                tier_2_amount_paid_lobby: this.state.tier_2_amount_paid_lobby,
                tier_system_payout_days: this.state.tier_system_payout_days,
                pay_per_sign_up_referral_system: this.state.pay_per_sign_up_referral_system,
                override_pay_per_signup: this.state.override_pay_per_signup,
                override_tier_amounts: this.state.override_tier_amounts
            };

            await updateUserReferralSettings(this.state.user.id, settings);
            toast.success("Referral settings updated successfully", toastrOptions);
            this.getDetailOfUser(this.state.user.id);
            this.setState({ disableSubmit: true });
        } catch (error) {
            toast.error(error.message || "Failed to update referral settings", toastrOptions);
        }
    };

    handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        let finalValue = type === 'checkbox' ? checked : value;
        
        // Convert select value to boolean for pay_per_sign_up_referral_system
        if (name === 'pay_per_sign_up_referral_system') {
            finalValue = value === 'true';
        }
        
        this.setState(prevState => ({
            [name]: finalValue,
            disableSubmit: this.checkIfValuesUnchanged({
                ...prevState,
                [name]: finalValue
            })
        }));
    };
    checkIfValuesUnchanged = (currentState) => {
        return (
            Number(currentState.tier_1_amount_free_lobby) === Number(currentState.initialValues.tier_1_amount_free_lobby) &&
            Number(currentState.tier_2_amount_paid_lobby) === Number(currentState.initialValues.tier_2_amount_paid_lobby) &&
            Number(currentState.tier_system_payout_days) === Number(currentState.initialValues.tier_system_payout_days) &&
            currentState.pay_per_sign_up_referral_system === currentState.initialValues.pay_per_sign_up_referral_system &&
            Boolean(currentState.override_pay_per_signup) === Boolean(currentState.initialValues.override_pay_per_signup) &&
            Boolean(currentState.override_tier_amounts) === Boolean(currentState.initialValues.override_tier_amounts)
        );
    };

    render() {
        return (
            <React.Fragment>
                <div className="page-content ">
                    <Breadcrumbs breadcrumbItem="User Detail" />
                    <Row>
                        <Row className="mb-4">
                            <Col>
                                <p>
                                    <Link
                                        to={
                                            this.props.location.data?.prevPath === "/referrals"
                                                ? "/referrals"
                                                : this.props.location.data === undefined
                                                    ? "/users"
                                                    : this.props.location.data?.prevPath === "/report"
                                                        ? {
                                                            pathname: this.props.location.data?.prevPath,
                                                            state: {
                                                                active: this.props.location.data.activeTab,
                                                                filter: this.props.location.data.filter,
                                                                activeFilter:
                                                                    this.props.location.data.activeFilter,
                                                                detailView: this.props.location.data.detailView,
                                                                setDates: this.props.location.data.setDates,
                                                            },
                                                        }
                                                        : "/users"
                                        }
                                    >
                                        <i className="mdi mdi-arrow-left"></i> back
                                    </Link>
                                </p>
                            </Col>
                        </Row>
                        {this.state.loader ? (
                            <div class="spinner-grow spinner-class" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        ) : this.state.editGameId ? (
                            <Row>
                                <Col md={12}>
                                    <Card className="mb-0">
                                        <CardBody>
                                            <EditGameIdUser
                                                enableListView={this.enableListView}
                                                editGameIdData={this.state.editGameData}
                                                editGameId={this.onEditGameId}
                                            //   data={props.data}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>) : (
                            <Col lg={12}>
                                <Nav tabs className="user-detail-tabs">
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: this.state.activeTab === "1",
                                            })}
                                            onClick={() => {
                                                this.toggle("1");
                                            }}
                                        >
                                            <span className="d-block d-sm-none">
                                                <i className="fas fa-home"></i>
                                            </span>
                                            <span className="d-none d-sm-block">User Details</span>
                                        </NavLink>
                                    </NavItem>
                                    {this.state.permissionTransaction.length !== 0 ? (
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: this.state.activeTab === "2",
                                                })}
                                                onClick={() => {
                                                    this.toggle("2");
                                                }}
                                            >
                                                <span className="d-block d-sm-none">
                                                    <i className="far fa-user"></i>
                                                </span>
                                                <span className="d-none d-sm-block">
                                                    Transaction history
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                    ) : null}
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: this.state.activeTab === "3",
                                            })}
                                            onClick={() => {
                                                this.toggle("3");
                                            }}
                                        >
                                            <span className="d-block d-sm-none">
                                                <i className="fas fa-gamepad"></i>
                                            </span>
                                            <span className="d-none d-sm-block">Game IDs</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: this.state.activeTab === "4",
                                            })}
                                            onClick={() => {
                                                this.toggle("4");
                                            }}
                                        >
                                            <span className="d-block d-sm-none">
                                                <i className="fas fa-users"></i>
                                            </span>
                                            <span className="d-none d-sm-block">Referral Settings</span>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent
                                    activeTab={this.state.activeTab}
                                    className="p-3 text-muted home-tab"
                                    style={{
                                        marginTop: "-1pc",
                                        width: "103%",
                                        marginLeft: "-15px",
                                    }}
                                >
                                    <TabPane tabId="1">
                                        <Card className="mb-0">
                                            {this.state.user && (
                                                <CardBody>
                                                    <Row>
                                                        <Col>
                                                            <h4 className="mb-4">
                                                                {this.state.user?.first_name}{" "}
                                                                {this.state.user?.last_name}
                                                            </h4>
                                                        </Col>
                                                        <Col className="col-12 mb-3">
                                                            <strong className="capitalize">
                                                                Gamer tag/Username :
                                                            </strong>{" "}
                                                            <span>{this.state.user?.username}</span>
                                                        </Col>
                                                        <Col className="col-12 mb-3">
                                                            <strong className="capitalize">
                                                                Game accounts :
                                                            </strong>{" "}
                                                            <span>
                                                                {this.state.user?.game_accounts?.length === 0
                                                                    ? "Not added any game accounts"
                                                                    : this.state.user?.game_accounts?.map(
                                                                        (item, id) => (
                                                                            <Col className="col-12" key={id}>
                                                                                {item.game.gaming_account_name} :{" "}
                                                                                {item.gaming_account}
                                                                            </Col>
                                                                        )
                                                                    )}
                                                            </span>
                                                        </Col>
                                                        <Col className="col-12 mb-3">
                                                            {Object.keys(this.state.user.referral || {})
                                                                .length === 0 ? (
                                                                <>
                                                                    <strong className="capitalize">
                                                                        {" "}
                                                                        Referral :{" "}
                                                                    </strong>
                                                                    <span>No referral used</span>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Col className="col-12">
                                                                        <strong className="capitalize">
                                                                            {" "}
                                                                            Referral Name :
                                                                        </strong>{" "}
                                                                        <span>{this.state.user.referral.name}</span>
                                                                    </Col>
                                                                    <Col className="col-12">
                                                                        <strong className="capitalize">
                                                                            Referral Code :
                                                                        </strong>{" "}
                                                                        <span>
                                                                            {this.state.user.referral.referral_code}
                                                                        </span>
                                                                    </Col>
                                                                </>
                                                            )}
                                                        </Col>

                                                        <Col className="col-12 mb-3">
                                                            <strong className="capitalize">
                                                                Social media handles :
                                                            </strong>{" "}
                                                            {Object.keys(
                                                                this.state?.user?.social_accounts || {}
                                                            ).map((item, index) => (
                                                                <Col className="col-12" key={index}>
                                                                    <span className="capitalize"> {item}</span> :{" "}
                                                                    {item === "facebook" ? (
                                                                        this.state?.user?.social_accounts[item] ===
                                                                            "Not Connected" ? (
                                                                            this.state?.user?.social_accounts[item]
                                                                        ) : this.state?.user?.social_accounts[
                                                                            item
                                                                        ] ===
                                                                            "Account is connected. Information is missing." ? (
                                                                            this.state?.user?.social_accounts[item]
                                                                        ) : (
                                                                            <Link
                                                                                to={{
                                                                                    pathname:
                                                                                        this.state?.user?.social_accounts[
                                                                                        item
                                                                                        ],
                                                                                }}
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                            >
                                                                                View facebook profile
                                                                            </Link>
                                                                        )
                                                                    ) : item === "google" ? (
                                                                        this.state?.user?.social_accounts[item] ===
                                                                            "Not Connected" ? (
                                                                            this.state?.user?.social_accounts[item]
                                                                        ) : this.state?.user?.social_accounts[
                                                                            item
                                                                        ] ===
                                                                            "Account is connected. Information is missing." ? (
                                                                            this.state?.user?.social_accounts[item]
                                                                        ) : (
                                                                            <a
                                                                                href={`mailto:${this.state?.user?.social_accounts[item]}`}
                                                                            >
                                                                                {
                                                                                    this.state?.user?.social_accounts[
                                                                                    item
                                                                                    ]
                                                                                }
                                                                            </a>
                                                                        )
                                                                    ) : item === "twitter" ? (
                                                                        this.state?.user?.social_accounts[item] ===
                                                                            "Not Connected" ? (
                                                                            this.state?.user?.social_accounts[item]
                                                                        ) : this.state?.user?.social_accounts[
                                                                            item
                                                                        ] ===
                                                                            "Account is connected. Information is missing." ? (
                                                                            this.state?.user?.social_accounts[item]
                                                                        ) : (
                                                                            //for linking future purpose
                                                                            <p>
                                                                                {
                                                                                    this.state?.user?.social_accounts[
                                                                                    item
                                                                                    ]
                                                                                }
                                                                            </p>
                                                                        )
                                                                    ) : (
                                                                        this.state?.user?.social_accounts[item]
                                                                    )}
                                                                </Col>
                                                            ))}
                                                        </Col>
                                                        <Col className="col-12 mb-3">
                                                            <strong className="capitalize">
                                                                Paypal Id :
                                                            </strong>
                                                            <span>
                                                                {this.state.user?.profile?.paypal_id || "NA"}
                                                            </span>
                                                        </Col>
                                                        <Col className="col-12 mb-3">
                                                            <strong className="capitalize">
                                                                Discord Id :
                                                            </strong>
                                                            <span>
                                                                {this.state.user?.profile?.discord_id || "NA"}
                                                            </span>
                                                        </Col>
                                                        <Col className="col-12 mb-3">
                                                            <strong className="capitalize">
                                                                Email address :
                                                            </strong>{" "}
                                                            <span>{this.state.user?.email}</span>
                                                        </Col>
                                                        <Col className="col-12 mb-3">
                                                            <strong className="capitalize">
                                                                Phone Number :{" "}
                                                            </strong>{" "}
                                                            <span>{this.state.user?.phone || "NA"}</span>
                                                        </Col>
                                                        <Col className="col-12 mb-3">
                                                            <strong className="capitalize">Street :</strong>{" "}
                                                            <span>
                                                                {this.state.user?.address?.full || "NA"}
                                                            </span>
                                                        </Col>
                                                        <Col className="col-12 mb-3">
                                                            <strong className="capitalize">
                                                                Wallet Balance :
                                                            </strong>{" "}
                                                            <span>
                                                                {"$ " +
                                                                    this.state.user?.profile?.wallet_balance ||
                                                                    "-"}
                                                            </span>
                                                        </Col>
                                                        <Col className="col-12 mb-3">
                                                            <strong className="capitalize">
                                                                Profile Status :
                                                            </strong>{" "}
                                                            <span>
                                                                {this.state.user?.profile?.is_verified
                                                                    ? "Verified"
                                                                    : "Unverified"}
                                                            </span>
                                                        </Col>
                                                        <Col className="col-12 mb-3">
                                                            <strong className="capitalize">
                                                                KYC Status :
                                                            </strong>{" "}
                                                            {this.state.user?.profile?.kyc_verification_status
                                                                ? "Verified, " +
                                                                new Date(
                                                                    this.state.user?.profile?.kyc_verification_date
                                                                ).toLocaleString("en-US", {
                                                                    weekday: "short", // long, short, narrow
                                                                    day: "numeric", // numeric, 2-digit
                                                                    year: "numeric", // numeric, 2-digit
                                                                    month: "long", // numeric, 2-digit, long, short, narrow
                                                                    hour: "numeric", // numeric, 2-digit
                                                                    minute: "numeric", // numeric, 2-digit
                                                                    second: "numeric", // numeric, 2-digit
                                                                    timeZone: "UTC",
                                                                    hour12: false,
                                                                })
                                                                : "Unverified"}
                                                        </Col>
                                                        <Col className="col-12 mb-3">
                                                            <strong className="capitalize">
                                                                Joining Date:
                                                            </strong>{" "}
                                                            {new Date(
                                                                this.state.user?.profile?.affiliate_days_expire
                                                            ).toLocaleString("en-US", {
                                                                weekday: "short", // long, short, narrow
                                                                day: "numeric", // numeric, 2-digit
                                                                year: "numeric", // numeric, 2-digit
                                                                month: "long", // numeric, 2-digit, long, short, narrow
                                                                hour: "numeric", // numeric, 2-digit
                                                                minute: "numeric", // numeric, 2-digit
                                                                second: "numeric", // numeric, 2-digit
                                                                timeZone: "UTC",
                                                                hour12: false,
                                                            })}
                                                        </Col>
                                                        <Col className="col-12 mb-3">
                                                            <strong className="capitalize">
                                                                Free Lobby Limit:
                                                            </strong>{" "}
                                                            <span>
                                                                {this.state.user?.profile?.free_lobby_limit}
                                                            </span>
                                                        </Col>
                                                        {this.state.changePermission ? (
                                                            <div className="button-items">
                                                                {this.state.editEarning ? (
                                                                    <>
                                                                        <EarningTimeline
                                                                            userId={this.state.user.id}
                                                                            onClose={() => this.handleCLose()}
                                                                            days={
                                                                                this.state.user?.profile?.affiliate_days
                                                                            }
                                                                            callApi={() => this.callUserDetail()}
                                                                        />
                                                                        <EditReferral
                                                                            userId={this.state.user.id}
                                                                            defaultReferral={
                                                                                this.state.user?.profile?.referral_code
                                                                            }
                                                                            onClose={() => this.handleCLose()}
                                                                            callApi={() => this.callUserDetail()}
                                                                        />
                                                                        <EditFreeLobby
                                                                            userId={this.state.user.id}
                                                                            defaultFreeLobbyLimit={
                                                                                this.state.user?.profile
                                                                                    ?.free_lobby_limit
                                                                            }
                                                                            onClose={() => this.handleCLose()}
                                                                            callApi={() => this.callUserDetail()}
                                                                        />
                                                                    </>
                                                                ) : (
                                                                    <Button
                                                                        onClick={() => this.handleEditEarning()}
                                                                    >
                                                                        Edit
                                                                    </Button>
                                                                )}
                                                                <button
                                                                    className={`btn ${this.state.user.status === 1
                                                                        ? "btn-danger"
                                                                        : "btn-success"
                                                                        }`}
                                                                    onClick={() => {
                                                                        this.openAlert(this.state.user);
                                                                    }}
                                                                >
                                                                    {this.state.user.status === 1
                                                                        ? "Deactivate "
                                                                        : "Activate "}{" "}
                                                                    User
                                                                </button>
                                                            </div>
                                                        ) : null}
                                                    </Row>
                                                </CardBody>
                                            )}

                                            {this.state.deactivate_alert && (
                                                <SweetAlert
                                                    title="Deactivate User"
                                                    warning
                                                    showCancel
                                                    confirmButtonText="Yes"
                                                    confirmBtnBsStyle="success"
                                                    cancelButtonText="No"
                                                    cancelBtnBsStyle="danger"
                                                    onConfirm={() => this.deactivateUserStatus()}
                                                    onCancel={() => this.closeAlert()}
                                                    disabled={this.state.disableSwal}
                                                >
                                                    Are you sure you want to deactivate the user from the
                                                    listings?
                                                    <textarea
                                                        required
                                                        rows="5"
                                                        className="form-control"
                                                        placeholder="enter description here"
                                                        onChange={(e) => {
                                                            this.handleDeactivateText(e);
                                                        }}
                                                    ></textarea>
                                                    {this.state.deactivateReason ? null : (
                                                        <p className="text-danger fst-italic fs-6 mt-2 mb-3">
                                                            Description is required
                                                        </p>
                                                    )}
                                                </SweetAlert>
                                            )}
                                            {this.state.deactivateUserSuccessMessage ? (
                                                <SweetAlert success showConfirm={false}>
                                                    User{" "}
                                                    {this.state.user.status === 1
                                                        ? "Deactivated "
                                                        : "Activated "}{" "}
                                                    Successfully
                                                </SweetAlert>
                                            ) : null}
                                            {this.state.activate_alert && (
                                                <SweetAlert
                                                    title="Activate User"
                                                    warning
                                                    showCancel
                                                    confirmButtonText="Yes"
                                                    confirmBtnBsStyle="success"
                                                    cancelButtonText="No"
                                                    cancelBtnBsStyle="danger"
                                                    onConfirm={() => this.deactivateUserStatus()}
                                                    onCancel={() => this.closeAlert()}
                                                    disabled={this.state.disableSwal}
                                                >
                                                    Are you sure you want to activate the user from the
                                                    listings?
                                                    <p>
                                                        Deactivation Reason : <br />
                                                        {this.state.selectedUser.account_status.reason}
                                                    </p>
                                                </SweetAlert>
                                            )}
                                        </Card>
                                    </TabPane>
                                    <TabPane
                                        tabId="2"
                                        className="p-3 history-tab"
                                        style={{
                                            marginTop: "-1pc",
                                            width: "154%",
                                            marginLeft: "-15px",
                                        }}
                                    >
                                        {this.state.activeTab === "2" ? (
                                            <LatestTransaction
                                                data={this.state.user}
                                                permission={this.props.permission}
                                            />
                                        ) : null}
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <Row>
                                            <Col sm="12">
                                                <Card>
                                                    <CardBody>
                                                        <div className="table-responsive">
                                                            <Table className="table table-striped mb-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Game</th>
                                                                        <th>ID</th>
                                                                        {this.state.changePermission && <th></th>}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.user?.game_accounts?.map((account, index) => (
                                                                        <tr key={index}>
                                                                            <td>{account.game.name}</td>
                                                                            <td>{account.gaming_account}</td>
                                                                            {this.state.changePermission && (
                                                                                <td>

                                                                                    {/* <Button
                                              className="btn-sm"
                                              onClick={() => this.handleEditGameId(account)}
                                            >
                                              <i className="fas fa-edit"></i> Edit
                                            </Button> */}
                                                                                    <Button
                                                                                        color="primary"
                                                                                        className="edit-icon cmsEdit info-icon"
                                                                                        onClick={() => this.handleEditGameId(account)}
                                                                                    >
                                                                                        <IconsToolTip
                                                                                            image={editIcon}
                                                                                            altImageText="edit-icon"
                                                                                            text="Edit"
                                                                                        />
                                                                                    </Button>

                                                                                </td>
                                                                            )}
                                                                        </tr>
                                                                    ))}
                                                                    {!this.state.user?.game_accounts?.length && (
                                                                        <tr>
                                                                            <td colSpan={this.state.changePermission ? 3 : 2} className="text-center">
                                                                                No game accounts found
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="4">
                                        <Row>
                                            <Col sm="12">
                                                <Card>
                                                    <CardBody>
                                                        <AvForm onValidSubmit={(e, v) => this.handleReferralSettingsSubmit(e, v)}>
                                                            <div className="col-5 mb-3 d-flex earningTimelines-box align-items-center">
                                                                <div className="form-check form-switch form-switch-lg">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input"
                                                                        id="override_pay_per_signup"
                                                                        name="override_pay_per_signup"
                                                                        checked={Boolean(this.state.override_pay_per_signup)}
                                                                        onChange={this.handleInputChange}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="override_pay_per_signup">
                                                                        Override Pay Per Signup
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            {this.state.override_pay_per_signup && (
                                                                <div className="col-5 mb-3 d-flex earningTimelines-box">
                                                                    <AvField
                                                                        style={{ backgroundColor: 'white' }}
                                                                        name="pay_per_sign_up_referral_system"
                                                                        label={
                                                                            <>
                                                                                Enable Pay Per Signup Referral*
                                                                            </>
                                                                        }
                                                                        type="select"
                                                                        onChange={this.handleInputChange}
                                                                        value={this.state.pay_per_sign_up_referral_system.toString()}
                                                                        required
                                                                    >
                                                                        <option value={true}>Enabled</option>
                                                                        <option value={false}>Disabled</option>
                                                                    </AvField>
                                                                </div>
                                                            )}
                                                            <div className="col-5 mb-3 d-flex earningTimelines-box align-items-center">
                                                                <div className="form-check form-switch form-switch-lg">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input"
                                                                        id="override_tier_amounts"
                                                                        name="override_tier_amounts"
                                                                        checked={Boolean(this.state.override_tier_amounts)}
                                                                        onChange={this.handleInputChange}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="override_tier_amounts">
                                                                        Override The tier amounts
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            {this.state.override_tier_amounts && (
                                                                <>
                                                                    <div className="col-5 mb-3 d-flex earningTimelines-box">
                                                                        <AvGroup>
                                                                            <AvField
                                                                                name="tier_1_amount_free_lobby"
                                                                                label={
                                                                                    <>
                                                                                        Manage Tier 1 (free lobby amount)*
                                                                                        <span className="info-icon stats-infoIcon">
                                                                                            <img
                                                                                                src={info}
                                                                                                alt="info"
                                                                                                className="info-image"
                                                                                                data-tip
                                                                                                data-for="loginTip"
                                                                                            />
                                                                                            <span className="tooltiptext">
                                                                                                The amount for tier 1 free lobby referrals.
                                                                                            </span>
                                                                                        </span>
                                                                                    </>
                                                                                }
                                                                                value={this.state.tier_1_amount_free_lobby}
                                                                                onChange={this.handleInputChange}
                                                                                type="number"
                                                                                validate={{
                                                                                    required: {
                                                                                        value: true,
                                                                                        errorMessage: "Tier 1 amount is required",
                                                                                    },
                                                                                    pattern: {
                                                                                        value: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/,
                                                                                        errorMessage: "Tier 1 amount can't be negative",
                                                                                    },
                                                                                }}
                                                                            />
                                                                        </AvGroup>
                                                                        <div className="daysBox">
                                                                            <span className="input-group-addon">$</span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-5 mb-3 d-flex earningTimelines-box">
                                                                        <AvGroup>
                                                                            <AvField
                                                                                name="tier_2_amount_paid_lobby"
                                                                                label={
                                                                                    <>
                                                                                        Manage Tier 2 (paid lobby amount)*
                                                                                        <span className="info-icon stats-infoIcon">
                                                                                            <img
                                                                                                src={info}
                                                                                                alt="info"
                                                                                                className="info-image"
                                                                                                data-tip
                                                                                                data-for="loginTip"
                                                                                            />
                                                                                            <span className="tooltiptext">
                                                                                                The amount for tier 2 paid lobby referrals.
                                                                                            </span>
                                                                                        </span>
                                                                                    </>
                                                                                }
                                                                                value={this.state.tier_2_amount_paid_lobby}
                                                                                onChange={this.handleInputChange}
                                                                                type="number"
                                                                                validate={{
                                                                                    required: {
                                                                                        value: true,
                                                                                        errorMessage: "Tier 2 amount is required",
                                                                                    },
                                                                                    pattern: {
                                                                                        value: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/,
                                                                                        errorMessage: "Tier 2 amount can't be negative",
                                                                                    },
                                                                                }}
                                                                            />
                                                                        </AvGroup>
                                                                        <div className="daysBox">
                                                                            <span className="input-group-addon">$</span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-5 mb-3 d-flex earningTimelines-box">
                                                                        <AvField
                                                                            name="tier_system_payout_days"
                                                                            label={
                                                                                <>
                                                                                    Manage Tier Threshold Expiree*
                                                                                    <span className="info-icon stats-infoIcon">
                                                                                        <img
                                                                                            src={info}
                                                                                            alt="info"
                                                                                            className="info-image"
                                                                                            data-tip
                                                                                            data-for="loginTip"
                                                                                        />
                                                                                        <span className="tooltiptext">
                                                                                            No. of days Manage Tier Threshold Expiree.
                                                                                        </span>
                                                                                    </span>
                                                                                </>
                                                                            }
                                                                            value={this.state.tier_system_payout_days}
                                                                            onChange={this.handleInputChange}
                                                                            type="number"
                                                                            validate={{
                                                                                required: {
                                                                                    value: true,
                                                                                    errorMessage: "Manage Tier Threshold Expiree is required",
                                                                                },
                                                                                pattern: {
                                                                                    value: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/,
                                                                                    errorMessage: "Manage Tier Threshold Expiree can't be negative",
                                                                                },
                                                                            }}
                                                                        />
                                                                        <div className="daysBox">
                                                                            <span className="input-group-addon">Days</span>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}

                                                            {this.state.changePermission && (
                                                                <FormGroup className="mt-4">
                                                                    <div>
                                                                        <Button
                                                                            type="submit"
                                                                            color="primary"
                                                                            className="ms-1"
                                                                            disabled={this.state.disableSubmit}
                                                                        >
                                                                            Submit
                                                                        </Button>
                                                                    </div>
                                                                </FormGroup>
                                                            )}
                                                        </AvForm>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                </TabContent>
                            </Col>

                        )}
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(UserDetail);
