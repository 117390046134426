import { axiosApi } from "./index";

export async function getTrophyList(filterModel) {
  let params = new URLSearchParams();
  if (filterModel.gameFilter) params.append("game", filterModel.gameFilter);
  if (filterModel.pageSize) params.append("page_size", filterModel.pageSize);
  if (filterModel.pageNumber) params.append("page", filterModel.pageNumber);

  return await axiosApi.get("trophy/", { params }).then((response) => {
    return { data: response.results, total: response.total };
  });
}

export async function addTrophy(model) {
  return await axiosApi.post(`trophy/`, model);
}


// export async function updateTrophyPagePinStatus(trophy_id, model) {
//   return await axiosApi.put(`trophy/${trophy_id}/`, model);
// }

export async function getEditTrophyDataById(id) {
  return await axiosApi.get(`trophy_id/${id}/`);
}

export async function editTrophy(model) {
  return await axiosApi.put(`trophy/${model.id}/`, model);
}
