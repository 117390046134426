import PropTypes from "prop-types";
import React, { useState, useEffect, useRef, useCallback } from "react";
import community from "../../assets/images/community.svg";
import referrals from "../../assets/images/referrals.svg";
import globalsettings from "../../assets/images/globalsettings.svg";
import demopage from "../../assets/images/demo-icon.svg";
// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import PermissionPath from "../../helpers/PermissionPath";

const SidebarContent = (props) => {
    const [allPath, setAllPath] = useState([]);

    const ref = useRef();
    const activateParentDropdown = useCallback((item) => {
        if (props.permissions.length === 0) {
            item.classList.add("active");
            const parent = item.parentElement;
            const parent2El = parent.childNodes[1];
            if (parent2El && parent2El.id !== "side-menu") {
                parent2El.classList.add("mm-show");
            }
            if (parent) {
                parent.classList.add("mm-active");
                const parent2 = parent.parentElement;
                if (parent2) {
                    parent2.classList.add("mm-show"); // ul tag
                    const parent3 = parent2.parentElement; // li tag
                    if (parent3) {
                        parent3.classList.add("mm-active"); // li
                        parent3.childNodes[0].classList.add("mm-active"); //a
                        const parent4 = parent3.parentElement; // ul
                        if (parent4) {
                            parent4.classList.add("mm-show"); // ul
                            const parent5 = parent4.parentElement;
                            if (parent5) {
                                parent5.classList.add("mm-show"); // li
                                parent5.childNodes[0].classList.add("mm-active"); // a tag
                            }
                        }
                    }
                }
                scrollElement(item);
                return false;
            }
            scrollElement(item);
            return false;
        }
    }, []);
    // Use ComponentDidMount and ComponentDidUpdate method symultaniously
    useEffect(() => {
        if (props.permissions.length === 0) {
            const pathName = props.location.pathname;
            const initMenu = () => {
                new MetisMenu("#side-menu");
                let matchingMenuItem = null;
                const ul = document.getElementById("side-menu");
                const items = ul.getElementsByTagName("a");
                for (let i = 0; i < items.length; ++i) {
                    if (pathName === items[i].pathname) {
                        matchingMenuItem = items[i];
                        break;
                    }
                }
                if (matchingMenuItem) {
                    activateParentDropdown(matchingMenuItem);
                }
            };
            initMenu();
        }
    }, [props.location.pathname, activateParentDropdown]);

    useEffect(() => {
        ref.current.recalculate();
        const allPermissionPath = PermissionPath();
        setAllPath(allPermissionPath);
    }, []);

    // Add useEffect for updating document title
    useEffect(() => {
        const pathName = props.location.pathname;
        // Remove leading slash and capitalize first letter of each word
        const title = pathName
            .substring(1)
            .split('/')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');

        document.title = title || 'Dashboard'; // Set default title if pathname is empty
    }, [props.location.pathname]);

    const scrollElement = (item) => {
        if (item) {
            const currentPosition = item.offsetTop;
            if (currentPosition > window.innerHeight) {
                ref.current.getScrollElement().scrollTop = currentPosition - 300;
            }
        }
    };
    return (
        <React.Fragment>
            <SimpleBar ref={ref} className="vertical-simplebar">
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
                        {props.permissions.length == 0 ? (
                            <>
                                <li>
                                    <Link to="/reports" className="sidenav-links waves-effect">
                                        <i className="mdi mdi-account-group-outline"></i>
                                        <span>{props.t("Reports")}</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/staff" className="sidenav-links waves-effect">
                                        <i className="mdi mdi-account-group-outline"></i>
                                        <span>{props.t("Staff")}</span>
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/users" className="sidenav-links waves-effect">
                                        <i class="far fa-user"></i>
                                        <span>{props.t("Users")}</span>
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/games" className="sidenav-links waves-effect">
                                        <i className="mdi mdi-gamepad-variant-outline"></i>
                                        <span>{props.t("Games")}</span>
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/lobby" className="sidenav-links waves-effect">
                                        <i className="mdi mdi-account-clock-outline"></i>
                                        <span>{props.t("Lobbies")}</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/cms" className="sidenav-links waves-effect">
                                        <i className="fas fa-cog"></i>
                                        <span>{props.t("CMS")}</span>
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/complaints" className="sidenav-links waves-effect">
                                        <i className="far fa-file-alt"></i>
                                        <span>{props.t("Complaints")}</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/locationLists"
                                        className="sidenav-links waves-effect"
                                    >
                                        <i className="fa fa-ban"></i>
                                        <span>{props.t("Banned Locations")}</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/community" className="sidenav-links waves-effect">
                                        <img src={community} class="community-image" />
                                        <span>{props.t("Community")}</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/groups" className="sidenav-links waves-effect">
                                        <i className="mdi mdi-account-group-outline"></i>
                                        <span>{props.t("Groups")}</span>
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/referrals" className="sidenav-links waves-effect">
                                        <img
                                            src={referrals}
                                            class="referral-icon"
                                            style={{ maxWidth: "20px", marginRight: "9px" }}
                                        />
                                        <span>{props.t("Referrals")}</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/globalsettings"
                                        className="sidenav-links waves-effect"
                                    >
                                        <img
                                            src={globalsettings}
                                            class="globalsettings"
                                            style={{ maxWidth: "20px", marginRight: "9px" }}
                                        />
                                        <span>{props.t("Global Settings")}</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/gameDemo" className="sidenav-links waves-effect">
                                        <img
                                            src={demopage}
                                            class="demoPage"
                                            style={{ maxWidth: "20px", marginRight: "9px" }}
                                        />
                                        <span>{props.t("Game Demo ")}</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/activitylog"
                                        className="sidenav-links waves-effect"
                                    >
                                        <img
                                            src={demopage}
                                            class="demoPage"
                                            style={{ maxWidth: "20px", marginRight: "9px" }}
                                        />
                                        <span>{props.t("Activity Logs ")}</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/teams" className="sidenav-links waves-effect">
                                        <i className="mdi mdi-account-group-outline"></i>
                                        <span>{props.t("Teams")}</span>
                                    </Link>
                                </li>
                            </>
                        ) : (
                            allPath.map((item, idx) => {
                                return (
                                    <li key={idx}>
                                        <Link
                                            to={item.pathname}
                                            className={
                                                item.pathname === props.location.pathname
                                                    ? "sidenav-links waves-effect active"
                                                    : "sidenav-links waves-effect"
                                            }
                                        >
                                            <img src={item.icon} class="demoPage" />
                                            <span>
                                                {props.t(
                                                    item.label === "Logs" ? "Activity Logs" : item.label
                                                )}
                                            </span>
                                        </Link>
                                    </li>
                                );
                            })
                        )}
                    </ul>
                </div>
            </SimpleBar>
        </React.Fragment>
    );
};

SidebarContent.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
