import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, FormGroup, Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withRouter } from "react-router-dom";
import Loader from "../../components/Common/Loader";
import Select from "react-select";
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from "../../helpers/StringConstant";
import { GoBack } from "../../components/GoBack";
import { getLobbyList } from "../../services/lobby_api_helper";
import { getTournamentList } from "../../services/bracket_tournaments_api_helper";
import {
  CustomInput,
} from "reactstrap";

import { uploadFile } from "react-s3";
import { addTrophy } from "../../services/trophy_api_helper";
import { toast } from "react-toastify";
import toastrOptions from "../../helpers/toastr-options/toastr-options";



function generateRandomFilename(extension = '') {
  const timestamp = Date.now();
  const randomString = Math.random().toString(36).substring(2, 10);
  return `${timestamp}_${randomString}${extension ? `.${extension}` : ''}`;
}


const config = {
  bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  region: process.env.REACT_APP_S3_REGION_NAME,
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
  dirName: 'trophies',
};


const AddTrophyPage = (props) => {


  const goToListing = () => {
    props.history.push("/trophy");
  };

  useEffect(() => {
    setLoading(true);

    tournamentList();
    lobbyList();
    setLoading(false);

  }, []);
  const [isLoading, setLoading] = useState(false);
  const [helperImage, setHelperImage] = useState(null);

  const [textHelper, setTextHelper] = useState(true);
  const [disabled, setDisabled] = useState(true);


  const [selectedTrophyType, setSelectedTrophyType] = useState("1");
  const [selectTrophyTypeError, setSelectTrophyTypeError] = useState("");
  const [spinnerLoader, setSpinnerLoader] = useState(false);
  const [selectedLobby, setSelectedLobby] = useState(null);
  const [selectedLobbyError, setSelectedLobbyError] = useState("");
  const [selectedTournament, setSelectedTournament] = useState(null);
  const [selectedTournamentError, setSelectedTournamentError] = useState("");


  const [lobbyOptionGroup, setLobbyOptionGroup] = useState(null);
  const [tournamentOptionGroup, setTournamentOptionGroup] = useState(null);



  const [errMsg, setErrorMessage] = useState("");

  const [helperImageFieldError, setHelperImageFieldError] = useState("");
  const [errorMsgForHelper, showErrorForHelperImage] = useState({
    isErrorForHelper: false,
    errorMsgForHelper: "",
  });

  const onUpload = (
    event,
    setSpinnerLoaderFunc,
    uploadFunc,
    setImageFunc,
    setTextFunc,
    showErrorFunc,
    setDisabledFunc,
    isHelper = false
  ) => {
    setSpinnerLoaderFunc(true);
    event.preventDefault();
    const regex = new RegExp("([a-zA-Z0-9s_\\.-:])+(.jpg|.jpeg|.png)$");
    if (regex.test(event.target.value.toLowerCase())) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = () => {
          const { height, width } = image;

          if ((height === 1092 && width === 412) || isHelper) {
            const originalFile = event.target.files[0]
            const finalFile = new File([originalFile], generateRandomFilename(originalFile.name.split('.').pop()), { type: originalFile.type })
            uploadFunc(finalFile, config).then((data) => {
              setImageFunc(data.location);
              setTextFunc(false);
              setDisabledFunc(false);
              showErrorFunc({
                isError: false,
                errorMsgForHelper: "",
              });
            });
            setTimeout(() => setSpinnerLoaderFunc(false), 5000);
          } else {
            showErrorFunc({
              isError: true,
              errorMsgForHelper: "Width and Height dimension must be 412x1092",
            });
            setSpinnerLoaderFunc(false);
          }
        };
      };
    } else {
      showErrorFunc({
        isError: true,
        errorMsg: "Please select a valid Image file.",
      });
      setSpinnerLoaderFunc(false);
      setDisabledFunc(true);
      return false;
    }
  };


  const lobbyList = () => {
    getLobbyList({
      lobbyFilter: "upcoming"
    }).then((res) => {
      let dataValue = res.results.map((item) => ({
        ...item,
        label: item.name,
        value: item.id,
      }));
      setLobbyOptionGroup(dataValue);
    });
  }

  const tournamentList = () => {
    getTournamentList({
      type: "upcoming"
    }).then((res) => {
      let dataValue = res.data.map((item) => ({
        ...item,
        label: item.name,
        value: item.id,
      }));
      setTournamentOptionGroup(dataValue);
    });
  }


  const handleSelectTrophy = (selected) => {
    setSelectedTrophyType(selected.value);
    setSelectTrophyTypeError("");
  };


  const [awardEverybody, setAwardEverybody] = useState(false);


  const handleCheckAwardEverybody = (e) => {
    console.log(e.target.checked);
    setAwardEverybody(e.target.checked)
  }


  const handleSelectLobby = (selected) => {
    setSelectedLobby(selected.value);
    setSelectedTournament(null)
    setSelectedLobbyError("");
    setSelectedTournamentError("");
  };

  const handleSelectTournament = (selected) => {
    setSelectedTournament(selected.value);
    setSelectedLobby(null)
    setSelectedLobbyError("");
    setSelectedTournamentError("");
  };




  const handleValidSubmit = async (event, values) => {
    event.preventDefault();
    console.log("submiting values")
    if (selectedLobby === null && selectedTournament === null) {
      setSelectedLobbyError(ERROR_MESSAGES.lobbyOrTournamentIsRequired);
      setSelectedTournamentError(ERROR_MESSAGES.lobbyOrTournamentIsRequired);
    } else if (helperImage === null || helperImage === "") {
      setHelperImageFieldError(ERROR_MESSAGES.validFile);
    } else {
      let model = {
        lobby_id: selectedLobby,
        tournament_id: selectedTournament,
        name: values.name,
        description: values.description,
        type: 2,
        status: 1,
        image: helperImage,
        placement: awardEverybody ? 0 : values.placement,
        award_everyone: awardEverybody
      };

      console.log("model")
      console.log(model)

      setLoading(true);
      await addTrophy(model).then(
        (data) => {
          if (data != undefined) {
            toast.success(SUCCESS_MESSAGES.gameDemoAddSuccess, toastrOptions);
          }
          goToListing();
          setLoading(false);
        },
        (err) => {
          setLoading(false);
          setErrorMessage(err);
        }
      );
    }
  };




  return (
    <React.Fragment>
      <Loader showLoader={isLoading} />
      <div className="page-content">
        <Breadcrumbs breadcrumbItem={"Add Trophy page"} />
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <GoBack backUrl="/trophy" />
                <Row className="add-staff-member">
                  <Col className="col-lg-8 col-md-8 col-sm-8 col-12">
                    <AvForm
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      <div className="mb-3">
                        <AvField
                          name="name"
                          label="Name*"
                          placeholder="Enter here"
                          type="text"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Name is required",
                            },
                            maxLength: {
                              value: 50,
                              errorMessage: "Name can have 32 characters max",
                            },
                          }}
                        />
                      </div>

                      <div className="mb-3">
                        <label>Type*</label>
                        <Select
                          onChange={(event) => {
                            handleSelectTrophy(event);
                          }}
                          // disabled
                          options={[
                            { value: "1", label: "Base" },
                            { value: "2", label: "Event" },
                          ]}
                          defaultValue={{ value: "2", label: "Event" }}
                          isDisabled={true}
                          name="trophyType"
                          classNamePrefix="select2-selection"
                        />
                        {selectTrophyTypeError === "" ? null : (
                          <label className="errorMsgGames invalid-feedback">
                            {selectTrophyTypeError}
                          </label>
                        )}
                      </div>

                      <div className="mb-3">
                        <label>Upcoming Lobby</label>
                        <Select
                          onChange={(event) => {
                            handleSelectLobby(event);
                          }}
                          options={lobbyOptionGroup}
                          name="lobby"
                          classNamePrefix="select2-selection"
                        />
                        {selectedLobbyError === "" ? null : (
                          <label className="errorMsgGames invalid-feedback">
                            {selectedLobbyError}
                          </label>
                        )}
                      </div>

                      <div className="mb-3">
                        <label>Upcoming Tournament</label>
                        <Select
                          onChange={(event) => {
                            handleSelectTournament(event);
                          }}
                          options={tournamentOptionGroup}
                          name="tournament"
                          classNamePrefix="select2-selection"
                        />
                        {selectedTournamentError === "" ? null : (
                          <label className="errorMsgGames invalid-feedback">
                            {selectedTournamentError}
                          </label>
                        )}
                      </div>



                      <div className="mb-3">
                        <AvField
                          onChange={(event) => {
                            handleCheckAwardEverybody(event);
                          }}
                          name="award-everyone"
                          label="Award Everyone in Lobby"
                          type="checkbox"
                        // value={false}
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="placement"
                          label="Placement"
                          placeholder="Enter placement"
                          type="number"
                          value={1}
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Placement",
                            },
                            min: {
                              value: 1,
                              errorMessage: `Placement has to be at least 1`,
                            },
                            max: {
                              value: 10,
                              errorMessage: `Placement has to be at least 10`,
                            },
                          }}
                          disabled={awardEverybody}
                        />
                      </div>


                      <div className="mb-3">
                        <AvField
                          name="description"
                          label="Description*"
                          placeholder="Enter description here"
                          type="text"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Description is required",
                            },
                            maxLength: {
                              value: 100,
                              errorMessage: "Description can have 100 characters max",
                            },
                          }}
                        />
                      </div>



                      <div className="mb-3">
                        <FormGroup>
                          <div className="mb-3 chooseImage">
                            {" "}
                            <CustomInput
                              type="file"
                              id="exampleCustomFileBrowserHelper"
                              name="customFileHelper"
                              label={"Choose a trophy picture image file"}
                              onChange={(event) =>
                                onUpload(
                                  event,
                                  setSpinnerLoader,
                                  uploadFile,
                                  setHelperImage,
                                  setTextHelper,
                                  showErrorForHelperImage,
                                  setDisabled,
                                  true
                                )
                              }
                            />

                            {errorMsgForHelper.errorMsgForHelper.length === 0 ? null : (
                              <label
                                className={
                                  errorMsgForHelper.errorMsgForHelper.length === 0
                                    ? ""
                                    : "errorMsgGames invalid-feedback"
                                }>
                                {errorMsgForHelper.errorMsgForHelper}
                              </label>
                            )}
                            {helperImageFieldError?.length === 0 ? null : (
                              <label className={
                                helperImageFieldError?.length === 0
                                  ? ""
                                  : "errorMsgGames invalid-feedback"
                              }>
                                {helperImageFieldError?.length === 0
                                  ? errorMsgForHelper.errorMsgForHelper
                                  : helperImageFieldError}
                              </label>
                            )}
                          </div>
                        </FormGroup>
                      </div>

                      {helperImage && <div className="mb-3 preview-img">
                        <h2>{textHelper ? "Image" : "Preview Image"}</h2>
                        <div class="upload-img">
                          {spinnerLoader ? (
                            <div
                              class="spinner-grow spinner-class"
                              role="status"
                              style={{ position: "inherit" }}
                            ></div>
                          ) : (
                            helperImage && (
                              <img alt="Cropped" src={helperImage} />
                            )
                          )}
                        </div>
                      </div>}

                      {/* <div className="mt-3">
                        <Row className="align-items-center verified-check-row">
                          <Col className="col-lg-3 col-sm-3">
                            <p className="verified-check">Status</p>
                          </Col>
                          <Col className="col-lg-9 col-sm-9">
                            <Switch
                              uncheckedIcon={<OffSymbol />}
                              checkedIcon={<OnSymbol />}
                              onColor="#626ed4"
                              className={
                                active
                                  ? "form-label active-toggle"
                                  : "form-label"
                              }
                              onChange={() => {
                                setActive(!active);
                              }}
                              checked={active}
                            />
                          </Col>
                        </Row>
                      </div> */}
                      {errMsg ? <p className="error-msg">{errMsg}</p> : null}
                      <FormGroup className="mt-4">
                        <div>
                          <Button
                            type="submit"
                            color="primary"
                            className="ms-1"
                            disabled={isLoading}
                          >
                            Submit
                          </Button>
                        </div>
                      </FormGroup>
                    </AvForm>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default withRouter(AddTrophyPage);
