import React, { useEffect, useState, useCallback } from "react";

import {
    Row,
    Col,
    Card,
    CardBody,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Button,
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
    getUserList,
    activateDeactivateUser,
} from "../../services/user_api_helper";
import { Link } from "react-router-dom";

// Alert
import SweetAlert from "react-bootstrap-sweetalert";

// Paginator
import Paginator from "../../helpers/paginator/paginator";
import EarningTimeline from "../../helpers/EarningTimeline";

import edit from "../../assets/images/edit.svg";
import FilterPermission from "../../helpers/FilterPermission";
import { filterOutPermissionToShowHide } from "../../helpers/PermissionUtils";
import EditReferral from "../../helpers/EditReferral";
import { debounce } from "lodash";
import EditFreeLobby from "../../helpers/EditFreeLobby";
import { useRef } from "react";
import ClearButton from "../../components/ClearButton";
import { getFilterText } from "../../helpers/util";
import IconsToolTip from "../../components/IconsToolTip";
const UserList = (props) => {
    const [users, setUsers] = useState([]);

    const [singlebtn, setSinglebtn] = useState(false);
    const [loader, setLoader] = useState(false);

    const [deleteModal, setdeleteModal] = useState(false);
    const [activeModal, setactiveModal] = useState(false);
    const [deactivateReason, setdeactivateReason] = useState("");
    const [selectedUser, setselectedUser] = useState(null);

    const [selectedDropdown, setselectedDropdown] = useState(null);

    const [totalCount, settotalCount] = useState(null);

    // filter model
    const pageSize = 10;
    const [pageNumber, setpageNumber] = useState(1);
    const [searchTerm, setsearchTerm] = useState("");
    const [sortBy, setsortBy] = useState(null);
    const [deactivateUserSuccessMessage, setDeactivateUserSuccessMessage] =
        useState(false);
    const [disableSwal, setDisabled] = useState(false);
    const dropdownOptions = [
        {
            value: "",
            text: "All",
        },
        {
            value: "create_date",
            text: "Joining Date : Asc",
        },
        {
            value: "-create_date",
            text: "Joining Date : Desc",
        },
        {
            value: "username",
            text: "Username : Asc",
        },
        {
            value: "-username",
            text: "Username : Desc",
        },
    ];
    const [editEarning, setEditEarning] = useState(false);
    const [editIndexById, setEditIndex] = useState(null);
    const [editReferral, setEditReferral] = useState(false);
    const [editReferralIndexById, setEditReferralIndex] = useState(null);
    const [editFreeLobby, setEditFreeLobby] = useState(false);
    const [editFreeLobbyIndexById, setEditFreeLobbyIndex] = useState(null);

    const [changePermission, setChange] = useState(false);
    useEffect(() => {
        getListing();
        callSetPermission();
    }, [sortBy, searchTerm, pageNumber]);
    const callSetPermission = () => {
        const type = "Users";
        const typeChange = "change_user";
        const filteredPermission = FilterPermission(props.permission, type);
        if (filteredPermission.length !== 0) {
            const setchange = filterOutPermissionToShowHide(
                filteredPermission[0].permissions,
                typeChange
            );
            setChange(setchange);
        }
    };
    const getListing = async () => {
        try {
            setLoader(true);
            const filters = filterModel();
            const res = await getUserList(filters);
            setUsers(res.results);
            settotalCount(res.total);
            setLoader(false);
        } catch (error) {
            setLoader(false);
        }
    };
    const handleSearch = (event) => {
        let term = event.target.value.trim();
        setpageNumber(1);
        setsearchTerm(term);
    };

    async function deactivateUserStatus() {
        setLoader(true);
        setDisabled(true);
        setdeleteModal(false);
        setactiveModal(false);
        if (selectedUser.status === 1)
            if (!deactivateReason || deactivateReason === "") {
                return;
            }

        let currentStatus = selectedUser.status === 1 ? 2 : 1;

        await activateDeactivateUser(
            selectedUser.id,
            currentStatus,
            deactivateReason
        )
            .then(() => {
                setDisabled(false);
                setDeactivateUserSuccessMessage(true);
                setdeactivateReason("");
                getListing();
                setTimeout(() => {
                    setDeactivateUserSuccessMessage(false);
                }, 2000);
                closeAlert();
            })
            .catch(() => {
                setDisabled(false);
                closeAlert();
                setdeleteModal(false);
                setactiveModal(false);
            });
    }

    const openAlert = (selectedUserAlert) => {
        setselectedUser(selectedUserAlert);
        setdeactivateReason("");
        selectedUserAlert.status === 1
            ? setdeleteModal(true)
            : setactiveModal(true);
    };

    const closeAlert = () => {
        setdeactivateReason("");
        setdeleteModal(false);
        setactiveModal(false);
    };

    const dropdownChange = (value) => {
        setselectedDropdown(value);
        setsortBy(value);
    };

    function filterModel() {
        let model = {
            searchTerm: searchTerm,
            sortBy: sortBy,
            pageNumber: pageNumber,
            pageSize: pageSize,
        };
        return model;
    }

    const handlePageClick = (pageNum) => {
        setpageNumber(pageNum);
    };
    const handleEditEarning = (id) => {
        setEditEarning(true);
        setEditIndex(id);
    };
    const handleEditReferral = (id) => {
        setEditReferral(true);
        setEditReferralIndex(id);
    };
    const handleCLose = () => {
        setEditEarning(false);
        setEditIndex(null);
    };
    const handleCLoseReferral = () => {
        setEditReferral(false);
        setEditReferralIndex(null);
    };
    const debouncedResults = useCallback(
        debounce((event) => {
            handleSearch(event);
        }, 500),
        []
    );

    const handleEditFreeLobby = (id) => {
        setEditFreeLobby(true);
        setEditFreeLobbyIndex(id);
    };
    const handleCLoseFreeLobby = () => {
        setEditFreeLobby(false);
        setEditFreeLobbyIndex(null);
    };
    useEffect(() => {
        return () => {
            debouncedResults.cancel();
        };
    });
    const ref = useRef(null);
    const handleClear = () => {
        setpageNumber(1);
        setsearchTerm("");
        setselectedDropdown(null);
        setsortBy(null);
        ref.current.value = "";
    };
    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs breadcrumbItem="List Of All Users" />
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <div className="search-box tournament-filter d-flex align-items-center flex-wrap mb-4">
                                    <input
                                        ref={ref}
                                        className="form-control search-bar w-540"
                                        type="search"
                                        placeholder="Search Users by username, phone number, email address or name"
                                        onChange={(event) => {
                                            debouncedResults(event);
                                        }}
                                    />
                                    <Dropdown
                                        isOpen={singlebtn}
                                        toggle={() => setSinglebtn(!singlebtn)}
                                    >
                                        <DropdownToggle
                                            caret
                                            color="primary"
                                            className="dropdown-toggle-split users-sort-by-btn"
                                        >
                                            <span>
                                                {selectedDropdown === null
                                                    ? "Sort By"
                                                    : getFilterText(dropdownOptions, selectedDropdown)}
                                            </span>{" "}
                                            <i className="mdi mdi-chevron-down" />
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {dropdownOptions.map((item, index) => {
                                                return (
                                                    <DropdownItem
                                                        key={index}
                                                        onClick={() => dropdownChange(item.value)}
                                                        dropdownvalue={item.value}
                                                    >
                                                        {item.text}
                                                        &nbsp; &nbsp;
                                                        {selectedDropdown === item.value ? (
                                                            <i className="mdi mdi-check-circle-outline"></i>
                                                        ) : null}
                                                    </DropdownItem>
                                                );
                                            })}
                                        </DropdownMenu>
                                    </Dropdown>
                                    <ClearButton handleClear={handleClear} />
                                </div>

                                <div className="table-rep-plugin">
                                    <div
                                        className="table-responsive mb-0"
                                        data-pattern="priority-columns"
                                    >
                                        <Table
                                            id="tech-companies-1"
                                            className="table table-striped table-bordered list-of-all-users-table"
                                        >
                                            <Thead>
                                                <Tr>
                                                    <Th>Gamer Tag/Username</Th>
                                                    <Th data-priority="1">First Name</Th>
                                                    <Th data-priority="3">Last Name</Th>
                                                    <Th data-priority="1">Email Address</Th>
                                                    <Th data-priority="3">Phone Number</Th>
                                                    <Th data-priority="6" className="no-wrap">
                                                        Address
                                                    </Th>
                                                    <Th data-priority="6">Wallet Balance</Th>
                                                    <Th data-priority="6">Bonus Balance</Th>
                                                    <Th data-priority="6">Profile Status</Th>
                                                    <Th data-priority="6">Free Lobby Limit</Th>
                                                    <Th data-priority="6" className="no-wrap">
                                                        Referral Code
                                                    </Th>
                                                    <Th data-priority="6">Referred By</Th>
                                                    {changePermission ? (
                                                        <>
                                                            <Th data-priority="6" className="no-wrap">
                                                                Earnings Timeline
                                                            </Th>
                                                            <Th data-priority="6" colSpan="2">
                                                                Actions
                                                            </Th>
                                                        </>
                                                    ) : null}
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {loader ? (
                                                    <div
                                                        class="spinner-grow spinner-class"
                                                        role="status"
                                                        style={{ marginTop: "40px" }}
                                                    >
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                ) : users.length === 0 ? (
                                                    <h5 className="text-center my-5">No Users Found</h5>
                                                ) : (
                                                    users.map((item, index) => {
                                                        const date = new Date(
                                                            item?.profile.affiliate_days_expire
                                                        );
                                                        const options = {
                                                            weekday: "short",
                                                            day: "numeric",
                                                            year: "numeric",
                                                            month: "long",
                                                        };
                                                        const dateTime = date.toLocaleString(
                                                            "en-US",
                                                            options
                                                        );
                                                        return (
                                                            <Tr key={index}>
                                                                <Td className="username-field">
                                                                    <Link to={`/user/${item.id}`}>
                                                                        {item.username}
                                                                    </Link>
                                                                </Td>
                                                                <Th>{item.first_name || "NA"}</Th>
                                                                <Td>{item.last_name || "NA"}</Td>
                                                                <Td>{item.email || "NA"}</Td>
                                                                <Td>{item?.phone || "NA"}</Td>
                                                                <Td className="no-wrap">
                                                                    {item.address?.full || "NA"}
                                                                </Td>
                                                                <Td>$ {item.profile.wallet_balance || 0} </Td>
                                                                <Td>$ {item.profile.bonus_balance || 0} </Td>
                                                                <Td>
                                                                    {item.status === 1 ? "Active" : "Inactive"}
                                                                </Td>
                                                                <Td className="no-wrap">
                                                                    <div className="d-flex justify-content-between">
                                                                        {item?.profile?.free_lobby_limit === 0
                                                                            ? "0"
                                                                            : item?.profile?.free_lobby_limit || "NA"}
                                                                        {changePermission ? (
                                                                            editFreeLobby &&
                                                                                editFreeLobbyIndexById === index ? (
                                                                                <EditFreeLobby
                                                                                    userId={item.id}
                                                                                    defaultFreeLobbyLimit={
                                                                                        item?.profile?.free_lobby_limit ===
                                                                                            0
                                                                                            ? "0"
                                                                                            : item?.profile?.free_lobby_limit
                                                                                    }
                                                                                    onClose={() => handleCLoseFreeLobby()}
                                                                                    callApi={() => getListing()}
                                                                                />
                                                                            ) : (
                                                                                <Button
                                                                                    className="edit-icon info-icon ps-1 pt-0 pm-0 pe-0"
                                                                                    onClick={() =>
                                                                                        handleEditFreeLobby(index)
                                                                                    }
                                                                                >
                                                                                    <IconsToolTip
                                                                                        image={edit}
                                                                                        altImageText="edit-icon"
                                                                                        text="Edit user free lobby limit"
                                                                                    />
                                                                                </Button>
                                                                            )
                                                                        ) : null}
                                                                    </div>
                                                                </Td>
                                                                <Td className="no-wrap">
                                                                    <div className="d-flex justify-content-between">
                                                                        {item?.profile?.referral_code || "NA"}
                                                                        {changePermission ? (
                                                                            editReferral &&
                                                                                editReferralIndexById === index ? (
                                                                                <EditReferral
                                                                                    userId={item.id}
                                                                                    defaultReferral={
                                                                                        item?.profile?.referral_code
                                                                                    }
                                                                                    onClose={() => handleCLoseReferral()}
                                                                                    callApi={() => getListing()}
                                                                                />
                                                                            ) : (
                                                                                <Button
                                                                                    className="edit-icon info-icon ps-1 pt-0 pm-0 pe-0"
                                                                                    onClick={() =>
                                                                                        handleEditReferral(index)
                                                                                    }
                                                                                >
                                                                                    <IconsToolTip
                                                                                        image={edit}
                                                                                        altImageText="edit-icon"
                                                                                        text="Edit user referral code"
                                                                                    />
                                                                                </Button>
                                                                            )
                                                                        ) : null}
                                                                    </div>
                                                                </Td>
                                                                <Td>{item?.referral?.referral_code || "NA"}</Td>

                                                                {changePermission ? (
                                                                    <>
                                                                        <Td className="no-wrap">
                                                                            <div className="d-flex justify-content-between">
                                                                                {" "}
                                                                                {dateTime} ET
                                                                                {editEarning &&
                                                                                    editIndexById === index ? (
                                                                                    <EarningTimeline
                                                                                        userId={item.id}
                                                                                        days={item.profile.affiliate_days}
                                                                                        onClose={() => handleCLose()}
                                                                                        callApi={() => getListing()}
                                                                                    />
                                                                                ) : (
                                                                                    <Button
                                                                                        className="edit-icon info-icon ps-1 pt-0 pm-0 pe-0"
                                                                                        onClick={() =>
                                                                                            handleEditEarning(index)
                                                                                        }
                                                                                    >
                                                                                        <IconsToolTip
                                                                                            image={edit}
                                                                                            altImageText="edit-icon"
                                                                                            text="Edit user earnings timeline"
                                                                                        />
                                                                                    </Button>
                                                                                )}
                                                                            </div>
                                                                        </Td>
                                                                        <Td>
                                                                            <Link
                                                                                className="btn btn-primary btn-sm view-button users-view-btn"
                                                                                to={`/user/${item.id}`}
                                                                            >
                                                                                View
                                                                            </Link>
                                                                        </Td>
                                                                        <Td className="view-deactivate-btns">
                                                                            <Link
                                                                                className="btn btn-primary btn-sm view-button user-view-btn-mb"
                                                                                to={`/user/${item.id}`}
                                                                            >
                                                                                View
                                                                            </Link>
                                                                            <button
                                                                                className={`btn btn-sm ${item.status === 1
                                                                                        ? "btn-danger deactivate-button"
                                                                                        : "btn-success activate-button"
                                                                                    }`}
                                                                                onClick={() => {
                                                                                    openAlert(item);
                                                                                }}
                                                                            >
                                                                                {item.status === 1
                                                                                    ? "Deactivate"
                                                                                    : "Activate"}
                                                                            </button>
                                                                        </Td>
                                                                    </>
                                                                ) : null}
                                                            </Tr>
                                                        );
                                                    })
                                                )}
                                            </Tbody>
                                        </Table>
                                    </div>
                                </div>
                                <Paginator
                                    totalCount={totalCount}
                                    pageSize={pageSize}
                                    pageClick={handlePageClick}
                                    forcePage={pageNumber}
                                />
                            </CardBody>
                            {deleteModal ? (
                                <SweetAlert
                                    title="Change User Status"
                                    warning
                                    showCancel
                                    confirmButtonText="Yes"
                                    confirmBtnBsStyle="success"
                                    cancelButtonText="No"
                                    cancelBtnBsStyle="danger"
                                    onConfirm={() => deactivateUserStatus()}
                                    onCancel={() => closeAlert()}
                                    disabled={disableSwal || !deactivateReason?.trim()}
                                >
                                    Are you sure you want to{" "}
                                    {selectedUser.status === 1 ? "deactive" : "activate"} the user
                                    from the listings?
                                    <textarea
                                        required
                                        rows="5"
                                        className="mt-3 form-control"
                                        placeholder="enter description here"
                                        onChange={(e) => {
                                            setdeactivateReason(e.target.value);
                                        }}
                                    ></textarea>
                                    {deactivateReason ? null : (
                                        <p className="text-danger fst-italic fs-6 mt-2 mb-3">
                                            Description is required
                                        </p>
                                    )}
                                </SweetAlert>
                            ) : null}
                            {activeModal ? (
                                <SweetAlert
                                    title="Activate User"
                                    warning
                                    showCancel
                                    confirmButtonText="Yes"
                                    confirmBtnBsStyle="success"
                                    cancelButtonText="No"
                                    cancelBtnBsStyle="danger"
                                    onConfirm={() => deactivateUserStatus()}
                                    onCancel={() => closeAlert()}
                                    disabled={disableSwal}
                                >
                                    Are you sure you want to activate the user from the listings?
                                    <p>
                                        Deactivation Reason : <br />
                                        {selectedUser?.account_status?.reason}
                                    </p>
                                </SweetAlert>
                            ) : null}
                            {deactivateUserSuccessMessage ? (
                                <SweetAlert success showConfirm={false}>
                                    User{" "}
                                    {selectedUser.status === 1 ? "Deactivated " : "Activated "}
                                    Successfully
                                </SweetAlert>
                            ) : null}
                        </Card>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
};

export default UserList;
