export const reportsTableConstant = [
  {
    type: 1,
    heading: "Transactions History",
    tableHead: [
      {
        label: "Date",
        key: "create_date",
        type: "date",
      },
      {
        label: "Transaction ID",
        key: "transaction_id",
      },
      {
        label: "Transaction Type",
        key: "transaction_type",
      },
      {
        label: "Transaction Amount",
        key: "transaction_amount",
      },
      {
        label: "Transaction Fee",
        key: "transaction_fee",
        units: "$",
      },
      {
        label: "Payment Method",
        key: "payment_method",
      },
      {
        label: "Lobby Name",
        key: "lobby_name",
      },
      {
        label: "Name Of The Game",
        key: "name_of_the_game",
      },
      {
        label: "Email",
        key: "player",
      },
      {
        label: "Ryvals Share",
        key: "ryvals_share",
        units: "$",
      },
      
    ],
    tableHeadUnclaimed: [
      {
        label: "Lobby Name",
        key: "lobby",
      },
      {
        label: "Game Name",
        key: "name_of_the_game",
      },
      {
        label: "Start Date",
        key: "start_date",
        type: "date",
      },
      {
        label: "Entry Fee",
        key: "entry_fee",
        units: "$",
      },
      {
        label: "Enrolled Players'",
        key: "enrolled_players",
      },
      {
        label: "Total Enrollment Money",
        key: "total_enrollment_money",
        units: "$",
      },
      {
        label: "Total Ryvals Share",
        key: "ryvals_share",
        units: "$",
      },
      {
        label: "Reward Per Finish",
        key: "reward",
        units: "$",
      },
      {
        label: "Total Kills",
        key: "total_kills",
      },
      {
        label: "Total Winning Amount",
        key: "winning_amount",
        units: "$",
      },
      {
        label: "Total Unclaimed Money",
        key: "unclaimed_money",
        units: "$",
      },
    ],
    tableHeadDormant: [
      {
        label: "Username",
        key: "username",
      },
      {
        label: "Email",
        key: "email",
      },
      {
        label: "Wallet Balance",
        key: "wallet_balance",
        units: "$",
      },
      {
        label: "Inactivity Timeline (in days)",
        key: "inactivity_timeline",
      },
      {
        label: "Inactivity Charges",
        key: "inactivity_charges",
        units: "$",
      },
      {
        label: "Last Activity Date",
        key: "last_enrollement_date",
        type: "date",
      },
      {
        label: "Last Activity",
        key: "last_activity",
      },
    ],
    tableHeadInactivity: [
      {
        label: "Date",
        key: "create_date",
        type: "date",
      },
      {
        label: "Transaction ID",
        key: "transaction_id",
      },
      {
        label: "Transaction Type",
        key: "transaction_type",
      },
      {
        label: "Transaction Amount",
        key: "transaction_amount",
      },
      {
        label: "Email",
        key: "player",
      },
    ],
  },
  {
    type: 2,
    heading: "Users",
    tableHead: [
      {
        label: "Players' Email",
        key: "email",
      },
      {
        label: "Referred By Email",
        key: "referred_email",
      },
      {
        label: "Referral Code",
        key: "referred_code",
      },
      {
        label: "Registered On",
        key: "join_date",
        type: "date",
      },
    ],
  },
  {
    type: 3,
    heading: "Lobbies",
    tableHead: [
      {
        label: "Lobby Name",
        key: "name",
      },
      {
        label: "Game Name",
        key: "name_of_the_game",
      },
      {
        label: "Lobby Created Date",
        key: "create_date",
        type: "date",
      },
      {
        label: "Lobby Start Date",
        key: "lobby_start_date",
        type: "date",
      },
      {
        label: "Entry Fee",
        key: "entry_fee",
        units: "$",
      },
      {
        label: "Enrolled Players'",
        key: "enrolled_players",
      },
      {
        label: "Total Enrollment Money",
        key: "total_enrollment_money",
        units: "$",
      },
      {
        label: "Players' Played Game",
        key: "registered_players",
      },
      {
        label: "Total Kills",
        key: "total_kills",
      },
      {
        label: "Total Winning Amount",
        key: "winning_amount",
        units: "$",
      },
    ],
  },
  {
    type: 4,
    heading: "Complaints",
    tableHead: [
      {
        label: "Lobby Name",
        key: "lobby_name",
      },
      {
        label: "Game",
        key: "game",
      },
      {
        label: "Complaint By",
        key: "cmp_by",
      },
      {
        label: "Complaint By Email",
        key: "cmp_by_email",
      },

      {
        label: "Complaint Against",
        key: "cmp_against",
      },
      {
        label: "Complaint Against Email",
        key: "cmp_against_email",
      },
      {
        label: "Date of Match",
        key: "date_of_match",
        type: "date",
      },
    ],
  },
];
