import { gamesToCategories } from "../../../helpers/util";
import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  SOCIAL_LOGIN,
  SET_GAMES,
  SET_PRIZE_LIST,
  SET_SCORE_LIST,
  SET_SCHEDULE_LIST,
  SET_SCHEDULE_COUNT,
  SET_CLEAR_PRIZE_LIST,
  UPDATE_PRIZE_COUNT,
  UPDATE_PRIZE_LIST,
  UPDATE_SCHEDULE_LIST,
  SET_CLEAR_SCHEDULE_LIST,
} from "./actionTypes";

export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history },
  };
};

export const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  };
};

export const logoutUser = (history) => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  };
};

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

export const socialLogin = (data, history, type) => {
  return {
    type: SOCIAL_LOGIN,
    payload: { data, history, type },
  };
};

export const setGames = (data) => {
  return {
    type: SET_GAMES,
    payload: gamesToCategories(data),
  };
};
export const setPrizeList = (prizeList) => {
  return {
    type: SET_PRIZE_LIST,
    payload: prizeList,
  };
};

export const setScoresList = (scoreList) => {
  return {
    type: SET_SCORE_LIST,
    payload: scoreList,
  };
};
export const setClearPrizeList = () => {
  return {
    type: SET_CLEAR_PRIZE_LIST,
    payload: {},
  };
};
export const updatePrizeCount = (prizeCount) => {
  return {
    type: UPDATE_PRIZE_COUNT,
    payload: prizeCount,
  };
};
export const updatePrizeList = () => {
  return {
    type: UPDATE_PRIZE_LIST,
  };
};
export const setScheduleList = (scheduleList) => {
  return {
    type: SET_SCHEDULE_LIST,
    payload: scheduleList,
  };
};
export const setScheduleCount = (scheduleCount) => {
  return {
    type: SET_SCHEDULE_COUNT,
    payload: scheduleCount,
  };
};
export const updateScheduleList = () => {
  return {
    type: UPDATE_SCHEDULE_LIST,
  };
};
export const setClearScheduleList = () => {
  return {
    type: SET_CLEAR_SCHEDULE_LIST,
    payload: {},
  };
};
