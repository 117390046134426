import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  deleteSelectedDemoApi,
  updateDemoPagePinStatus,
  getPinnedList,
} from "../../services/demo_api_helper";
import { getGameList } from "../../services/game_api_helper";
import { Link } from "react-router-dom";

// Paginator
import Paginator from "../../helpers/paginator/paginator";
// import switch
import Switch from "react-switch";

// Alert
import SweetAlert from "react-bootstrap-sweetalert";

import { toast } from "react-toastify";
import toastrOptions from "../../helpers/toastr-options/toastr-options";
import FilterPermission from "../../helpers/FilterPermission";
import { filterOutPermissionToShowHide } from "../../helpers/PermissionUtils";
import {
  permissionsStrings,
  SUCCESS_MESSAGES,
} from "../../helpers/StringConstant";
import { OffSymbol } from "../../components/Switch/OffSymbol";
import { OnSymbol } from "../../components/Switch/OnSymbol";
import { getTrophyList } from "../../services/trophy_api_helper";


export const TrophyTypeMap = {
  1: { label: "Base", value: 1 },
  2: { label: "Event", value: 2 },
};



const TrophyPageList = (props) => {
  const [trophies, setTrophies] = useState([]);
  const [singlebtnGame, setSinglebtnGame] = useState(false);
  const [selectedDropdownByGameName, setselectedDropdownByGameName] =
    useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [totalCount, settotalCount] = useState(null);

  //filter keys
  const pageSize = 10;
  const [pageNumber, setpageNumber] = useState(1);
  const [searchByGame, setSearchByGame] = useState(null);

  const [games, setGames] = useState([]);

  const [changePermission, setChange] = useState(false);
  const [deletePermission, setDelete] = useState(false);
  const [addPermission, setAdd] = useState(false);
  useEffect(() => {
    getDemoListing();
    if (props.permission.length !== 0) {
      callSetPermission();
    } else {
      setChange(true);
      setDelete(true);
      setAdd(true);
    }
  }, [
    searchByGame,
    // gameFilter,
    pageNumber,
  ]);
  const callSetPermission = () => {
    const type = permissionsStrings.gameDemo;
    const typeChange = permissionsStrings.typeDemoChange;
    const typeDelete = permissionsStrings.typeDemoDelete;
    const typeAdd = permissionsStrings.typeDemoAdd;
    const filteredPermission = FilterPermission(props.permission, type);
    if (filteredPermission.length !== 0) {
      const setchange = filterOutPermissionToShowHide(
        filteredPermission[0].permissions,
        typeChange
      );
      const setdelete = filterOutPermissionToShowHide(
        filteredPermission[0].permissions,
        typeDelete
      );

      const setadd = filterOutPermissionToShowHide(
        filteredPermission[0].permissions,
        typeAdd
      );
      setChange(setchange);
      setDelete(setdelete);
      setAdd(setadd);
    }
  };
  async function getDemoListing() {
    setisLoading(true);
    const filters = filterModel();
    await getTrophyList(filters)
      .then((res) => {
        setTrophies(res.data);
        settotalCount(res.total);
        setisLoading(false);
      })
      .catch(() => {
        setisLoading(false);
      });
    getGameList().then((res) => {
      setGames(res);
    });
    getPinnedList().then((res) => {
      setOptions(res.results);
    });
  }
  const [options, setOptions] = useState({});

  function filterModel() {
    return {
      pageSize: pageSize,
      pageNumber: pageNumber,
    };
  }

  const handlePageClick = (pageNum) => {
    setpageNumber(pageNum);
  };


  const handleStatusChange = async (index, e, statusData) => {
    const game_id = statusData.id;
    let model = {
      status: e ? 1 : 2,
    };
    await updateDemoPagePinStatus(game_id, model).then(
      (data) => {
        toast.success(SUCCESS_MESSAGES.gameDemoSuccess, toastrOptions);
        getDemoListing();
      },
      (err) => {
        toast.error(err, toastrOptions);
      }
    );
  };
  const [selectedDemo, setselectedDemo] = useState(null);


  const [deleteModal, setDeleteModal] = useState(false);
  const [disableSwal, setDisabled] = useState(false);

  const deleteSelectedDemo = async () => {
    setDisabled(true);
    setDeleteModal(false);
    setisLoading(true);
    await deleteSelectedDemoApi(selectedDemo)
      .then(() => {
        setDisabled(false);
        closeAlert();
        getDemoListing();
        toast.success(SUCCESS_MESSAGES.gameDemoDeleteSuccess, toastrOptions);
      })
      .catch(() => {
        setDisabled(false);
        closeAlert();
        setisLoading(false);
      });
  };

  const closeAlert = () => {
    setDeleteModal(false);
  };



  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs breadcrumbItem="Trophies and Achievements" />
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <Row className="search-box">


                  {addPermission ? (
                    <Col className="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-6">
                      <Link to="/trophy/add">
                        <button className="btn btn-primary add-lobby-btn">
                          {" "}
                          Add Trophy{" "}
                        </button>
                      </Link>
                    </Col>
                  ) : null}
                </Row>

                <div className="table-rep-plugin">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                    <h4>Trophies</h4>
                    <Table
                      id="tech-companies-1"
                      className="table table-striped table-bordered lobbies-table"
                    >
                      <Thead>
                        <Tr>
                          <Th>Sr.No</Th>
                          <Th>Name</Th>
                          <Th data-priority="3">Type</Th>
                          {/* <Th data-priority="3">Status</Th> */}
                          <Th data-priority="3">Lobby</Th>
                          <Th data-priority="3">Tournament</Th>
                          <Th data-priority="3">Placement</Th>
                          <Th data-priority="4">Description</Th>
                          <Th data-priority="4">Image</Th>

                          {changePermission || deletePermission ? (
                            <>
                              <Th data-priority="3">Status</Th>
                              <Th data-priority="6" colSpan="2">
                                Actions
                              </Th>
                            </>
                          ) : null}
                        </Tr>
                      </Thead>
                      <Tbody>
                        {isLoading ? (
                          <div
                            class="spinner-grow transaction-spinner"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                        ) : trophies.length === 0 ? (
                          <h5 className="text-center mt-4">No Trophies(s) Found</h5>
                        ) : (
                          trophies.map((item, index) => (
                            <Tr key={index}>
                              <Td>{(pageNumber - 1) * 10 + index + 1}</Td>
                              <Td className="username-field">
                                {item.name}
                              </Td>
                              <Td>{item.type ? TrophyTypeMap[item.type].label : "NA"}</Td>
                              <Td>

                                {item.lobby ? <Link
                                  to={{
                                    pathname: `/lobby/${item.lobby}`,
                                    // state: { item },
                                  }}
                                >
                                  {item.lobby_name}
                                </Link> : "NA"}
                              </Td>

                              <Td> {item.tournament ? <Link
                                to={{
                                  pathname: `/tournament/${item.tournament}`,
                                  state: { item },
                                }}
                              >
                                {item.tournament_name}
                              </Link> : "NA"}</Td>
                              <Td>{item.award_everyone ? "Everyone" :item.placement || "NA"}</Td>
                              <Td>{item.description || "NA"}</Td>
                              <Td>  <img
                                alt="Imagebg"
                                src={item.image}
                                className="img-thumbnail"
                                style={{ height: "80px", width: "120px" }}
                              /></Td>
                              {changePermission ? (
                                <>
                                  <Td>
                                    <Switch
                                      uncheckedIcon={<OffSymbol />}
                                      checkedIcon={<OnSymbol />}
                                      onColor="#626ed4"
                                      className={
                                        item.status === 1
                                          ? "form-label active-toggle"
                                          : "form-label"
                                      }
                                      onChange={(e) => {
                                        handleStatusChange(index, e, item);
                                      }}
                                      checked={item.status === 1 ? true : false}
                                      disabled={true}

                                    />
                                  </Td>
                                  <Td>
                                    <Link
                                      className="btn btn-sm complaint-view-btn btn-secondary"
                                      role="button"
                                      to={`/trophy/edit/${item?.id}`}
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      Edit {"  "}
                                    </Link>
                                  </Td>
                                </>
                              ) : null}
                              {/* {deletePermission ? (
                                <Td className="location-delete-btn">
                                  <button
                                    className="btn btn-sm complaint-view-btn btn-secondary"
                                    onClick={() => {
                                      openAlert(item.id);
                                    }}
                                  >
                                    Delete
                                  </button>
                                </Td>
                              ) : null} */}
                            </Tr>
                          ))
                        )}
                      </Tbody>
                    </Table>
                  </div>
                </div>
                <Paginator
                  totalCount={totalCount}
                  pageSize={pageSize}
                  pageClick={handlePageClick}
                  forcePage={pageNumber}
                />
              </CardBody>
              {deleteModal ? (
                <SweetAlert
                  title="Delete Demo"
                  warning
                  showCancel
                  confirmButtonText="Yes"
                  confirmBtnBsStyle="success"
                  cancelButtonText="No"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => deleteSelectedDemo()}
                  onCancel={() => closeAlert()}
                  disabled={disableSwal}
                >
                  Are you sure you want to delete this demo
                </SweetAlert>
              ) : null}

            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default TrophyPageList;
