import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody, Col } from "reactstrap";
const PlayersList = (props) => {
    const [players, setPlayers] = useState({});
    const teamIdNameMap = useRef({});
    // creating state with playerList
    useEffect(() => {
        const teamObj = {};
        props?.team.players.forEach((element) => {
            // creating data for team separtations
            if (teamObj[props.team.id]) {
                teamObj[props.team.id].push(element);
            } else {
                teamIdNameMap.current[props.team.id] = props.team.name;
                teamObj[props.team.id] = [element];
            }
        });
        //updating states
        setPlayers(teamObj);
    }, [props?.team]);
    const comparator = (x) => (x.leader ? -1 : 1);
    return (
        <Col lg={8}>
            <Card>
                <CardBody className="valorantDetail">
                    <div className="table-responsive enrolled-players-table">
                        <table className="table table-striped table-bordered lobbies-table responsiveTable">
                            <thead>
                                <tr>
                                    <td scope="col">Team Name</td>
                                    <td scope="col">Ryvals Username</td>
                                    <td scope="col">Gamer ID</td>
                                    <td scope="col">Team Creator</td>
                                </tr>
                            </thead>
                            <tbody>
                                {props?.playerList?.length === 0 ? (
                                    <tr>
                                        <td colspan="7">
                                            <h5 className="text-center my-5">
                                                No enrolled team found
                                            </h5>
                                        </td>
                                    </tr>
                                ) : (
                                    Object.entries(players).map((item, i) => {
                                        return item[1]?.sort(comparator).map((player, index) => {
                                            return (
                                                <tr key={index}>
                                                    {index == 0 ? (
                                                        <td
                                                            rowSpan={item?.[1]?.length}
                                                            className="teamName challnegeDetails"
                                                        >
                                                            {teamIdNameMap.current?.[item?.[0]]}
                                                        </td>
                                                    ) : null}
                                                    <td>{player?.username || "N/A"}</td>
                                                    <td>{player?.player_name || "N/A"}</td>
                                                    <td>
                                                        {player?.leader ? "Creator" : "Member" || "N/A"}
                                                    </td>
                                                </tr>
                                            );
                                        });
                                    })
                                )}
                            </tbody>
                        </table>
                    </div>
                </CardBody>
            </Card>
        </Col>
    );
};
export default PlayersList;
