import { axiosApi } from "./index";

export async function addTeamToLobby(model) {
  return await axiosApi.post(`lobby/add-team/`, model);
}

export async function formNewTeam(model) {
  return await axiosApi.post(`versus-team/add-team/`, model);
}

export async function getTeams(name, game_id, mode) {
  const url = `versus-team/name?name=${name}&game_id=${game_id}&mode=${mode}`;

  return await axiosApi.get(url);
}