import { axiosApi } from "./index";
import axios from "axios";


const baseUrl = process.env.REACT_APP_BASEURL;

export async function uploadBulkFileUpload(model) {
  return await axiosApi.post(`file-uploads/upload/`, model);
}

export async function getPresignedUrl(fileType) {

    try {
        const response = await axiosApi.post(baseUrl + 'app/generate-presigned-url/',{file_type: fileType});
    
        if (!response?. presigned_put_url) {
          throw new Error('Failed to get presigned URL.');
        }
    
        return response?.presigned_put_url;
      } catch (error) {
        throw new Error(error.message || 'An error occurred while getting the presigned URL.');
      }

}

export const uploadToS3 = async (presignedUrl, file,fileType) => {
    try {
      const uploadResponse = await axios.put(presignedUrl, file,{headers:{'Content-Type': fileType,'x-amz-acl': 'public-read',}});

      if (uploadResponse.status !== 200) {
        throw new Error('Failed to upload the image to S3.');
      }
   
      return presignedUrl.split('?')[0];
     
    } catch (error) {
      throw new Error(error.message || 'An error occurred while uploading the image.');
    }
  };
