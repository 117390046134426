import { AvField, AvForm } from "availity-reactstrap-validation";
import React from "react";
import { Button, CardTitle, FormGroup } from "reactstrap";
import backIcon from "../../assets/images/arrow-left.svg";

const EditGameIdUser = ({
  enableListView,
  editGameIdData = {},
  editGameId,
//   data,
}) => {
  const resetForm = () => {
    enableListView();
  };

  return (
    <>
      <CardTitle>
        <div style={{ cursor: "pointer" }} onClick={resetForm}>
          <img alt="back-icon" src={backIcon} />
          <span
            style={{
              display: "inline-block",
              marginLeft: "4px",
              color: "black",
            }}
          >
            back
          </span>
        </div>
      </CardTitle>

      <div className="faq-box d-flex mt-4 flex-column align-items-start mb-4 pl-3">
        <div className="w-100">
          <AvForm
            onValidSubmit={(e, v) => {
                editGameId({
                payload: {
                  gaming_account: v.gameAccunt,
                  gaming_account_id: editGameIdData.gaming_account_id,
                  game_id: editGameIdData.gameId,
                  game_name: editGameIdData.gameName
                },
                callback: resetForm,
              });
            }}
          >
            <div className="mb-3">
              <AvField
                key={editGameIdData.gameName}
                name="gameId"
                label="Game Name"
                placeholder=""
                type="text"
                value={editGameIdData.gameName}
                disabled={true}
              />
            </div>
            <div className="mb-3">
              <AvField
                key={editGameIdData.gaming_account}
                name="gameAccunt"
                label="Game Id"
                placeholder=""
                type="text"
                value={editGameIdData.gaming_account}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Game Id Required",
                  },
                //   maxLength: {
                //     value: 50,
                //     errorMessage: "Topic can have 50 characters max",
                //   },
                }}
                // disabled={}
              />
            </div>
              <FormGroup style={{ marginTop: "20px" }}>
                <div>
                  <Button type="submit" color="primary" className="ms-1 mr-2">
                    Submit
                  </Button>
                  <Button
                    onClick={resetForm}
                    type="reset"
                    color="secondary"
                    outline
                    className="ms-1"
                  >
                    Cancel
                  </Button>
                </div>
              </FormGroup>
            
          </AvForm>
        </div>
      </div>
    </>
  );
};

export default EditGameIdUser;
