import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import {
  csvConverterToPreviewFile,
  dateFormat,
  competitionStatus,
} from "../../../helpers/util";
import sanitize from "sanitize-html";
import { isEmpty } from "lodash";
import PrizeList from "../Common/PrizeList";
import { useEffect } from "react";
import { useState } from "react";
import CsvView from "../../../helpers/CsvView";
import ScoreList from "../Common/ScoreList";
const TournamentDetailsTab = ({ details }) => {
  useEffect(() => {
    if (!isEmpty(details.team_rank_file)) {
      callUrl();
    }
  }, []);
  const [previewOldcsvData, setPreviewOldCsvData] = useState([]);
  const callUrl = async () => {
    fetch(details.team_rank_file)
      .then((response) => response.text())
      .then((data) => {
        const csvpreview = csvConverterToPreviewFile(data);
        setPreviewOldCsvData(csvpreview);
      });
  };
  return (
    <Card className="mb-0">
      <CardBody>
        <Row>
          <Col className="col-12 mb-4">
            <h4>{details.name}</h4>
          </Col>
          <Col className="col-12 mb-3">
            <strong className="capitalize">Game Type :</strong>{" "}
            {details?.game_type}
          </Col>
          <Col className="col-12 mb-3">
            <strong className="capitalize">Game :</strong> {details.game}
          </Col>

          <Col className="col-12 mb-3">
            <strong className="capitalize">Entry Fee :</strong> ${" "}
            {Number(details?.entry_fee)?.toFixed(2)}{" "}
          </Col>
          <Col className="col-12 mb-3">
            <strong className="capitalize">Bracket Styles :</strong>{" "}
            {details.bracket}
          </Col>
          <Col className="col-12 mb-3">
            <strong className="capitalize">Seeding:</strong> {details.seeding}
          </Col>
          {details.seeding === "Qualifiers" ? (
            <Col className="col-12 mb-3">
              <strong className="capitalize">
                Qualifier Selected Tournament:
              </strong>{" "}
              {details.qualifier_tournament?.name}
            </Col>
          ) : null}
          {details?.game_type === "PPK" ? (
            <>
              {" "}
              <Col className="col-12 mb-3">
                <strong className="capitalize">Lobby Size:</strong>{" "}
                {details.lobby_size}
              </Col>
              <Col className="col-12 mb-3">
                <strong className="capitalize">Threshold:</strong>{" "}
                {details.ppk_team_selection_threshold}
              </Col>
            </>
          ) : null}
          {details.seeding === "Ranked Placement" ? (
            <Col className="col-12 mb-3">
              <strong className="capitalize">
                Allow The Players To Add Ranks:
              </strong>{" "}
              {details.players_to_add_rank_status ? "Yes" : "No"}
            </Col>
          ) : null}

          {previewOldcsvData.length === 0 ? null : (
            <Col className="col-12 mb-3">
              <strong className="capitalize">Team Rank:</strong>
              <CsvView csvContents={previewOldcsvData} />
            </Col>
          )}

          <Col className="col-12 mb-3">
            <strong className="capitalize">Minimum Teams :</strong>{" "}
            {details.min_team}
          </Col>
          <Col className="col-12 mb-3">
            <strong className="capitalize">Maximum Teams :</strong>{" "}
            {details.max_team}
          </Col>
          <Col className="col-12 mb-3">
            <strong className="capitalize"> Players Per Team:</strong>{" "}
            {details.player_per_team}
          </Col>
          <Col className="col-12 mb-3">
            <strong className="capitalize"> Enrolled Teams:</strong>{" "}
            {details.enrolled_teams}
          </Col>
          <Col className="col-12 mb-3">
            <strong className="capitalize">Start Date :</strong>{" "}
            {dateFormat(details?.start_date)}
          </Col>
          <Col className="col-12 mb-3">
            <strong className="capitalize">Start Time :</strong>{" "}
            {details?.start_time}
          </Col>
          <Col className="col-12 mb-3">
            <strong className="capitalize">Last Entry Time :</strong>{" "}
            {details?.last_entry_time}
          </Col>
          <Col className="col-12 mb-3">
            <strong className="capitalize">Prize :</strong>{" "}
            {details.price.length === 0 ? (
              "Na"
            ) : (
              <PrizeList prize={details.price} />
            )}
          </Col>

          {details.custom_scoring && (
            <>
              <Col className="col-12 mb-3">
                <strong className="capitalize">Custom Scoring :</strong>{" "}
                {details.score.length === 0 ? (
                  "Na"
                ) : (
                  <ScoreList score={details.score} />
                )} 
              </Col>
              <Col className="col-12 mb-3">
                <strong className="capitalize">
                  Match Point :{details.match_point ? "Yes" : "No"}
                </strong>{" "}
              </Col>
            </>
          )}

          <Col className="col-12 mb-3">
            <strong className="capitalize">Game Rules :</strong>{" "}
            {isEmpty(details?.game_rules) ? (
              "NA"
            ) : (
              <p
                className="mb-0"
                dangerouslySetInnerHTML={{
                  __html: sanitize(details?.game_rules),
                }}
              ></p>
            )}
          </Col>
          <Col className="col-12 mb-3">
            <strong className="capitalize">Tournament Rules :</strong>{" "}
            {isEmpty(details?.tournament_rules) ? (
              "NA"
            ) : (
              <p
                className="mb-0"
                dangerouslySetInnerHTML={{
                  __html: sanitize(details?.tournament_rules),
                }}
              ></p>
            )}
          </Col>
          <Col className="col-12 mb-3">
            <strong className="capitalize">Active Tournament :</strong>{" "}
            {details?.active ? "Yes" : "No"}
          </Col>
          <Col className="col-12 mb-3">
            <strong className="capitalize">Free Tournament :</strong>{" "}
            {details?.free ? "Yes" : "No"}
          </Col>
          <Col className="col-12 mb-3">
            <strong className="capitalize">Tournament Status :</strong>{" "}
            {competitionStatus[details.current_status]}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default TournamentDetailsTab;
