import { SET_SCORE_LIST } from "./actionTypes";

const initialState = {
  score: [
    { position: 1, points: 12 },
    { position: 2, points: 9 },
    { position: 3, points: 7 },
    { position: 4, points: 5 },
    { position: 5, points: 4 },
    { position: 6, points: 3 },
    { position: 7, points: 3 },
    { position: 8, points: 2 },
    { position: 9, points: 2 },
    { position: 10, points: 2 },
    { position: 11, points: 1 },
    { position: 12, points: 1 },
    { position: 13, points: 1 },
    { position: 14, points: 1 },
    { position: 15, points: 1 },
    { position: 16, points: 0 },
    { position: 17, points: 0 },
    { position: 18, points: 0 },
    { position: 19, points: 0 },
    { position: 20, points: 0 },
  ],
  count: 20,
};

const scoreReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SCORE_LIST:
      return {
        ...state,
        score:
          action === undefined
            ? []
            : action.payload.map(({ positionLabel, ...rest }) => ({
                ...rest,
              })),
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default scoreReducer;
