import React, { useEffect, useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const ImageCropper = (props) => {
    const { imageToCrop, onImageCropped, type } = props;

    const [cropConfig, setCropConfig] = useState({
        unit: "px",
        width: 0,  
        height: 0, 
        aspect: 0,  
    });

    const [imageRef, setImageRef] = useState();

    useEffect(() => {
        if (type === "cover") {
           const width = 200;
            const aspectRatio = 3.33;
            const height = width / aspectRatio; // Calculate height based on aspect ratio

            // Set the crop configuration in pixels
            setCropConfig({ unit: "px", width, height, aspect: aspectRatio });
        }else if (type === "background") {
            setCropConfig({ unit: "px", width: 208, height: 162, aspect: 1.28 });
        }
    }, [type]);

    // Generate a random string for image name
    function makeid(length) {
        let result = "";
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(window.crypto.getRandomValues(new Uint32Array(1))[0] % charactersLength));
        }
        return result;
    }

    // Handle image cropping
    async function cropImage(crop) {
        const imageName = makeid(5);
        if (imageRef && crop.width && crop.height) {
            const croppedImage = await getCroppedImage(
                imageRef,
                crop,
                `${imageName}.jpeg`
            );
            onImageCropped(croppedImage);
        }
    }

    // Function to create cropped image
    function getCroppedImage(sourceImage, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = sourceImage.naturalWidth / sourceImage.width;
        const scaleY = sourceImage.naturalHeight / sourceImage.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(
            sourceImage,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );
        return new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
                if (!blob) {
                    reject(new Error("Canvas is empty"));
                    return;
                }
                const blobUrl = URL.createObjectURL(blob);
                setCroppedImageLink(blobUrl);  // Save the cropped image link
                resolve(blobUrl);
            }, "image/jpeg");
        });
    }

    // Save cropped image link to localStorage based on image type
    const setCroppedImageLink = (link) => {
        if (props.type === "cover") {
            localStorage.setItem("coverImageLink", link);
        }else if(props.type === "background") {
            localStorage.setItem("backgroundImageLink", link);
        }
    };

    return (
        <div>
            {/* Image Cropper */}
            <ReactCrop
                src={imageToCrop}
                crop={cropConfig}
                ruleOfThirds
                onImageLoaded={(ref) => setImageRef(ref)}
                onComplete={(cropImageValue) => cropImage(cropImageValue)}
                onChange={(cropConfigValue) => setCropConfig(cropConfigValue)}
                crossorigin="anonymous"
            />
        </div>
    );
};

export default ImageCropper;
