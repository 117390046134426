import {
    Modal,
    ModalBody,
    Label,

    ModalHeader,
    Button,
    FormGroup,
} from "reactstrap";

import { useState } from 'react';
import { AvForm, AvGroup, AvInput, AvField } from "availity-reactstrap-validation";


import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";


import { toast } from "react-toastify";

import toastrOptions from "../../helpers/toastr-options/toastr-options";
import { fetchStatsManual, updateStatsManual } from "../../services/lobby_api_helper";
import { set } from "date-fns";




const ManualResultsModal = (props) => {

    const [showLoader, setShowLoader] = useState(false);
    const [LobbyResults, setLobbyResults] = useState({});
    const [error, setError] = useState(null);
    const [statsCode, setStatsCode] = useState(props?.lobby?.stats_code);


    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [spinnerLoader, setSpinnerLoader] = useState(false);

    const [addManualStatsForm, setAddManualStatsForm] = useState({});

    const changeValuesforPlayer = (player_id, key, value) => {
        console.log(player_id, key, value);
        if (addManualStatsForm[player_id] === undefined) {
            setAddManualStatsForm((prev) => {
                prev[player_id] = {
                    teamPlacement: '0',
                    teamName: '',
                    kills: '0',
                    deaths: '0',
                    assists: '0',
                    damageDealt: '0'
                };
                return prev
            });

        }
        setAddManualStatsForm((prev) => { prev[player_id][key] = value; console.log(prev); return prev });
    }


    const handleValidUpdateResults = (event, values) => {
        event.preventDefault();
        setSubmitDisabled(true);
        const model = {
            lobby_id: props.lobby.id,
            data: addManualStatsForm
        }

        setErrorMessage(null);


        updateStatsManual(model).then((res) => {

            setSubmitDisabled(false);
            setSpinnerLoader(false);

            toast.success(res.message, toastrOptions);



        }, (err) => {
            setSpinnerLoader(false);
            setSubmitDisabled(false);
            setErrorMessage(err)

        })

    }


    const [errorMessage, setErrorMessage] = useState(null);

    const [submitFetchStatFileDisabled, setSubmitFetchStatFileDisabled] = useState(false);
    const handleValidFetchStatFileSubmit = (event, values) => {
        event.preventDefault();
        console.log(statsCode);

        setSubmitFetchStatFileDisabled(true);
        const model = {
            lobby_id: props.lobby.id,
            stats_code: statsCode
        }

        setErrorMessage(null);


        fetchStatsManual(model).then((res) => {

            setSubmitFetchStatFileDisabled(false);
            setShowLoader(false);


            setAddManualStatsForm(res.data);

            toast.success(res.message, toastrOptions);





        }, (err) => {
            setShowLoader(false);
            setSubmitFetchStatFileDisabled(false);
            setErrorMessage(err)

        })

    }


    return (
        <Modal
            isOpen={props.openManualResultsModal}
            toggle={() => {
                props.handleClose();
            }}
            onHide={() => this.handleClose()}
        >
            <ModalHeader>
                <h5>Enter Manual Lobby Results</h5>
                <button
                    className="crossButton previewCrossIcon"
                    onClick={() => {
                        props.handleClose();
                    }}
                >
                    X
                </button>
            </ModalHeader>
            <ModalBody className="py-3 px-5">
                <div className="">
                    <AvForm
                        onValidSubmit={(e, v) => {
                            handleValidFetchStatFileSubmit(e, v);
                        }}
                    >


                        <div className="row">
                            <div className="col-5">
                                <AvGroup>
                                    <Label>{'Stats Code'}</Label>
                                    <AvField
                                        required
                                        type="text"
                                        name="statsCode"
                                        autoComplete="off"
                                        clear
                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage: "Stats Code is required",
                                            },
                                        }}
                                        value={statsCode}
                                        placeholder="Enter Stats Code"
                                        onChange={(e) => {
                                            setStatsCode(e.target.value);
                                        }}
                                    />


                                </AvGroup>
                            </div>


                            <div className="col-5 d-flex">
                                <FormGroup className="d-flex align-items-end">
                                    <Button
                                        type="submit"
                                        color="primary"
                                        className="ms-1"
                                    // disabled={disableSubmit}
                                    >
                                        Fetch Stats File
                                    </Button>
                                </FormGroup>
                            </div>
                        </div>
                    </AvForm>

                    <AvForm
                        onValidSubmit={(e, v) => {
                            handleValidUpdateResults(e, v);
                        }}
                    >


                        <div className="row">
                            <Table
                                className="table table-striped table-bordered ">
                                <Thead>
                                    <Tr>
                                        <Th>Player Name</Th>
                                        <Th>Team Name</Th>
                                        <Th>Team Placement</Th>
                                        <Th>Player Kills</Th>
                                        <Th>Player Assists</Th>
                                        <Th>Player Damage</Th>
                                    </Tr>
                                </Thead>

                                <Tbody>
                                    {props.lobby?.lobby_players.map((e, i) => {

                                        const player = props.lobby?.lobby_players[i]
                                        const player_id = player?.id;

                                        return (<Tr key={i}>

                                            <Td>
                                                {player?.username}
                                            </Td>
                                            <Td>
                                                <AvField
                                                    required
                                                    type="text"
                                                    name={`teamName${i}`}
                                                    autoComplete="off"
                                                    clear
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: `Player ${player?.username} team name is required`,
                                                        },
                                                    }}
                                                    value={addManualStatsForm[player_id]?.teamName || ''}
                                                    onChange={(e) => {
                                                        changeValuesforPlayer(player_id, "teamName", e.target.value)
                                                    }}
                                                />

                                            </Td>

                                            <Td>
                                                <AvField
                                                    required
                                                    type="text"
                                                    name={`teamPlacement${i}`}
                                                    autoComplete="off"
                                                    clear
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: `Player ${player_id.username} team placement is required`,
                                                        },
                                                    }}
                                                    value={addManualStatsForm[player_id]?.teamPlacement || ''}
                                                    onChange={(e) => {
                                                        changeValuesforPlayer(player_id, "teamPlacement", e.target.value)
                                                    }}
                                                />

                                            </Td>
                                            <Td>
                                                <AvField
                                                    required
                                                    type="text"
                                                    name={`kills${i}`}
                                                    autoComplete="off"
                                                    clear
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: `Player ${player_id.username} kills is required`,
                                                        },
                                                    }}
                                                    value={addManualStatsForm[player_id]?.kills || ''}
                                                    onChange={(e) => {
                                                        changeValuesforPlayer(player_id, "kills", e.target.value)
                                                    }}
                                                />

                                            </Td>

                                            <Td>
                                                <AvField
                                                    required
                                                    type="text"
                                                    name={`assists${i}`}
                                                    autoComplete="off"
                                                    clear
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: `Player ${player_id.username} assists is required`,
                                                        },
                                                    }}
                                                    value={addManualStatsForm[player_id]?.assists || ''}
                                                    onChange={(e) => {
                                                        changeValuesforPlayer(player_id, "assists", e.target.value)
                                                    }}
                                                />

                                            </Td>

                                            <Td>
                                                <AvField
                                                    required
                                                    type="text"
                                                    name={`damageDealt${i}`}
                                                    autoComplete="off"
                                                    clear
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: `Player ${player_id.username} damageDealt is required`,
                                                        },
                                                    }}
                                                    value={addManualStatsForm[player_id]?.kills || ''}
                                                    onChange={(e) => {
                                                        changeValuesforPlayer(player_id, "damageDealt", e.target.value)
                                                    }}
                                                />

                                            </Td>
                                        </Tr>)
                                    }

                                    )}
                                </Tbody>


                            </Table>


                            <FormGroup className="d-flex align-items-end justify-content-end">
                                <Button
                                    type="submit"
                                    color="primary"
                                    className="ms-1"
                                // disabled={disableSubmit}
                                >
                                    Update Results
                                </Button>
                            </FormGroup>
                        </div>
                    </AvForm>

                </div>
            </ModalBody>
        </Modal>
    )


}


export default ManualResultsModal;