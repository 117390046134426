import React, { useState } from "react";
import { Col } from "reactstrap";
import "react-quill/dist/quill.snow.css";
import { AvField } from "availity-reactstrap-validation";
import AddPrizes from "./AddPrizes";
import { FormGroup, Label, Input, FormFeedback } from "reactstrap";
import { useDispatch } from "react-redux";
import {
  setClearPrizeList,
  setScheduleCount,
  updatePrizeCount,
} from "../store/actions";
const CreatePrizes = ({
  BestOfMatches,
  matchId,
  count,
  resetError,
  EditPrizeCount,
  EditPrizeData,
  Edit,
  SetMaxPrizeCount,
  gameTypeMode,
}) => {
  const [numberOfPrizes, setNumberOfPrizes] = useState(0);
  const [numberOfMatches, setNumberOfMatches] = useState(0);
  const [numberOfMatchesError, setNumberOfMatchesError] = useState("");
  const handleKeyDown = (e) => {
    if (e.key === "." || e.key === "-") {
      e.preventDefault();
    }
  };
  const dispatch = useDispatch();
  if (BestOfMatches) {
    const handleInputChange = (e) => {
      e.preventDefault();
      if (parseInt(e.target.value) < 1 || e.target.value === "") {
        setNumberOfMatches(e.target.value);
        setNumberOfMatchesError("Should be greater than or equal to 1");
      } else if (parseInt(e.target.value) > 10) {
        setNumberOfMatches(e.target.value);
        setNumberOfMatchesError("Should be less than or equal to 10");
      } else {
        setNumberOfMatches(e.target.value);
        count(e.target.value);
        dispatch(setScheduleCount(parseInt(e.target.value)));
      }
      resetError();
    };
    return (
      <FormGroup className="mb-3 w-100">
        <Label>No. of matches*</Label>
        <Input
          name="noOfMatches"
          placeholder="No. of matches"
          type="number"
          onChange={(e) => {
            handleInputChange(e);
          }}
          min={1}
          max={10}
        />
        <FormFeedback className="errorMsgGames invalid-feedback">
          {numberOfMatches === "" || numberOfMatches < 1 || numberOfMatches > 10
            ? numberOfMatchesError
            : ""}
        </FormFeedback>
      </FormGroup>
    );
  } else {
    return (
      <>
        <AvField
          name="noOfPrizes"
          value={
            numberOfPrizes === 0
              ? EditPrizeCount === 0
                ? numberOfPrizes
                : EditPrizeCount
              : numberOfPrizes
          }
          label={gameTypeMode.value === 1 ? "No. of Prizes" : "No. of Prizes*"}
          placeholder="No. of Prizes"
          type="number"
          validate={{
            required: {
              value: gameTypeMode.value === 1 ? false : true,
              errorMessage: "Prizes is required.",
            },
            min: {
              value: 0,
              errorMessage: "Prizes should be greater than or equal to 1.",
            },
            max: {
              value: SetMaxPrizeCount,
              errorMessage:
                "Prizes should be less than maximum number of team.",
            },
            pattern: {
              value: /^\d+$/,
              errorMessage: "Prizes can't be in decimals.",
            },
          }}
          disabled={SetMaxPrizeCount === 0 || SetMaxPrizeCount === null}
          onKeyDown={handleKeyDown}
          onChange={(e) => {
            if (e.target.value > 0 && e.target.value <= SetMaxPrizeCount) {
              setNumberOfPrizes(Number(e.target.value));
              dispatch(updatePrizeCount(Number(e.target.value)));
            }
            if (e.target.value === "" || e.target.value === "0") {
              setNumberOfPrizes(0);
              dispatch(setClearPrizeList());
            }
          }}
        />
        <Col className="col-12 mt-3 ml-3">
          {numberOfPrizes <= 0 ? (
            EditPrizeCount <= 0 ? null : (
              <AddPrizes count={EditPrizeCount} PrizeData={EditPrizeData} />
            )
          ) : (
            <AddPrizes count={numberOfPrizes} PrizeData={EditPrizeData} />
          )}
        </Col>
      </>
    );
  }
};

export default CreatePrizes;
