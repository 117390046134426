import React, { useState } from "react";
import { AvField } from "availity-reactstrap-validation";
import { createScoreTable } from "./util";
import { useDispatch, useSelector } from "react-redux"; // Redux Store
import { setScoresList } from "../store/actions";
const AddScores = (count) => {
  const scoreList = useSelector((state) => state?.ScoreList);
  const [scoreBasedOnRanking, setScoreBasedOnRanking] = useState(
    scoreList.score
  );
  const dispatch = useDispatch();
  const handlePrize = (event, prizeListArr) => {
    const convertStringToNumber = event.target.value;
    const arr = prizeListArr.slice();
    arr[0].points = Number(convertStringToNumber);
    if (scoreBasedOnRanking === undefined) {
      const sendToReducer = arr;
      dispatch(setScoresList(sendToReducer));
      setScoreBasedOnRanking(() => {
        return sendToReducer;
      });
    } else {
      const index = scoreBasedOnRanking.findIndex(
        (el) => el.position === prizeListArr[0].position
      );

      if (index !== -1) {
        setScoreBasedOnRanking(() => {
          const sendToReducer = scoreBasedOnRanking.map(
            (dataToCheckForDuplicates) =>
              prizeListArr.find(
                (alreadyPresent) =>
                  alreadyPresent.position === dataToCheckForDuplicates.position
              ) || dataToCheckForDuplicates
          );
          dispatch(setScoresList(sendToReducer));
          return sendToReducer;
        });
      } else {
        const sendToReducer = [...scoreBasedOnRanking, ...arr];
        dispatch(setScoresList(sendToReducer));
        setScoreBasedOnRanking(() => {
          return sendToReducer;
        });
      }
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "." || e.key === "-") {
      e.preventDefault();
    }
  };
  return (
    <table className="table table-striped table-bordered lobbies-table responsiveTable addPrize-tournament">
      <thead>
        <tr>
          <th>Position</th>
          <th>Points</th>
        </tr>
      </thead>
      <tbody>
        {createScoreTable(count?.count, count.PrizeData).map(
          (prizeListMap, index) =>
            prizeListMap?.map((item) => {
              return (
                <tr id={index}>
                  <td>{item.positionLabel}</td>
                  <td>
                    <AvField
                      name={`points_${index}`}
                      placeholder="Enter amount"
                      type="number"
                      id={`amount_${index}`}
                      value={item.points.toString()}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Amount is required.",
                        },
                        min: {
                          value: 0,

                          errorMessage: "Amount should be greater than or equal to 0.",
                        },
                        max: {
                          value: 20,

                          errorMessage:
                            "Amount should be less than or equal to 1000.",
                        },
                        pattern: {
                          value: /^\d+$/,
                          errorMessage: "Amount can't be in decimals.",
                        },

                      }}
                      onBlur={(event) => handlePrize(event, prizeListMap)}
                      onKeyDown={handleKeyDown}
                    />
                  </td>
                </tr>
              );
            })
        )}
      </tbody>
    </table>
  );
};

export default AddScores;
