import { combineReducers } from "redux";
// Front
import Layout from "./layout/layoutReducer";
// Authentication
import Login from "./auth/login/loginReducer";
import Games from "./auth/login/gameReducer";
import PrizeList from "./auth/login/prizeReducer";
import ScoreList from "./auth/login/scoreReducer";
import ScheduleList from "./auth/login/scheduleReducer";
import count from "./auth/login/scheduleReducer";
const rootReducer = combineReducers({
  Layout,
  Login,
  Games,
  PrizeList,
  ScoreList,
  ScheduleList,
  count,
});

export default rootReducer;
