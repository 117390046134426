import { tournamentLastEntryBufferMinutes,tournamentLastEntryBufferFromNow } from './consts';
export const passwordCharacterData = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "!",
  "#",
  "$",
  "%",
  "&",
  "'",
  "(",
  ")",
  "*",
  "+",
  ",",
  "-",
  ".",
  "/",
  ":",
  ";",
  "<",
  "=",
  ">",
  "?",
  "@",
  "[",
  "]",
  "^",
  "_",
  "`",
  "{",
  "|",
  "}",
  "~",
];
export const charactersForRandomText = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
];
export const randomPasswordGenerator = ({ passWordLength, data }) => {
  let password = "";
  const array = new Uint32Array(data.length);
  window.crypto.getRandomValues(array);

  for (let i = 0; i < passWordLength; i++) {
    password += data[array[i] % data.length];
  }
  return password;
};

export const getPaymentMethod = (payment_method) => {
  return payment_method == "Stripe Instant" || payment_method == "Stripe"
    ? payment_method == "Stripe Instant"
      ? "Instant"
      : "Standard"
    : payment_method;
};
export const convertDateToHyphenFormat = (fieldDate) => {
  if (fieldDate === null) {
    return;
  } else {
    let formatToHyphenDate = new Date(fieldDate);
    let month = "" + (formatToHyphenDate?.getMonth() + 1);
    let day = "" + formatToHyphenDate?.getDate();
    let year = formatToHyphenDate?.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    let joinDate = [year, month, day].join("-");
    return joinDate;
  }
};
export const gameStatsTableHeadForApex = [
  "Team Name",
  "Player Name",
  "Kills",
  "Winnings",
  "Team Placement",
  "Survival Time",
  "Damage Dealt",
  "Platform",
];
export const gameStatsTableHeadForValorant = [
  "Team Name",
  "Player Name",
  "Kills",
  "Winnings",
  "Assists",
  "Damage Dealt",
  "Survival Time",
  "Platform",
];
export const FreeText = (freeAmountText, freeLobbyText) => {
  return (
    <>
      <p className="mb-1">{"$" + freeAmountText}</p>{" "}
      <p className="mb-0 freeEntry">{freeLobbyText?.join(" ")}</p>
    </>
  );
};
export const gameTypeEnumForChallenges = {
  1: "PPK",
  2: "Kill Race",
  3: "Traditional",
};
export const competitionStatus = {
  1: "Upcoming",
  2: "Active",
  3: "Ended",
  4: "Cancelled",
  5: "Completed",
};

export const compareDatesDDMMYYYY = (date1, date2) => {
  // Split each date into an array of [month, day, year]
  const date1Array = date1.split("-");
  const date2Array = date2.split("-");

  // Convert each element to an integer
  const date1Int = date1Array.map(Number);
  const date2Int = date2Array.map(Number);

  // Compare the year, month, and day in that order
  if (date1Int[2] > date2Int[2]) {
    return 1;
  } else if (date1Int[2] < date2Int[2]) {
    return -1;
  } else {
    if (date1Int[0] > date2Int[0]) {
      return 1;
    } else if (date1Int[0] < date2Int[0]) {
      return -1;
    } else {
      if (date1Int[1] > date2Int[1]) {
        return 1;
      } else if (date1Int[1] < date2Int[1]) {
        return -1;
      } else {
        return 0; // The dates are eq
      }
    }
  }
};
export const dropdownOptions = [
  {
    value: "username",
    text: "Username : Asc",
  },
  {
    value: "-username",
    text: "Username : Desc",
  },

  {
    value: "email",
    text: "Email : Asc",
  },
  {
    value: "-email",
    text: "Email : Desc",
  },
  {
    value: "create_date",
    text: "Date & Time : Asc",
  },
  {
    value: "-create_date",
    text: "Date & Time : Desc",
  },
];
export const DropDownOptionsChallenges = [
  {
    value: "match_date",
    text: "Match Date : Asc",
  },
  {
    value: "-match_date",
    text: "Match Date : Desc",
  },
  {
    value: "1",
    text: "Challenge type : Team",
  },
  {
    value: "2",
    text: "Challenge type : Open",
  },
];
export const dropdownOptionsForChallengeMode = [
  {
    value: "1",
    text: "Open",
  },
  {
    value: "2",
    text: "Team",
  },
];

export const challengesStatusOption = [
  {
    value: "1",
    text: "Active",
  },
  {
    value: "2",
    text: "Cancelled",
  },
  {
    value: "3",
    text: "Completed",
  },
  {
    value: "4",
    text: "Ended",
  },
];
export const dropdownOptionsForReferral = [
  {
    value: "",
    text: "All",
  },
  {
    value: "total_earn",
    text: "Total Earn : Asc",
  },
  {
    value: "-total_earn",
    text: "Total Earn : Desc",
  },
  {
    value: "referee__create_date",
    text: "Joining Date : Asc",
  },
  {
    value: "-referee__create_date",
    text: "Joining Date : Desc",
  },
  {
    value: "referee__username",
    text: "Username : Asc",
  },
  {
    value: "-referee__username",
    text: "Username : Desc",
  },
  {
    value: "referee__email",
    text: "Email : Asc",
  },
  {
    value: "-referee__email",
    text: "Email : Desc",
  },
];

export const gamesToCategories = (games) => {
  let traditional = [];
  let ppk = [];
  let killRace = [];
  games.forEach((data) => {
    data.types_of_game.forEach((gameType) => {
      switch (gameType) {
        case "1":
          ppk.push({ ...data, value: data.id, label: data.name });
          break;
        case "2":
          killRace.push({ ...data, value: data.id, label: data.name });
          break;
        case "3":
          traditional.push({ ...data, value: data.id, label: data.name });
          break;
        default:
          break;
      }
    });
  });
  return {
    ppkGames: ppk,
    traditionalGames: traditional,
    killRaceGames: killRace,
    allGames: games.map((game) => ({
      ...game,
      value: game.id,
      label: game.name,
    })),
    modeObj: {
      1: ppk,
      2: killRace,
      3: traditional,
    },
    modeObjTournaments: {
      1: ppk,
      2: killRace,
      3: traditional,
    },
    modeGameTypeObj: getModesOfGame([...ppk, ...traditional, ...killRace]),
    modeGameTypeTournamentObj: getModesOfTournamentGame([
      ...ppk,
      ...traditional,
      ...killRace,
    ]),
  };
};

export const getModesOfGame = (games) => {
  const temp = {};
  games.forEach((game) => {
    temp[game.slug] = game.game_allowed_mode?.map((mode) => ({
      ...mode,
      value: mode.mode,
      label: mode.mode_type,
    }));
  });
  // temp["apex-legends"] = temp["apex-legends"].filter((mode) => mode.mode != 6);
  return temp;
};

export const getModesOfTournamentGame = (games) => {
  const temp = {};
  games.forEach((game) => {
    temp[game.slug] = game.game_allowed_mode?.map((mode) => ({
      ...mode,
      value: mode.mode,
      label: mode.mode_type,
      1: true,
      2: true,
      3: true,
    }));
  });

  temp["apex-legends"] = temp["apex-legends"].map((mode) => {
    if (mode.value == 1 || mode.value == 2) {
      return { ...mode, 3: false };
    } else if (mode.value == 6) {
      return { ...mode, 1: false };
    }
    return mode;
  });

  return temp;
};

export const getModeLabel = (type, mode) => {
  return type == 1
    ? lobbyModeFilters[mode]?.label
    : challengeModeFilters[mode]?.label;
};

export const getModeLabelsSelect = (type, modeArr, text = "") => {
  if (!modeArr?.length) return [];
  const ans = modeArr?.filter((obj) => !obj.mode_for_game_type ? true : obj.mode_for_game_type == type.value).map((mode) => ({
    ...mode,
    label: getModeLabel(type.value, mode.mode),
  }));
  return ans;
};

export const lobbyModeFilters = {
  1: { value: 1, label: "Solos" },
  2: { value: 2, label: "Duos" },
  3: { value: 3, label: "Trios" },
  4: { value: 4, label: "Quads" },
  5: { value: 5, label: "Penta" },
  6: { value: 6, label: "Hexa" },
};
export const challengeModeFilters = {
  1: { value: 1, label: "1v1" },
  2: { value: 2, label: "2v2" },
  3: { value: 3, label: "3v3" },
  4: { value: 4, label: "4v4" },
  5: { value: 5, label: "5v5" },
  6: { value: 6, label: "6v6" },
};

export const gameTypeFilters = [
  { value: 1, label: "PPK" },
  { value: 2, label: "Kill Race" },
  { value: 3, label: "Traditional" },
];

export const gameTypeFiltersTournaments = [
  { value: 1, text: "PPK", label: "PPK" },
  { value: 3, text: "Traditional", label: "Traditional" },
];

export const getGameFromId = (games = [], gameId) => {
  const res = games?.filter(({ id }) => gameId == id)?.[0];
  return res;
};
export const GAME_MAP_CHALLENGES = {
  "apex-legends": {
    label: "Apex Legends",
    permissionType: "Apex",
    permissionDeleteType: "delete_apex",
    permissionChangeType: "change_apex",
  },
  valorant: {
    label: "Valorant",
    permissionType: "Valorant",
    permissionDeleteType: "delete_valorant",
    permissionChangeType: "change_valorant",
  },
};

export const canEditLobbyField = ({
  editMode,
  is_manual,
  current_status,
  editIfActive,
}) => {
  return !(
    !editMode ||
    current_status == 1 ||
    (current_status == 2 && editIfActive && is_manual)
  );
};

export const canFetchResults = (item) =>
  item?.is_manual && item.stats_status == 1;
export const bracketStyleOptionsTraditonal = [
  { value: 1, label: "Single Elimination" },
  { value: 2, label: "Double Elimination" },
  { value: 3, label: "Round Robin" },
  // { value: 4, label: "Winners and Losers" },
];
export const bracketStyleOptionsPPK = [{ value: 5, label: "PPK" }];

export const seedingOptions = [
  { value: 1, label: "Qualifiers" },
  { value: 2, label: "Manual Placement" },
  { value: 3, label: "Team Placement" },
  { value: 4, label: "Ranked Placement" },
  { value: 5, label: "Random" },
];
export const seedingOptionsPPK = [{ value: 5, label: "Random" }];

export const bracketStyleMinMaxOptions = [
  { value: 4, label: "4" },
  { value: 8, label: "8" },
  { value: 16, label: "16" },
  { value: 32, label: "32" },
  { value: 64, label: "64" },
  { value: 128, label: "128" },
  { value: 256, label: "256" },
  { value: 512, label: "512" },
];
export const createPrizeTable = (count, data) => {
  if (data?.length === 0 || data === undefined) {
    for (var prizeArrayList = [], index = 1; index <= count; index++)
      prizeArrayList.push([
        {
          // id: index,
          position: index,
          positionLabel: index + appendSuffixToNumbers(index),
          price: 0,
        },
      ]);
    return prizeArrayList;
  } else {
    for (
      var filledPrizeList = [], filledPrizeIndex = 1;
      filledPrizeIndex <= count;
      filledPrizeIndex++
    )
      filledPrizeList.push([
        {
          position:
            data[filledPrizeIndex - 1]?.position === undefined
              ? filledPrizeIndex
              : data[filledPrizeIndex - 1]?.position,
          positionLabel:
            data[filledPrizeIndex]?.position === undefined
              ? filledPrizeIndex + appendSuffixToNumbers(filledPrizeIndex)
              : data[filledPrizeIndex - 1]?.position +
              appendSuffixToNumbers(filledPrizeIndex),
          price:
            data[filledPrizeIndex - 1]?.price === undefined
              ? 0
              : data[filledPrizeIndex - 1]?.price,
        },
      ]);

    return filledPrizeList;
  }
};

export const createScoreTable = (count, data) => {
  if (data?.length === 0 || data === undefined) {
    for (var prizeArrayList = [], index = 1; index <= count; index++)
      prizeArrayList.push([
        {
          // id: index,
          position: index,
          positionLabel: index + appendSuffixToNumbers(index),
          points: 0,
        },
      ]);
    return prizeArrayList;
  } else {
    for (
      var filledPrizeList = [], filledPrizeIndex = 1;
      filledPrizeIndex <= count;
      filledPrizeIndex++
    )
      filledPrizeList.push([
        {
          position:
            data[filledPrizeIndex - 1]?.position === undefined
              ? filledPrizeIndex
              : data[filledPrizeIndex - 1]?.position,
          positionLabel:
            data[filledPrizeIndex]?.position === undefined
              ? filledPrizeIndex + appendSuffixToNumbers(filledPrizeIndex)
              : data[filledPrizeIndex - 1]?.position +
              appendSuffixToNumbers(filledPrizeIndex),
              points:
            data[filledPrizeIndex - 1]?.points === undefined
              ? 0
              : data[filledPrizeIndex - 1]?.points,
        },
      ]);

    return filledPrizeList;
  }
};
export const createPrizeTableFromApi = (count, data) => {
  for (
    var filledPrizeList = [], filledPrizeIndex = 1;
    filledPrizeIndex <= count;
    filledPrizeIndex++
  )
    filledPrizeList.push({
      position:
        data[filledPrizeIndex - 1]?.position === undefined
          ? filledPrizeIndex
          : data[filledPrizeIndex - 1]?.position,
      positionLabel:
        data[filledPrizeIndex]?.position === undefined
          ? filledPrizeIndex + appendSuffixToNumbers(filledPrizeIndex)
          : data[filledPrizeIndex - 1]?.position +
          appendSuffixToNumbers(filledPrizeIndex),
      price:
        data[filledPrizeIndex - 1]?.price === undefined
          ? 0
          : data[filledPrizeIndex - 1]?.price,
    });

  return filledPrizeList;
};
function appendSuffixToNumbers(numberForSuffix) {
  return (
    ["st", "nd", "rd"][((((numberForSuffix + 90) % 100) - 10) % 10) - 1] || "th"
  );
}
export const appendSuffixToTournamentPosition = (position) => {
  return ["st", "nd", "rd"][((((position + 90) % 100) - 10) % 10) - 1] || "th";
};

// create matches table
export const createMatchesTable = (count) => {
  for (var prizeArrayList = [], index = 1; index <= count; index++)
    prizeArrayList.push([
      {
        id: index,
        match: "Match " + index,
        matches: {
          id: index,
          match: "Match " + index,
          map: "",
          startDate: "",
          startTime: "",
          endDate: "",
          endTime: "",
          adminCode: "",
          statisticalCode: "",
          participantCode: "",

          // positionLabel: index + appendSuffixToNumbers(index),
          // price: 0,
        },
      },
    ]);
  return prizeArrayList;
};
export const createStartEndTimeList = (count, time) => {
  if (time.length === 0) {
    for (var startEndList = [], index = 1; index <= count; index++)
      startEndList.push({
        id: index,
        start_Time: null,
        end_Time: null,
      });
    return startEndList;
  } else {
    for (
      var filledTimeList = [], filledIndex = 1;
      filledIndex <= count;
      filledIndex++
    )
      filledTimeList.push({
        id:
          time[filledIndex - 1]?.id === undefined
            ? filledIndex
            : time[filledIndex - 1]?.id,
        start_Time:
          time[filledIndex - 1]?.start_Time === undefined
            ? null
            : time[filledIndex - 1]?.start_Time,
        end_Time:
          time[filledIndex - 1]?.end_Time === undefined
            ? null
            : time[filledIndex - 1]?.end_Time,
      });

    return filledTimeList;
  }
};
export const getEstDateObj = (date, timeZone) => {
  if (typeof date === "string") {
    return new Date(
      new Date(date).toLocaleString("en-US", {
        timeZone,
      })
    );
  }

  return new Date(
    date.toLocaleString("en-US", {
      timeZone,
    })
  );
};
export const getEstDate = () => {
  let getEstDateTour = new Date();
  let estDate = getEstDateTour.toLocaleDateString("en-US", {
    timeZone: "America/New_York",
  });
  let fromatExtractedDate = new Date(estDate);
  let month = "" + (fromatExtractedDate.getMonth() + 1);
  let day = "" + fromatExtractedDate.getDate();
  let year = fromatExtractedDate.getFullYear();
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  return [year, month, day].join("-");
};
export const getEstDateToConvert = (dateConversion) => {
  let getEstDateAdd = new Date(dateConversion);
  let month = "" + (getEstDateAdd.getMonth() + 1);
  let day = "" + getEstDateAdd.getDate();
  let year = getEstDateAdd.getFullYear();
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  return [year, month, day].join("-");
};
export const compareDateTournamentForBestOfMatches = (
  currentDateTime,
  changedDateTime
) => {
  const currentDateTimeEST = new Date(
    currentDateTime.toLocaleString("en-US", { timeZone: "America/New_York" })
  );

  const userSelectedDateTimeEST = new Date(
    changedDateTime.toLocaleString("en-US", { timeZone: "America/New_York" })
  );

  if (userSelectedDateTimeEST < currentDateTimeEST) {
    return true;
  }

  return false;
};
export const compareDateTournament = (currentDateTime, changedDateTime) => {
  const currentEstDateTimeObj = getEstDateObj(new Date(), "America/New_York");
  const adjustedDateTimeEST = new Date(changedDateTime);

  const timeDifference = adjustedDateTimeEST - currentEstDateTimeObj;
  const minutesDifference = timeDifference / (1000 * 60 );

  return minutesDifference < tournamentLastEntryBufferMinutes;
};

export const compareDateForTimeValidation = (currentDate, userInputDate) => {
  if (+new Date(userInputDate) === +new Date(currentDate)) {
    const isSame = -1;
    return isSame;
  } else if (new Date(userInputDate) > new Date(currentDate)) {
    return true;
  } else return false;
};

export const tournamentStatusOption = [
  {
    value: "current",
    text: "Active",
  },
  {
    value: "upcoming",
    text: "Upcoming",
  },
  {
    value: "cancelled",
    text: "Cancelled",
  },
  {
    value: "ended",
    text: "Ended",
  },
];
export const freeTournamentStatusOption = [
  { value: "1", text: "Yes" },
  { value: "0", text: "No" },
];
export const compareTwoArraysOfObjects = (arr1, arr2) => {
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i].startDate === arr2[0].startDate) {
      if (arr1[i].start_Time === arr2[0].start_Time) {
        return true;
      } else if (
        new Date("1/1/1999 " + arr1[i].start_Time) >
        new Date("1/1/1999 " + arr2[0].start_Time)
      ) {
        //   return -1;
        if (arr1.length > i) {
          i++;
        } else {
          return true;
        }
      } else if (
        new Date("1/1/1999 " + arr1[i].start_Time) <
        new Date("1/1/1999 " + arr2[0].start_Time)
      ) {
        if (arr1.length === 1) {
          return false;
        } else {
          continue;
        }
      } else if (arr1.length === arr2.length) {
        return false;
      }
      // else if (i === arr1.length) {
      //   return false;
      // }
      else continue;
    } else if (
      arr1[i].startDate < arr2[0].startDate ||
      arr1[i].startDate > arr2[0].startDate
    ) {
      //  return false;
      if (arr1.length > i) {
        // i++;
        continue;
      } else {
        return true;
      }
    }
    // else if (arr1[i].startDate > arr2[0].startDate) {
    //   // return false;
    //   if (arr1.length > i) {
    //     // i++;
    //     continue;
    //   } else {
    //     return true;
    //   }
    // }
    else {
      //i++;

      continue;
    }
  }
};
export const filterLabel = (ArrOfObj, labelTofilter) => {
  return ArrOfObj.filter((arr) => arr.label === labelTofilter);
};
export const filterTeam = (ArrOfObjteam, valuesTofilter) => {
  return ArrOfObjteam.filter((arr) => arr.value == valuesTofilter);
};
export const csvConverterToPreviewFile = (file) => {
  const result = [];
  let splitText = file.split(/[\r\n,]/);
  let removeEmptyObjectFromSplitText = splitText.filter(
    (el) => Object.keys(el).length
  );
  for (
    let iAcpt = 0, a = removeEmptyObjectFromSplitText;
    iAcpt < a.length;
    iAcpt += 2
  ) {
    result.push({
      tournamentName: a[iAcpt],
      tournamentRank: a[iAcpt + 1],
    });
  }
  return result;
};
export const dateFormat = (format) => {
  // TODO: check if this is need it or not
  // var userTimezoneOffset = date.getTimezoneOffset() * 60000;
  const date = new Date(format);
  const options = {
    weekday: "short",
    day: "numeric",
    year: "numeric",
    month: "long",
  };
  return date.toLocaleString("en-US", options);
  // return new Date(date.getTime() + userTimezoneOffset).toLocaleString("en-US", options);
};
export const dateTimeFormatter = (format) => {
  const [dateString, timeString, timeZoneString] = format.split(" ");
  const [month, day, year] = dateString.split("-");
  const date = new Date(year, month - 1, day);
  const formattedDate = date.toLocaleDateString("en-US", {
    weekday: "short",
    month: "long",
    day: "numeric",
    year: "numeric",
  });
  return `${formattedDate} at ${timeString} ${timeZoneString}`;
};
export const getFilterText = (options, filterVal) => {
  return options.map((item) => {
    return filterVal === item.value ? item.text : null;
  });
};
export const getFilterGame = (optionsGame, filterGameVal) => {
  return optionsGame.map((item) => {
    return filterGameVal === item.id ? item.name : null;
  });
};
export const getFilterChallengeGame = (
  optionsGame,
  filterChallengesGameVal
) => {
  return optionsGame.map((item) => {
    return filterChallengesGameVal === item.slug ? item.name : null;
  });
};
export const getFilterTextTournament = (options, filterVal) => {
  return options.map((item) => {
    return filterVal === item.value ? item.label : null;
  });
};
export const checkTimeDifference = (start, userDate) => {
  const currentDateTime = new Date();
  const [startHour, startMinute] = start.split(":");
  const currentDateTimeInEST = new Date(
    currentDateTime.toLocaleString("en-US", { timeZone: "America/New_York" })
  );
  const selectedDateTime = new Date(`${userDate} ${startHour}:${startMinute}`);
  const bufferMinutesAheadDateTime = new Date(
    currentDateTimeInEST.getTime() + tournamentLastEntryBufferMinutes * 60 * 1000
  );
  if (
    selectedDateTime > currentDateTimeInEST &&
    selectedDateTime > bufferMinutesAheadDateTime
  ) {
    return true; // Skip time comparison for future dates
  } else {
    const currentTime = new Date(
      currentDateTimeInEST.getFullYear(),
      currentDateTimeInEST.getMonth(),
      currentDateTimeInEST.getDate(),
      startHour,
      startMinute,
      0,
      0
    );

    const timeDifference =
      (currentTime - currentDateTimeInEST) / (1000 * 60 * 60);
    const isTimeDifferenceGreaterThanTwoHours = timeDifference >= 2;
    if (isTimeDifferenceGreaterThanTwoHours) {
      return true;
    } else {
      return false;
    }
  }
};

export const getCurrentTimeESTWithTournamentLastEntryBufferMinutesAhead = () => {
  const currentDateTime = new Date();
  const currentDateTimeEST = new Date(
    currentDateTime.toLocaleString("en-US", { timeZone: "America/New_York" })
  );
  currentDateTimeEST.setMinutes(currentDateTimeEST.getMinutes() + tournamentLastEntryBufferMinutes);

  const timeOptions = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  };

  // Format the time using toLocaleTimeString
  const time = currentDateTimeEST.toLocaleTimeString("en-US", timeOptions);
  return time;
};
export const getEstDateObjTournamentLastEntryBufferMinutesAheadPlusFive = () => {
  const currentDateTime = new Date();
  const currentDateTimeEST = new Date(
    currentDateTime.toLocaleString("en-US", { timeZone: "America/New_York" })
  );
  currentDateTimeEST.setMinutes(currentDateTimeEST.getMinutes() + tournamentLastEntryBufferMinutes);
  currentDateTimeEST.setMinutes(currentDateTimeEST.getMinutes() + tournamentLastEntryBufferFromNow);
  return currentDateTimeEST;
};
export const inviteStatus = {
  1: "Accepted",
  2: "Rejected",
  3: "Pending",
};
export const singleElimination = [
  { value: "brackets", label: "Brackets" },
  // { value: "standings", label: "Standings" },
];
export const doubleElimination = [
  { value: "upper", label: "Winners" },
  { value: "lower", label: "Losers" },
  { value: "final", label: "Championship" },
  // { value: "standings", label: "Standings" },
];
export const roundRobin = [
  { value: "groups", label: "Groups" },
  { value: "matches", label: "Matches" },
];

export const getFromLocalStorage = (key) => {
  try {
    const item = localStorage.getItem(key);
    return JSON.parse(item);
  } catch (error) { }
};
export const dateTimeFormatterForTournamentMatchinfo = (format) => {
  const [dateString, timeString, timeZoneString] = format.split(" ");
  const [year, month, day] = dateString.split("-");
  const date = new Date(year, month - 1, day);
  const formattedDate = date.toLocaleDateString("en-US", {
    weekday: "short",
    month: "long",
    day: "numeric",
    year: "numeric",
  });
  return `${formattedDate} at ${timeString} ${timeZoneString}`;
};
