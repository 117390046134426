import PropTypes from "prop-types";
import React from "react";
import { Switch, BrowserRouter as Router, useLocation } from "react-router-dom";
import { connect } from "react-redux";
// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";
// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";
// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
// Import scss
import "./assets/scss/theme.scss";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

function App() {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const location = useLocation();

    // Add title update logic
    React.useEffect(() => {
        const path = location.pathname;
        let pageTitle = "Ryvals"; // Default title
        console.log(path);

        if (path !== "/") {
            // Remove leading slash and split by remaining slashes
            const pathSegments = path.substring(1).split("/");
            // Take the last segment, replace hyphens/underscores with spaces, and capitalize words
            const routeText = pathSegments[pathSegments.length - 1]
                .split(/[-_]/)
                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                .join(" ");

            pageTitle = `${routeText}`;
        }

        document.title = pageTitle;
    }, [location]);

    return (
        <React.Fragment>
            <ToastContainer />
            <Router>
                <Switch>
                    {authRoutes &&
                        authRoutes.map((route, idx) => (
                            <Authmiddleware
                                path={route.path}
                                layout={NonAuthLayout}
                                component={route.component}
                                key={idx}
                                isAuthProtected={false}
                            />
                        ))}

                    {userRoutes &&
                        userRoutes.map((route, idx) => (
                            <Authmiddleware
                                exact={true}
                                path={route.path}
                                layout={VerticalLayout}
                                component={route.component}
                                key={idx}
                                isAuthProtected={true}
                                permission={obj === null ? null : obj.extras?.permissions}
                            />
                        ))}
                </Switch>
            </Router>
        </React.Fragment>
    );
}

App.propTypes = {
    layout: PropTypes.any,
};

const mapStateToProps = (state) => {
    return {
        layout: state.Layout,
    };
};

export default connect(mapStateToProps, null)(App);
