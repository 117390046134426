import React, { useState } from "react";
import { Col } from "reactstrap";
import "react-quill/dist/quill.snow.css";
import { AvField } from "availity-reactstrap-validation";
import AddScores from "./AddScores";
const CreateScores = ({
  EditPrizeCount,
  EditPrizeData,
  SetMaxPrizeCount,
  gameTypeMode,
}) => {
  return (
    <>
      <Col className="col-12 mt-3 ml-3">
        <AddScores
          count={20}
          PrizeData={[
            { position: 1, points: 12 },
            { position: 2, points: 9 },
            { position: 3, points: 7 },
            { position: 4, points: 5 },
            { position: 5, points: 4 },
            { position: 6, points: 3 },
            { position: 7, points: 3 },
            { position: 8, points: 2 },
            { position: 9, points: 2 },
            { position: 10, points: 2 },
            { position: 11, points: 1 },
            { position: 12, points: 1 },
            { position: 13, points: 1 },
            { position: 14, points: 1 },
            { position: 15, points: 1 },
            { position: 16, points: 0 },
            { position: 17, points: 0 },
            { position: 18, points: 0 },
            { position: 19, points: 0 },
            { position: 20, points: 0 },
          ]}
        />
      </Col>
    </>
  );
};

export default CreateScores;
