import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
    Card,
    CardBody,
    Col,
    Row,
    NavLink,
    NavItem,
    Nav,
    TabContent,
    TabPane,
} from "reactstrap";
import { getTeamDetail } from "../../services/teams_api_helper";
import classnames from "classnames";
import PlayersList from "./PlayerList";
import FilterPermission from "../../helpers/FilterPermission";
import { filterOutPermissionToShowHide } from "../../helpers/PermissionUtils";
import { useSelector } from "react-redux";
const TeamDetail = (props) => {
    const returnUrl = "/teams";
    const [team, setTeam] = useState();
    const games = useSelector((state) => state.Games.game?.allGames);
    const [challenges, setChallenges] = useState(null);
    const [isLoading, setisLoading] = useState(false);
    const teamUrl = window.location.href;
    const teamId = teamUrl.substring(teamUrl.lastIndexOf("/") + 1);
    const [activeTab, setActiveTab] = useState("1");
    const [deleteId, setDeleteId] = useState(null);
    const [vs, setVs] = useState(null);
    const [deleteModal, setdeleteModal] = useState(false);
    useEffect(() => {
        if (teamId > 0) {
            getDetail();
        } else {
            goToBack();
        }
    }, [teamId]);

    const getDetail = async () => {
        setisLoading(true);
        await getTeamDetail(teamId)
            .then((res) => {
                // setChallenges(res);

                setTeam(res);
                setisLoading(false);
            })
            .catch(() => {
                setTeam(null);
                setisLoading(false);
                // goToBack();
            });
    };

    const canPerform = (gameObjType, gameObjPermissionType) => {
        const filteredPermission = FilterPermission(props.permission, gameObjType);
        if (filteredPermission.length !== 0) {
            const setdelete = filterOutPermissionToShowHide(
                filteredPermission[0].permissions,
                gameObjPermissionType
            );
            return setdelete;
        }
        return false;
    };

    const goToBack = () => {
        props.history.push(returnUrl);
    };
    const toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };
    const openAlert = (id, vrs) => {
        setDeleteId(id);
        setVs(vrs);
        setdeleteModal(true);
    };

    const handleCLoseAlert = () => {
        setdeleteModal(false);
    };

    return (
        <>
            <div className="page-content">
                <Breadcrumbs breadcrumbItem="Team Detail" />
                <Row className="mb-4">
                    <Col>
                        <p>
                            <Link to={{ pathname: returnUrl }}>
                                <i className="mdi mdi-arrow-left"></i> back
                            </Link>
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Col lg={12}>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: activeTab === "1",
                                        })}
                                        onClick={() => {
                                            toggle("1");
                                        }}
                                    >
                                        <span className="d-block d-sm-none">
                                            <i className="fas fa-home"></i>
                                        </span>
                                        <span className="d-none d-sm-block">Team Details</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: activeTab === "2",
                                        })}
                                        onClick={() => {
                                            toggle("2");
                                        }}
                                    >
                                        <span className="d-block d-sm-none">
                                            <i className="far fa-user"></i>
                                        </span>
                                        <span className="d-none d-sm-block">Team players</span>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                        {isLoading ? (
                            <div class="spinner-grow transaction-spinner" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        ) : (
                            team && (
                                <TabContent
                                    activeTab={activeTab}
                                    className="p-3 text-muted lobby-details-view"
                                    style={{
                                        marginTop: "-1pc",
                                        width: "103%",
                                        marginLeft: "-15px",
                                    }}
                                >
                                    {" "}
                                    <TabPane tabId="1">
                                        <Card className="mb-0">
                                            <CardBody>
                                                <Row>
                                                    <Col className="col-12 mb-3">
                                                        <strong className="capitalize">Team Name :</strong>
                                                        {team.name}
                                                    </Col>
                                                    <Col className="col-12 mb-3">
                                                        <strong className="capitalize">Number of Players :</strong>{" "}
                                                        {team?.has_player}
                                                    </Col>
                                                    <Col className="col-12 mb-3">
                                                        <strong className="capitalize">Game :</strong> {team.game?.name}
                                                    </Col>
                                                    <Col className="col-12 mb-3">
                                                        <strong className="capitalize">Mode :</strong>{" "}
                                                        {team.mode?.name}
                                                    </Col>
                                                    <Col className="col-12 mb-3">
                                                        <strong className="capitalize">Created By :</strong>{" "}
                                                        {team.creator?.email}
                                                    </Col>

                                                    <Col className="col-12 mb-3">
                                                        <strong className="capitalize">Create Date:</strong>{" "}
                                                        {new Date(team?.create_date).toLocaleString(
                                                            "en-US",
                                                            {
                                                                weekday: "short", // long, short, narrow
                                                                day: "numeric", // numeric, 2-digit
                                                                year: "numeric", // numeric, 2-digit
                                                                month: "long", // numeric, 2-digit, long, short, narrow
                                                                hour: "numeric", // numeric, 2-digit
                                                                minute: "numeric", // numeric, 2-digit
                                                                second: "numeric", // numeric, 2-digit
                                                                timeZone: "UTC",
                                                                hour12: false,
                                                            }
                                                        )}
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </TabPane>
                                    <TabPane
                                        tabId="2"
                                        className="p-3 enrolled-players-tab"
                                        style={{
                                            marginTop: "-1pc",
                                            width: "154%",
                                            marginLeft: "-15px",
                                        }}
                                    >
                                        <PlayersList
                                            team={team}
                                        />
                                    </TabPane>
                                </TabContent>
                            )
                        )}
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default withRouter(TeamDetail);
