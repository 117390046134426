import { withRouter, Link } from "react-router-dom";

import {
  Card,
  CardBody,
  Col,
  Row,
  FormGroup,
  Button,
  TabContent,
  TabPane,
  NavLink,
  NavItem,
  Nav,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  changeStatusLobby,
  getLobbyDetail,
  deleteLobby,
  overrideLobby,
  sendImageCount,
  fetchStats,
  cancelLobby,
} from "../../services/lobby_api_helper";
import classnames from "classnames";
import React, { Component } from "react";

// Alert
import SweetAlert from "react-bootstrap-sweetalert";
import EnrolledPlayersList from "../LobbyList/EnrolledPlayersList";
import LobbyResult from "../LobbyList/LobbyResult";
import sanitizeHtml from "sanitize-html";
import EnrolledTeamList from "../LobbyList/EnrolledTeamList";
import { toast } from "react-toastify";
import toastrOptions from "../../helpers/toastr-options/toastr-options";
import S3 from "react-aws-s3";
import {
  S3Client,
  PutObjectCommand,
  GetObjectCommand,
} from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import checkCircle from "../../assets/images/check-circle.svg";
import Dropzone from "react-dropzone";
import FilterPermission from "../../helpers/FilterPermission";
import { filterOutPermissionToShowHide } from "../../helpers/PermissionUtils";
import { GameTypeMap } from "../LobbyForm/data";
import { canFetchResults } from "../../helpers/util";
import Loader from "../../components/Common/Loader";
import { dateFormat } from "../../helpers/util";
import { isEmpty } from "lodash";
import ManualResultsModal from "./ManualResultsModal";
class LobbyDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lobby: null,
      enroll: null,
      confirm_alert: false,
      delete_alert: false,
      lobby_override_alert: false,
      cancel_alert: false,
      results_alert: false,
      activeTab: "1",
      imageUrls: [],
      invalidFile: { invalidFileBoolean: false, invalidFileMsg: "" },
      csvData: [],
      csvFileName: "",
      onSubmitFile: {
        file: [],
        fileName: "",
        fileLength: null,
        isMultiple: null,
      },
      imageFileName: [],
      files: [],
      openPreviewModal: false,
      openManualResultsModal: false,
      confirm_override_automatic_cancel_alert: false,
      selectedPreviewId: null,
      processingLoader: false,
      fileUploadSucess: {
        fileBoolean: false,
        fileMessage: "",
      },
      disableSubmit: false,
      permission: [],
      changePermission: false,
      deletePermission: false,
      viewPermission: false,
      uploadPermission: false,
      isLoading: false,
      tournamentData: {},
    };
  }
  componentDidMount() {
    const { location } = this.props;
    const params = new URLSearchParams(location.search);
    const data = JSON.parse(decodeURIComponent(params.get("data")));
    this.setState({ tournamentData: data });

    let lobbyId = this.props.match.params.lobbyId;
    if (lobbyId == "add") {
      this.props.history.push("/lobby");
    } else {
      if (lobbyId) {
        this.getLobbyData(lobbyId);
      }
      if (isEmpty(this.props.permission)) {
        this.setState({
          changePermission: true,
          deletePermission: true,
          viewPermission: true,
          uploadPermission: true,
        });
      } else {
        this.callSetPermission();
      }
    }
  }

  callSetPermission = () => {
    const { location } = this.props;
    const params = new URLSearchParams(location.search);
    const data = JSON.parse(decodeURIComponent(params.get("data")));
    if (
      this.props.location.state?.item.game.name == "Apex Legends" ||
      this.props.location?.data?.nameOfGame == "Apex Legends" ||
      data?.nameOfGame == "Apex Legends"
    ) {
      const type = "Apex";
      const typeChange = "change_apex";
      const typeDelete = "delete_apex";
      const typeUpload = "can_upload_publish_apex";
      const typeView = "view_apex";

      const filteredPermission = FilterPermission(this.props.permission, type);
      if (filteredPermission.length !== 0) {
        const setchange = filterOutPermissionToShowHide(
          filteredPermission[0].permissions,
          typeChange
        );
        const setdelete = filterOutPermissionToShowHide(
          filteredPermission[0].permissions,
          typeDelete
        );
        const setview = filterOutPermissionToShowHide(
          filteredPermission[0].permissions,
          typeView
        );
        const setupload = filterOutPermissionToShowHide(
          filteredPermission[0].permissions,
          typeUpload
        );
        this.setState(
          {
            changePermission: setchange,
            deletePermission: setdelete,
            viewPermission: setview,
            uploadPermission: setupload,
            permission: filteredPermission[0].permissions,
          },
          () => {
            console.log("");
          }
        );
      }
    } else if (
      this.props.location.state?.item.game.name == "Call of Duty Mobile" ||
      this.props.location?.data?.nameOfGame == "Call of Duty Mobile" ||
      this.props.location.state?.item.game.name == "COD Mobile" ||
      this.props.location?.data?.nameOfGame == "COD Mobile" ||
      data?.nameOfGame == "Call of Duty Mobile" ||
      data?.nameOfGame == "COD Mobile"
    ) {
      const type = "COD Mobile";
      const typeChange = "change_codmobile";
      const typeDelete = "delete_codmobile";
      const typeUpload = "can_upload_publish_cod_mobile";
      const typeView = "view_codmobile";

      const filteredPermission = FilterPermission(this.props.permission, type);
      if (filteredPermission.length !== 0) {
        const setchange = filterOutPermissionToShowHide(
          filteredPermission[0].permissions,
          typeChange
        );
        const setdelete = filterOutPermissionToShowHide(
          filteredPermission[0].permissions,
          typeDelete
        );
        const setview = filterOutPermissionToShowHide(
          filteredPermission[0].permissions,
          typeView
        );
        const setupload = filterOutPermissionToShowHide(
          filteredPermission[0].permissions,
          typeUpload
        );
        this.setState({
          changePermission: setchange,
          deletePermission: setdelete,
          viewPermission: setview,
          uploadPermission: setupload,
          permission: filteredPermission[0].permissions,
        });
      }
    } else if (
      this.props.location.state?.item.game.name == "Fortnite" ||
      this.props.location?.data?.nameOfGame == "Fortnite" ||
      data?.nameOfGame == "Fortnite"
    ) {
      const type = "Fortnite";
      const typeChange = "change_fortnite";
      const typeDelete = "delete_fortnite";
      const typeUpload = "can_upload_publish_fortnite";
      const typeView = "view_fortnite";

      const filteredPermission = FilterPermission(this.props.permission, type);
      if (filteredPermission.length !== 0) {
        const setchange = filterOutPermissionToShowHide(
          filteredPermission[0].permissions,
          typeChange
        );
        const setdelete = filterOutPermissionToShowHide(
          filteredPermission[0].permissions,
          typeDelete
        );
        const setview = filterOutPermissionToShowHide(
          filteredPermission[0].permissions,
          typeView
        );
        const setupload = filterOutPermissionToShowHide(
          filteredPermission[0].permissions,
          typeUpload
        );
        this.setState({
          changePermission: setchange,
          deletePermission: setdelete,
          viewPermission: setview,
          uploadPermission: setupload,
          permission: filteredPermission[0].permissions,
        });
      }
    } else if (
      this.props.location.state?.item.game.name == "PUBG Mobile" ||
      this.props.location?.data?.nameOfGame == "PUBG Mobile" ||
      data?.nameOfGame == "PUBG Mobile"
    ) {
      const type = "PUBG Mobile";
      const typeChange = "change_pubgmobile";
      const typeDelete = "delete_pubgmobile";
      const typeUpload = "can_upload_publish_pubg_mobile";
      const typeView = "view_pubgmobile";

      const filteredPermission = FilterPermission(this.props.permission, type);
      if (filteredPermission.length !== 0) {
        const setchange = filterOutPermissionToShowHide(
          filteredPermission[0].permissions,
          typeChange
        );
        const setdelete = filterOutPermissionToShowHide(
          filteredPermission[0].permissions,
          typeDelete
        );
        const setview = filterOutPermissionToShowHide(
          filteredPermission[0].permissions,
          typeView
        );
        const setupload = filterOutPermissionToShowHide(
          filteredPermission[0].permissions,
          typeUpload
        );
        this.setState({
          changePermission: setchange,
          deletePermission: setdelete,
          viewPermission: setview,
          uploadPermission: setupload,
          permission: filteredPermission[0].permissions,
        });
      }
    } else if (
      this.props.location.state?.item.game.name == "PUBG: Battlegrounds" ||
      this.props.location?.data?.nameOfGame == "PUBG: Battlegrounds" ||
      this.props.location.state?.item.game.name == "PUBG: BATTLEGROUNDS" ||
      this.props.location?.data?.nameOfGame == "PUBG: BATTLEGROUNDS" ||
      data?.nameOfGame == "PUBG: Battlegrounds" ||
      data?.nameOfGame == "PUBG: BATTLEGROUNDS"
    ) {
      const type = "PUBG PC";
      const typeChange = "change_pubgpc";
      const typeDelete = "delete_pubgpc";
      const typeUpload = "can_upload_publish_pubg_pc";
      const typeView = "view_pubgpc";

      const filteredPermission = FilterPermission(this.props.permission, type);
      if (filteredPermission.length !== 0) {
        const setchange = filterOutPermissionToShowHide(
          filteredPermission[0].permissions,
          typeChange
        );
        const setdelete = filterOutPermissionToShowHide(
          filteredPermission[0].permissions,
          typeDelete
        );
        const setview = filterOutPermissionToShowHide(
          filteredPermission[0].permissions,
          typeView
        );
        const setupload = filterOutPermissionToShowHide(
          filteredPermission[0].permissions,
          typeUpload
        );
        this.setState({
          changePermission: setchange,
          deletePermission: setdelete,
          viewPermission: setview,
          uploadPermission: setupload,
          permission: filteredPermission[0].permissions,
        });
      }
    } else if (
      this.props.location.state?.item.game.name == "Valorant" ||
      this.props.location?.data?.nameOfGame == "Valorant" ||
      data?.nameOfGame == "Valorant"
    ) {
      const type = "Valorant";
      const typeChange = "change_valorant";
      const typeDelete = "delete_valorant";
      const typeView = "view_valorant";
      const typeUpload = "can_upload_publish_valorant";

      const filteredPermission = FilterPermission(this.props.permission, type);
      if (filteredPermission.length !== 0) {
        const setchange = filterOutPermissionToShowHide(
          filteredPermission[0].permissions,
          typeChange
        );
        const setdelete = filterOutPermissionToShowHide(
          filteredPermission[0].permissions,
          typeDelete
        );
        const setview = filterOutPermissionToShowHide(
          filteredPermission[0].permissions,
          typeView
        );
        const setupload = filterOutPermissionToShowHide(
          filteredPermission[0].permissions,
          typeUpload
        );
        this.setState({
          changePermission: setchange,
          deletePermission: setdelete,
          viewPermission: setview,
          uploadPermission: setupload,
          permission: filteredPermission[0].permissions,
        });
      }
    } else if (
      this.props.location.state?.item.game.name == "Call Of Duty: Warzone" ||
      this.props.location?.data?.nameOfGame == "Call Of Duty: Warzone" ||
      data?.nameOfGame == "Call Of Duty: Warzone"
    ) {
      const type = "Cod Warzone";
      const typeChange = "change_codwarzone";
      const typeDelete = "delete_codwarzone";
      const typeUpload = "can_upload_publish_cod_warzone";
      const typeView = "view_codwarzone";

      const filteredPermission = FilterPermission(this.props.permission, type);
      if (filteredPermission.length !== 0) {
        const setchange = filterOutPermissionToShowHide(
          filteredPermission[0].permissions,
          typeChange
        );
        const setdelete = filterOutPermissionToShowHide(
          filteredPermission[0].permissions,
          typeDelete
        );
        const setview = filterOutPermissionToShowHide(
          filteredPermission[0].permissions,
          typeView
        );
        const setupload = filterOutPermissionToShowHide(
          filteredPermission[0].permissions,
          typeUpload
        );
        this.setState({
          changePermission: setchange,
          deletePermission: setdelete,
          viewPermission: setview,
          uploadPermission: setupload,
          permission: filteredPermission[0].permissions,
        });
      }
    }
  };

  async changeLobbyStatus() {
    const changeStatus = this.state.lobby.status === 1 ? 2 : 1;
    this.setState({ isLoading: true });
    await changeStatusLobby(this.state.lobby.id, changeStatus)
      .then(() => {
        this.closeAlert();
        this.getLobbyData(this.state.lobby.id);
      })
      .catch((err) => {
        console.error(err);
        this.closeAlert();
        toast.error(err, toastrOptions);
      });
  }

  async overrideLobbyAutomaticCancel() {
    this.closeAlert();
    // const changeStatus = this.state.lobby.status === 1 ? 2 : 1;
    // this.setState({ isLoading: true });
    // await changeStatusLobby(this.state.lobby.id, changeStatus)
    //   .then(() => {
    //     this.closeAlert();
    //     this.getLobbyData(this.state.lobby.id);
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //     this.closeAlert();
    //     toast.error(err, toastrOptions);
    //   });
  }

  async delLobby() {
    this.setState({ isLoading: true });
    await deleteLobby(this.state.lobby.id)
      .then((res) => {
        toast.success(res.message, toastrOptions);
        this.closeAlert();
        this.goToListing();
      })
      .catch((err) => {
        this.closeAlert();
        toast.error(err, toastrOptions);
      });
  }

  async overrideLobby() {
    this.setState({ isLoading: true });
    await overrideLobby(this.state.lobby.id)
      .then((res) => {
        toast.success(res.message, toastrOptions);
        this.closeAlert();
        this.goToListing();
      })
      .catch((err) => {
        this.closeAlert();
        toast.error(err, toastrOptions);
      });
  }

  async fetchStatsLobby() {
    this.setState({ isLoading: true });
    await fetchStats({ lobby_id: this.state.lobby.id })
      .then((res) => {
        let lobbyId = this.props.match.params.lobbyId;
        this.closeAlert();
        this.getLobbyData(lobbyId);
        toast.success(res.message, toastrOptions);
      })
      .catch((err) => {
        this.closeAlert();
        console.error(err);
      });
  }

  async cancelLobby() {
    this.setState({ isLoading: true });
    await cancelLobby({ lobby_id: this.state.lobby.id })
      .then((res) => {
        toast.success(res.message, toastrOptions);
        this.closeAlert();
        this.goToListing();
      })
      .catch((err) => {
        this.closeAlert();
        console.error(err);
        toast.error(err, toastrOptions);
      });
  }

  async getLobbyData(lobbyId) {
    try {
      const res = await getLobbyDetail(lobbyId);
      this.setState({
        lobby: res,
      });
      this.setState({
        fileUploadSucess: {
          fileBoolean: false,
          fileMessage: "",
        },
      });
    } catch (error) {
      toast.error(error, toastrOptions);
    }
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  closeAlert() {
    this.setState({
      confirm_alert: false,
      confirm_override_automatic_cancel_alert: false,
      delete_alert: false,
      lobby_override_alert: false,
      cancel_alert: false,
      results_alert: false,
      isLoading: false,
    });
  }

  goToListing() {
    this.props.history.push("/lobby");
  }
  isValidType = (data) => {
    const ext = this.state.lobby.game.stats_file_format.map(
      (statsFileData, i) => {
        return statsFileData;
      }
    );
    return ext.some((el) => data.endsWith(el));
  };
  async sendFileLength(imageLength) {
    this.setState({ processingLoader: true });
    const data = {
      count: imageLength,
      pubg_images: this.state.imageUrls,
    };
    await sendImageCount(data, this.state.lobby.id)
      .then((res) => {
        this.setState({
          fileUploadSucess: {
            fileBoolean: true,
            fileMessage: "Uploaded Successfully",
          },
        });
        setTimeout(() => {
          let lobbyId = this.props.match.params.lobbyId;
          if (lobbyId) {
            this.getLobbyData(lobbyId);
            this.setState({
              csvData: [],
              csvFileName: "",
              files: [],
              disableSubmit: false,
            });
          }
        }, 1000);
        this.setState({ processingLoader: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ processingLoader: false });
      });
  }

  // handle upload data
  handleSubmit = async () => {
    this.setState({
      disableSubmit: true,
      processingLoader: true,
    });
    if (this.state.lobby?.game?.is_multiple_upload) {
      for (let i = 0; i < this.state.files.length; ++i) {
        let fileName = this.state.files[i].name;
        let fileExtension = fileName.split(".").pop();
        let result = `${process.env.REACT_APP_S3_EXT_NAME}_${this.state.lobby.id
          }_${i + 1}.${fileExtension}`;
        const CREDENTIAL = {
          accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
          secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
        };

        // using key for code mobile and pubg mobile identifier env
        const bucketParams = {
          Bucket: process.env.REACT_APP_S3_OCR_BUCKET_NAME,
          Key: `${this.state.lobby.game.slug == "cod-mobile"
            ? process.env.REACT_APP_S3_OCR_BUCKET_PATH_COD_MOBILE
            : process.env.REACT_APP_S3_OCR_BUCKET_PATH
            }/${result}`,
          Body: this.state.files[i],
          // ContentType: "image/jpeg",
          ACL: "public-read",
        };
        const s3Client = new S3Client({
          region: process.env.REACT_APP_S3_REGION_NAME,
          credentials: CREDENTIAL,
        });
        s3Client.send(new PutObjectCommand(bucketParams));
        const bucketData = new GetObjectCommand({
          Bucket: bucketParams.Bucket,
          Key: `${this.state.lobby.game.slug == "cod-mobile"
            ? process.env
              .REACT_APP_S3_OCR_IMAGE_PROCESSED_FILE_PATH_COD_MOBILE
            : process.env.REACT_APP_S3_OCR_IMAGE_PROCESSED_FILE_PATH
            }/${result}`,
        });
        const url = await getSignedUrl(s3Client, bucketData);
        this.setState(
          {
            imageUrls: [...this.state.imageUrls, url],
          },
          () => {
            console.log("");
          }
        );

        if (this.state.imageUrls.length === this.state.files.length) {
          setTimeout(() => {
            this.sendFileLength(this.state.files.length);
          }, 5000);
        }
      }
    } else {
      for (let i = 0; i < this.state.files.length; ++i) {
        let result = `${process.env.REACT_APP_S3_EXT_NAME}_${this.state.lobby.id}`;
        const config = {
          bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
          dirName: process.env.REACT_APP_S3_BUCKET_PATH,
          region: process.env.REACT_APP_S3_REGION_NAME,
          accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
          secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
        };
        let newFileName = result;
        const ReactS3Client = new S3(config);
        ReactS3Client.uploadFile(this.state.files[i], newFileName)
          .then((data) => {
            if (data.status === 204) {
              this.setState(
                {
                  imageUrls: [...this.state.imageUrls, data.location],
                },
                () => {
                  console.log("");
                }
              );
              if (this.state.imageUrls.length === this.state.files.length) {
                setTimeout(() => {
                  this.sendFileLength(this.state.files.length);
                }, 5000);
              }
            } else {
              toast.error("Please try again later", toastrOptions);
              this.setState({ processingLoader: false });
            }
          })
          .catch((err) => {
            console.log(err, ">>>>>>");
          });
      }
    }
  };
  handleAcceptedFiles = (files) => {
    if (this.state.lobby?.game?.is_multiple_upload) {
      for (let i = 0; i < files.length; ++i) {
        let fname = files[i].name;
        if (!this.isValidType(fname)) {
          this.setState({
            invalidFile: {
              invalidFileBoolean: true,
              invalidFileMsg: `Please upload ${this.state.lobby.game.stats_file_format.map(
                (data) => {
                  return data;
                }
              )} file only`,
            },
          });
          return false;
        } else {
          files.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          );
          const newFiles = [...this.state.files, files[i]];
          this.setState({
            files: newFiles,
            invalidFile: {
              invalidFileBoolean: false,
              invalidFileMsg: "",
            },
          });
        }
      }
    } else {
      for (let i = 0; i < files.length; i++) {
        let fname = files[i].name;
        if (!this.isValidType(fname)) {
          this.setState({
            invalidFile: {
              invalidFileBoolean: true,
              invalidFileMsg: `Please upload ${this.state.lobby.game.stats_file_format.map(
                (data) => {
                  return data;
                }
              )} file only`,
            },
          });
          return false;
        } else {
          const file = files[i];
          const reader = new FileReader();
          reader.onload = (e) => {
            const text = e.target.result;
            const result = [];
            let splitText = text.split(/[\r\n,]/);
            let removeEmptyObjectFromSplitText = splitText.filter(
              (el) => Object.keys(el).length
            );
            for (
              //sonar chnge iAcpt
              let iAcpt = 0, a = removeEmptyObjectFromSplitText;
              iAcpt < a.length;
              iAcpt += 3
            ) {
              result.push({
                accountName: a[iAcpt],
                position: a[iAcpt + 1],
                kills: a[iAcpt + 2],
              });
            }
            this.setState(
              {
                csvData: result,
                csvFileName: files[i].name,
              },
              () => {
                console.log("");
              }
            );
          };
          reader.readAsText(file);
          //this.handleUpload(newArr[i], result, newArr.length);
          files.map((fileN) =>
            Object.assign(fileN, {
              preview: URL.createObjectURL(fileN),
            })
          );
          this.setState({ files: files });
          this.setState({
            invalidFile: {
              invalidFileBoolean: false,
              invalidFileMsg: "",
            },
          });
        }
      }
    }
  };
  remove = (file) => {
    const newFiles = [...this.state.files];
    newFiles.splice(file, 1);
    this.setState({ files: newFiles });
    this.setState({ csvData: [], csvFileName: "" });
  };
  showPreview = (index) => {
    this.setState({ openPreviewModal: true, selectedPreviewId: index });
  };
  handleClose = () => {
    this.setState({ openPreviewModal: false });
  };

  showManualResultsModal = (index) => {
    this.setState({ openManualResultsModal: true });
  };
  handleCloseManualResultsModal = () => {
    this.setState({ openManualResultsModal: false });
  };
  render() {
    return (
      <React.Fragment>
        <Loader isTransparent={true} showLoader={this.state.isLoading} />

        <div className="page-content">
          <Breadcrumbs breadcrumbItem="Lobby Detail" />
          <Row>
            {" "}
            <Row className="mb-4">
              <Col>
                <p>
                  <Link
                    to={
                      this.state?.tournamentData?.redirectName === "/tournament"
                        ? {
                          pathname: this.state.tournamentData.prevPath,
                          data: {
                            prevPath: this.state.tournamentData.prevPath,
                            nameOfGame: this.state.tournamentData.nameOfGame,
                            redirectName: "/tournament",
                            active: this.state.tournamentData.active,
                          },
                        }
                        : this.props.location.data === undefined
                          ? "/lobby"
                          : this.props.location.data?.prevPath === "/report"
                            ? {
                              pathname: this.props.location.data?.prevPath,
                              state: {
                                active: this.props.location.data.activeTab,
                                filter: this.props.location.data.filter,
                                activeFilter:
                                  this.props.location.data.activeFilter,
                                detailView: this.props.location.data.detailView,
                                setDates: this.props.location.data.setDates,
                              },
                            }
                            : "/lobby"
                    }
                  >
                    <i className="mdi mdi-arrow-left"></i> back
                  </Link>
                </p>
              </Col>
            </Row>
            <Col>
              <Col lg={12}>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: this.state.activeTab === "1",
                      })}
                      onClick={() => {
                        this.toggle("1");
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="fas fa-home"></i>
                      </span>
                      <span className="d-none d-sm-block">Lobby Details</span>
                    </NavLink>
                  </NavItem>
                  {this.state.uploadPermission ||
                    this.state.changePermission ? (
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: this.state.activeTab === "2",
                        })}
                        onClick={() => {
                          this.toggle("2");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="far fa-user"></i>
                        </span>
                        <span className="d-none d-sm-block">
                          Enrolled players
                        </span>
                      </NavLink>
                    </NavItem>
                  ) : null}
                  {this.state.lobby?.current_status === 3 ? (
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: this.state.activeTab === "3",
                        })}
                        onClick={() => {
                          this.toggle("3");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="far fa-user"></i>
                        </span>
                        <span className="d-none d-sm-block">View Results</span>
                      </NavLink>
                    </NavItem>
                  ) : (
                    ""
                  )}
                </Nav>
              </Col>{" "}
              <TabContent
                activeTab={this.state.activeTab}
                className="p-3 text-muted lobby-details-view"
              >
                {" "}
                <TabPane tabId="1">
                  <Card className="mb-0">
                    {this.state.lobby && (
                      <CardBody>
                        <Row>
                            <Row >
                                <Col className="col-12 col-md-5">
                                <Col className="col-12">
                            <h4 className="mb-4">{this.state.lobby?.name}</h4>
                          </Col>
                          <Col className="col-12 mb-3">
                            <strong className="capitalize">Game Type :</strong>{" "}
                            {GameTypeMap[this.state.lobby?.game_type]?.label}
                          </Col>
                          <Col className="col-12 mb-3">
                            <strong className="capitalize">Game :</strong>{" "}
                            {this.state.lobby?.game?.name}
                          </Col>
                          <Col className="col-12 mb-3">
                            <strong className="capitalize">Lobby Mode :</strong>{" "}
                            {this.state.lobby?.modetag}
                          </Col>

                          <Col className="col-12 mb-3">
                            <strong className="capitalize">Entry Fee :</strong>{" "}
                            $ {this.state.lobby?.entry_fee}{" "}
                            {this.state.lobby?.free_lobby === "Yes"
                              ? "Free Entry"
                              : ""}
                          </Col>
                          <Col className="col-12 mb-3">
                            <strong className="capitalize">Win Amount :</strong>
                            {this.state.lobby.game.slug === "valorant" ||
                              (this.state.lobby.game.slug == 'apex-legends' && this.state.lobby.game_type == "3") ||

                              this.state.lobby.game.slug ===
                              "call-of-duty-warzone"
                              ? ` $${this.state.lobby?.reward} Per Winning Team`
                              : ` $${this.state.lobby?.reward} Paid Every Kill`}
                          </Col>
                          <Col className="col-12 mb-3">
                            <strong className="capitalize">
                              Minimum Players :
                            </strong>{" "}
                            {this.state.lobby?.min_players || "NA"}
                          </Col>
                          <Col className="col-12 mb-3">
                            <strong className="capitalize">
                              Maximum Players :
                            </strong>{" "}
                            {this.state.lobby?.max_players || "NA"}
                          </Col>
                          <Col className="col-12 mb-3">
                            <strong className="capitalize">Start Date :</strong>{" "}
                            {dateFormat(this.state.lobby?.start_date)}
                          </Col>
                          <Col className="col-12 mb-3">
                            <strong className="capitalize">Start Time :</strong>{" "}
                            {this.state.lobby?.start_time || "NA"}
                          </Col>
                          <Col className="col-12 mb-3">
                            <strong className="capitalize">
                              Game End Time :
                            </strong>{" "}
                            {this.state.lobby?.end_time === null
                              ? "NA"
                              : this.state.lobby?.end_time}
                          </Col>
                                </Col>
                                
                            </Row>
                            <Col className="col-12 mb-3 lobby-game-rules">
                            <strong className="capitalize">Game Rules :</strong>{" "}
                            {(this.state.lobby?.rules && (
                              <p
                                className="mb-0"
                                dangerouslySetInnerHTML={{
                                  __html: sanitizeHtml(this.state.lobby?.rules),
                                }}
                              ></p>
                            )) ||
                              "NA"}
                          </Col>
                          
                          <Col className="col-12 mb-3">
                            <strong className="capitalize">Admin :</strong>{" "}
                            {this.state.lobby?.admin.email || "NA"}
                          </Col>
                          <Col className="col-12 mb-3">
                            <strong className="capitalize">Admin Code :</strong>{" "}
                            {this.state.lobby?.admin_code || "NA"}
                          </Col>

                          <Col className="col-12 mb-3">
                            <strong className="capitalize">
                              Player Code /Password /Participant Code :
                            </strong>{" "}
                            {this.state.lobby?.participant_code || "NA"}
                          </Col>
                          <Col className="col-12 mb-3">
                            <strong className="capitalize">
                              Statistic Code :
                            </strong>{" "}
                            {this.state.lobby?.stats_code === ""
                              ? "NA"
                              : this.state.lobby?.stats_code}
                          </Col>
                          <Col className="col-12 mb-3">
                            <strong className="capitalize">Map :</strong>{" "}
                            {this.state.lobby?.gamemap || "NA"}
                          </Col>
                          <Col className="col-12 mb-3">
                            <strong className="capitalize">Region :</strong>{" "}
                            {this.state.lobby?.region || "NA"}
                          </Col>
                          <Col className="col-12 mb-3">
                            <strong className="capitalize">
                              Lobby Password :
                            </strong>{" "}
                            {this.state.lobby?.lobby_password || "NA"}
                          </Col>

                          <Col className="col-12 mb-3">
                            <strong className="capitalize">Verified :</strong>{" "}
                            {this.state.lobby?.is_verified ? "Yes" : "No"}
                          </Col>
                          <Col className="col-12 mb-3">
                            <strong className="capitalize">Scoring :</strong>{" "}
                            {this.state.lobby?.is_manual
                              ? "Manual"
                              : "Automatic"}
                          </Col>
                          <Col className="col-12 mb-3">
                            <strong className="capitalize">
                              Lobby Status :
                            </strong>{" "}
                            {this.state.lobby?.status === 1
                              ? "Activated"
                              : "Deactivated"}
                          </Col>
                          <Col className="col-12 mb-3">
                            <strong className="capitalize">
                              Free Lobby status :
                            </strong>{" "}
                            {this.state.lobby?.free_lobby}
                          </Col>

                          <Col className="col-12 mb-3">
                            <strong className="capitalize">
                              Lobby Current Status :
                            </strong>{" "}
                            {this.state.lobby?.current_status === 1
                              ? "Upcoming"
                              : this.state.lobby?.current_status === 2
                                ? "Active"
                                : "Ended"}
                          </Col>
                          <Col className="col-12 mb-3  ">
                                <p><strong>Lobby Image: </strong></p>
                                {this.state?.lobby.background_image?(<img className="img-fluid" src={this.state?.lobby.background_image}/>):<p>No Image</p>}
                                </Col>
                                <Col className="col-12 mb-3">
                                <p><strong>Cover Image: </strong></p>
                                {this.state?.lobby.cover_image?(<img className="img-fluid"src={this.state?.lobby.cover_image}/>):<p>No Image</p>}
                                </Col>
                          {this.state.uploadPermission ||
                            this.state.changePermission ? (
                            this.state.processingLoader ? (
                              <>
                                <div className="col-12 mb-3 processingLoader lobbiesProcessingLoader">
                                  <div
                                    class="spinner-grow transaction-spinner"
                                    role="status"
                                  ></div>
                                  <span class="sr-only processingFile">
                                    Processing
                                  </span>
                                </div>
                              </>
                            ) : this.state.fileUploadSucess.fileBoolean ? (
                              <div>
                                <div className="col-12 mb-3 processingLoader lobbiesProcessingLoader">
                                  <img
                                    alt="tick-icon"
                                    src={checkCircle}
                                    className="tickIconLobbies"
                                    style={{ marginLeft: "70px" }}
                                  />
                                  <span
                                    class="sr-only processingFile"
                                    style={{ marginLeft: "-100px" }}
                                  >
                                    {this.state.fileUploadSucess.fileMessage}
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <>
                                <Col className="col-12 mb-3">
                                  {this.state.lobby.pubg_image_count >
                                    0 ? null : this.state.lobby?.game
                                      ?.has_upload ? (
                                    this.state.lobby.current_status !== 1 ? (
                                      <>
                                        <Dropzone
                                          onDrop={(acceptedFiles) => {
                                            this.handleAcceptedFiles(
                                              acceptedFiles
                                            );
                                          }}
                                          accept={this.state.lobby.game.stats_file_format.map(
                                            (data, i) => {
                                              return data;
                                            }
                                          )}
                                          disabled={
                                            this.state.lobby?.stats_status === 5
                                          }
                                        >
                                          {({
                                            getRootProps,
                                            getInputProps,
                                          }) => (
                                            <div className="dropzone dz-clickable">
                                              <div
                                                className="dz-message needsclick"
                                                {...getRootProps()}
                                              >
                                                <input {...getInputProps()} />
                                                <div className="mb-3">
                                                  <i className="display-4 text-muted mdi mdi-upload-network-outline"></i>
                                                </div>
                                                <h4>
                                                  {this.state.invalidFile
                                                    .invalidFileBoolean ? (
                                                    <p className="error-msg">
                                                      {
                                                        this.state.invalidFile
                                                          .invalidFileMsg
                                                      }
                                                    </p>
                                                  ) : this.state.lobby?.game
                                                    ?.is_multiple_upload ? (
                                                    `${this.state.lobby
                                                      ?.stats_status === 5
                                                      ? "Lobby is cancelled"
                                                      : "Upload image files."
                                                    }`
                                                  ) : (
                                                    `${this.state.lobby
                                                      ?.stats_status === 5
                                                      ? "Lobby is cancelled"
                                                      : "Upload CSV file."
                                                    }`
                                                  )}
                                                </h4>
                                              </div>
                                            </div>
                                          )}
                                        </Dropzone>
                                        <div
                                          className="dropzone-previews mt-3"
                                          id="file-previews"
                                        >
                                          {this.state.files.map((f, i) => {
                                            return (
                                              <div className="p-2 multipleImages">
                                                <Row className="align-items-center imagesUpload">
                                                  <Button
                                                    className="crossButton"
                                                    type="button"
                                                    onClick={() =>
                                                      this.remove(i)
                                                    }
                                                  >
                                                    X
                                                  </Button>
                                                  <Col className="col-auto uploadImage">
                                                    {this.state.lobby?.game
                                                      ?.is_multiple_upload ? (
                                                      <img
                                                        data-dz-thumbnail=""
                                                        height="80"
                                                        className="avatar-sm rounded bg-light"
                                                        alt={f.name}
                                                        src={f.preview}
                                                        onClick={() =>
                                                          this.showPreview(i)
                                                        }
                                                      />
                                                    ) : null}
                                                    <p>{f.name}</p>
                                                  </Col>
                                                  <Col></Col>
                                                </Row>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </>
                                    ) : null
                                  ) : null}
                                </Col>
                                <Col className="col-12 mt-3 ml-3">
                                  {this.state.csvData?.length === 0 ? (
                                    ""
                                  ) : (
                                    <table className="table csvUploadTable table-striped table-bordered responsiveTable">
                                      <thead>
                                        <tr>
                                          <td scope="col">Account name</td>
                                          <td scope="col">Kills</td>
                                          <td scope="col">Position</td>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.csvData.map((data, i) => {
                                          return (
                                            <tr key={1}>
                                              <td>
                                                {data.accountName || "N/A"}
                                              </td>
                                              <td>{data.kills || "0"}</td>
                                              <td>{data.position || "N/A"}</td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  )}
                                </Col>
                              </>
                            )
                          ) : null}

                          <Col className="col-12 mb-3">
                            <FormGroup>
                              <div>
                                {this.state.uploadPermission ||
                                  this.state.changePermission ? (
                                  this.state.lobby?.game?.has_upload &&
                                    this.state.lobby.current_status !== 1 &&
                                    this.state.lobby.pubg_image_count == 0 ? (
                                    <Button
                                      color="primary"
                                      className="ms-1"
                                      onClick={this.handleSubmit}
                                      disabled={
                                        this.state.disableSubmit ||
                                        this.state.lobby.pubg_image_count > 0 ||
                                        this.state.files.length === 0 ||
                                        this.state.lobby?.stats_status === 5
                                      }
                                      type="submit"
                                    >
                                      Submit
                                    </Button>
                                  ) : null
                                ) : null}
                                {this.state.tournamentData
                                  ?.hideButton ? null : (
                                  <>
                                    {this.state.changePermission ? (
                                      <>
                                        <Button
                                          color="primary"
                                          className="ms-1"
                                          onClick={() =>
                                            this.props.history.push(
                                              "/lobby/edit/" +
                                              this.state.lobby?.id
                                            )
                                          }
                                          disabled={
                                            this.state.lobby.game.slug ===
                                              "pubg" ||
                                              this.state.lobby.game.slug ===
                                              "valorant"
                                              ? this.state.lobby
                                                .current_status === 3
                                                ? true
                                                : false
                                              : this.state.lobby
                                                .current_status !== 1 &&
                                                !canFetchResults(
                                                  this.state.lobby
                                                )
                                                ? true
                                                : false
                                          }
                                        >
                                          Edit Lobby
                                        </Button>
                                        {(this.state.lobby.current_status ==
                                          1
                                        ) ? (<Button
                                          color="primary"
                                          className="ms-1"
                                          disabled={this.state.lobby
                                            .enrolled_players < this.state.lobby.min_players}
                                          onClick={() => {
                                            this.setState({
                                              lobby_override_alert: true,
                                            });
                                          }}
                                        >
                                          Override Lobby Start
                                        </Button>) : <></>
                                        }

                                        {this.state.lobby.current_status !==
                                          1 ? (
                                          ""
                                        ) : this.state.lobby
                                          .enrolled_players !== 0 ? (
                                          ""
                                        ) : (
                                          <Button
                                            type="button"
                                            color="primary"
                                            className="ms-1"
                                            onClick={() => {
                                              this.setState({
                                                confirm_alert: true,
                                              });
                                            }}
                                          >
                                            {this.state.lobby?.status === 1
                                              ? "Deactivate"
                                              : "Activate"}{" "}
                                            Lobby
                                          </Button>
                                        )}
                                      </>
                                    ) : null}

                                    {this.state.deletePermission ? (
                                      <Button
                                        type="button"
                                        color="danger"
                                        className="ms-1"
                                        onClick={() => {
                                          this.setState({
                                            delete_alert: true,
                                          });
                                        }}
                                      >
                                        Delete Lobby
                                      </Button>
                                    ) : null}
                                    {(this.state.lobby.status == 1 && this.state.lobby.current_status == 2) ? (
                                      <Button
                                        type="button"
                                        color="primary"
                                        className="ms-1"
                                        onClick={() => {
                                          this.setState({
                                            confirm_override_automatic_cancel_alert: true,
                                          });
                                        }}
                                      >

                                        Override Automatic Lobby Canceling
                                      </Button>
                                    ) : null}

                                    {(this.state.lobby.status == 1 && this.state.lobby.current_status == 2) ? (
                                      <Button
                                        type="button"
                                        color="primary"
                                        className="ms-1"
                                        onClick={() => {
                                          this.showManualResultsModal();
                                        }}
                                      >

                                        Add Manual Lobby Stats
                                      </Button>
                                    ) : null}
                                  </>
                                )}

                                {(this.state.lobby?.stats_status == 1 ||
                                  this.state.lobby?.stats_status == 2) &&
                                  this.state.changePermission &&
                                  this.state.lobby.is_manual &&
                                  this.state.lobby.current_status == 2 ? (
                                  <>
                                    <Button
                                      type="button"
                                      color="success"
                                      className="ms-1"
                                      onClick={() => {
                                        this.setState({
                                          results_alert: true,
                                        });
                                      }}
                                    >
                                      Fetch Results
                                    </Button>
                                    <Button
                                      type="button"
                                      color="warning"
                                      className="ms-1"
                                      onClick={() => {
                                        this.setState({
                                          cancel_alert: true,
                                        });
                                      }}
                                    >
                                      Cancel Lobby
                                    </Button>
                                  </>
                                ) : null}
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    )}

                    {this.state.confirm_alert ? (
                      <SweetAlert
                        title={`${this.state.lobby?.status === 1
                          ? "Deactivate"
                          : "Activate"
                          } lobby`}
                        warning
                        showCancel
                        confirmButtonText={`Yes, ${this.state.lobby?.status === 1
                          ? "Deactivate"
                          : "Activate"
                          }`}
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => this.changeLobbyStatus()}
                        onCancel={() => this.closeAlert()}
                      >
                        {`Are you sure you want to ${this.state.lobby?.status === 1
                          ? "Deactivate"
                          : "Activate"
                          } the lobby "${this.state.lobby?.name}"?`}

                        <p>
                          {this.state.lobby?.status === 1
                            ? "You cannot re-activate the lobby once it is deactivated"
                            : ""}
                        </p>
                      </SweetAlert>
                    ) : null}


                    {this.state.confirm_override_automatic_cancel_alert ? (
                      <SweetAlert
                        title="Ovverride Automatic Lobby Cancel"
                        warning
                        showCancel
                        confirmButtonText="Ovverride"
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => this.overrideLobbyAutomaticCancel()}
                        onCancel={() => this.closeAlert()}
                      >
                        {`Are you sure you want to ovverride automatic lobby cancellation "${this.state.lobby?.name}"?`}

                        <p>
                          You cannot revert this action once ovverriden and have to manually cancel the lobby
                        </p>
                      </SweetAlert>
                    ) : null}

                    {this.state.lobby_override_alert ? (
                      <SweetAlert
                        title="Override Lobby Start"
                        warning
                        showCancel
                        confirmButtonText="Yes"
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => this.overrideLobby()}
                        onCancel={() => this.closeAlert()}
                      >
                        {`Are you sure you want to override and the start "${this.state.lobby?.name}" in 5 Minutes?`}
                        <p>
                          It will disable any more user actions, send notifications to all the players
                          and set the lobby start time to 5 minutes from current time.
                          Only can override start lobby if minimum lobby requirements are met
                          and everyone has completed payment if the lobby is not free.
                        </p>
                      </SweetAlert>
                    ) : null}

                    {this.state.delete_alert ? (
                      <SweetAlert
                        title="Delete Lobby"
                        warning
                        showCancel
                        confirmButtonText="Yes"
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => this.delLobby()}
                        onCancel={() => this.closeAlert()}
                      >
                        {`Are you sure you want to delete the lobby "${this.state.lobby?.name}"?`}
                        <p>
                          This will delete the lobby permanently from the
                          website. The notification related to this lobby will
                          also be deleted at the front end. All the players
                          enrolled will get the refund as applicable.
                        </p>
                      </SweetAlert>
                    ) : null}
                    {this.state.results_alert ? (
                      <SweetAlert
                        title="Fetch Results"
                        warning
                        showCancel
                        confirmButtonText="Yes"
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => this.fetchStatsLobby()}
                        onCancel={() => this.closeAlert()}
                      >
                        {`Are you sure you want to fetch results for the lobby "${this.state.lobby?.name}"?`}
                        <p>This will fetch the results if they are arrived.</p>
                      </SweetAlert>
                    ) : null}
                    {this.state.cancel_alert ? (
                      <SweetAlert
                        title="Cancel Lobby"
                        warning
                        showCancel
                        confirmButtonText="Yes"
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => this.cancelLobby()}
                        onCancel={() => this.closeAlert()}
                      >
                        {`Are you sure you want to cancel the lobby "${this.state.lobby?.name}"?`}
                        <p>
                          This will cancel the lobby from the website. All the
                          players enrolled will get the refund as applicable.
                        </p>
                      </SweetAlert>
                    ) : null}
                  </Card>
                </TabPane>
                <TabPane tabId="2" className="p-3 enrolled-players-tab">
                  {this.state.lobby?.mode ? (
                    this.state.lobby?.mode === 1 ? (
                      <EnrolledPlayersList
                        playerList={this.state?.lobby?.lobby_players}
                        gameType={this.state?.lobby}
                        callLobbyDetail={() => {
                          this.getLobbyData(this.state.lobby.id);
                        }}
                        permission={this.props.permission}
                        hideRemoveButton={this.state.tournamentData?.hideButton}
                      />
                    ) : (
                      <EnrolledTeamList
                        playerList={this.state?.lobby?.lobby_players}
                        gameType={this.state?.lobby}
                        callLobbyDetail={() => {
                          this.getLobbyData(this.state.lobby.id);
                        }}
                        permission={this.props.permission}
                        hideRemoveButton={this.state.tournamentData?.hideButton}
                      />
                    )
                  ) : null}
                </TabPane>
                <TabPane tabId="3" className="p-3 lobby-results-tab">
                  {this.state.lobby === null ? (
                    ""
                  ) : (
                    <LobbyResult
                      data={this.state.lobby.player_stats}
                      gameData={this.state.lobby.game}
                      isTournament={this.state.tournamentData !== null}
                      isPPK={
                        this.state.tournamentData === null
                          ? false
                          : this.state.tournamentData.isPPK
                      }
                    />
                  )}
                </TabPane>
              </TabContent>
              {this.state.openPreviewModal ? (
                <Modal
                  isOpen={this.state.openPreviewModal}
                  toggle={() => {
                    this.handleClose();
                  }}
                  onHide={() => this.handleClose()}
                >
                  <ModalHeader>
                    <h5>Preview</h5>
                    <button
                      className="crossButton previewCrossIcon"
                      onClick={() => {
                        this.handleClose();
                      }}
                    >
                      X
                    </button>
                  </ModalHeader>
                  <ModalBody className="py-3 px-5">
                    <div className="previewImage">
                      {this.state.files.map((f, i) => {
                        return this.state.selectedPreviewId === i ? (
                          <img alt={f.name} src={f.preview} />
                        ) : null;
                      })}
                    </div>
                  </ModalBody>
                </Modal>
              ) : null}

              {this.state.openManualResultsModal ? (
                <ManualResultsModal openManualResultsModal={this.state.openManualResultsModal} handleClose={this.handleCloseManualResultsModal} lobby={this.state?.lobby} />
              ) : null}
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(LobbyDetail);
