import React, { useEffect, useState } from "react";

import { Row, Col, Card, CardBody } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getTeamList } from "../../services/teams_api_helper";
import { Link } from "react-router-dom";
import FilterPermission from "../../helpers/FilterPermission";
import { filterOutPermissionToShowHide } from "../../helpers/PermissionUtils";
import { isEmpty } from "lodash";
import {
    permissionsStrings,
    SUCCESS_MESSAGES,
} from "../../helpers/StringConstant";

const TeamsList = (props) => {
    const [teams, setTeams] = useState([]);
    const [loader, setLoader] = useState(false);
    const [changePermission, setChangePermission] = useState(false);
    useEffect(() => {
        setLoader(true);
        getTeamList().then((res) => {
            setTeams(res?.results);
            setLoader(false);
        });
        if (isEmpty(props.permission)) {
            setChangePermission(true);
        } else {
            callSetPermission();
        }
    }, []);
    const callSetPermission = () => {
        const type = permissionsStrings.gameDemo;
        const typeChange = permissionsStrings.typeDemoChange;
        const filteredPermission = FilterPermission(props.permission, type);
        if (filteredPermission.length !== 0) {
            const setchange = filterOutPermissionToShowHide(
                filteredPermission[0].permissions,
                typeChange
            );

            setChangePermission(setchange);
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs breadcrumbItem="Teams" />
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <div className="table-rep-plugin">
                                    <div
                                        className="table-responsive mb-0"
                                        data-pattern="priority-columns"
                                    >
                                        <Table
                                            id="tech-companies-1"
                                            className="table table-striped table-bordered games-table"
                                        >
                                            <Thead>
                                                <Tr>
                                                    <Th>Team Name</Th>
                                                    <Th data-priority="3">Game Name</Th>
                                                    <Th data-priority="1">Creator Username</Th>
                                                    {/* {changePermission ? (
                                                        <Th data-priority="1">Action</Th>
                                                    ) : null} */}
                                                </Tr>
                                            </Thead>
                                            {teams && (
                                                <Tbody>
                                                    {loader ? (
                                                        <div
                                                            class="spinner-grow spinner-class"
                                                            role="status"
                                                            style={{ marginTop: "40px" }}
                                                        >
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                    ) : (
                                                        teams.map((item, index) => (
                                                            <Tr key={index}>
                                                                <Td>
                                                                    {item.name}
                                                                </Td>
                                                                <Td>{item.game?.name}</Td>
                                                                <Td>
                                                                    {item.creator?.username}
                                                                </Td>
                                                                <Td>
                                                                    <Link
                                                                        className="btn btn-sm complaint-view-btn btn-secondary challenge-actions"
                                                                        role="button"
                                                                        to={`/team/${item.id}`}
                                                                    >
                                                                        View
                                                                    </Link>
                                                                </Td>
                                                            </Tr>
                                                        ))
                                                    )}
                                                </Tbody>
                                            )}
                                        </Table>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
};

export default TeamsList;
