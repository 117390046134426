const PaymentConstantTypes = [
    {
        label: "ADD TO WALLET",
        value: 1,
    },
    { label: "REDEEM BALANCE", value: 2 },
    { label: "PAY ENTRY FEE", value: 3 },
    { label: "RECEIVE WINNINGS", value: 4 },
    { label: "WINNINGS SEIZED", value: 5 },
    { label: "WALLET BALANCE SEIZED", value: 6 },
    { label: "REFUND", value: 7 },
    { label: "ON HOLD", value: 8 },
    { label: "RECEIVE REFERRAL", value: 9 },
    { label: "RECEIVE BONUS", value: 10 },
    { label: "VOLUNTEER", value: 11 },
    { label: "INACTIVITY CHARGES", value: 12 },
    { label: "RECEIVE_MANUAL_PROMO ", value: 13 }

];
export default PaymentConstantTypes;
