import React, { useEffect, useState, useCallback } from "react";

import { ListGroup } from "react-bootstrap";
import { toast } from "react-toastify";

import toastrOptions from "../../helpers/toastr-options/toastr-options";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { getUsers } from "../../services/user_api_helper";



import './lobby.scss'
import {
  getLobbyDetail,
} from "../../services/lobby_api_helper";

import {
  formNewTeam,
  getTeams,
  addTeamToLobby
} from "../../services/versus_team_api_helper";




import {
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  InputGroup,
  Row,
  // ListGroup
} from "reactstrap";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";

import Loader from "../../components/Common/Loader";
import { debounce } from "lodash";
import { AvForm, AvGroup, AvInput, AvField } from "availity-reactstrap-validation";




function AddTeam(props) {
  const [showAllTeamNames, setShowAllTeamNames] = useState(false);
  const [spinnerLoader, setSpinnerLoader] = useState(false);
  const [disableSubmit, setSubmitDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);



  const [teamNames, setTeamNames] = useState([]);

  const [teamName, setTeamName] = useState('');
  // const [teamId, setTeamId] = useState(null);


  const debouncedHandleSearch = useCallback(debounce(handleSearch, 400), []);


  function handleSearch(value) {
    if (value.length !== 0) {
      setShowAllTeamNames(true);
      setSpinnerLoader(true);
      getTeams(value, props.lobby["game"]["id"], props.lobby["mode"])
        .then((res) => {
          setSpinnerLoader(false);
          setTeamNames(res);
        })
        .catch(() => {
          setSpinnerLoader(false);
        });
    } else {
      setTeamNames([]);
      setShowAllTeamNames(false);
    }
  }

  const handleValidAddTeamSubmit = (event, values) => {
    event.preventDefault();
    setSubmitDisabled(true);


  


    const model = {
      lobby_id: props.lobby.id,
      team_id: teamName.id 
    }

    setErrorMessage(null);


    addTeamToLobby(model).then(() => {

      setSubmitDisabled(false);
      setSpinnerLoader(false);

      toast.success("Team Added Successfully", toastrOptions);



    }, (err) => {
      setSpinnerLoader(false);
      setSubmitDisabled(false);
      setErrorMessage(err)

    })

  }
  return (

    <AvForm
      onValidSubmit={(e, v) => {
        handleValidAddTeamSubmit(e, v);
      }}
    >

      <div className="row">
        <div className="col-5">
          <AvGroup>
            <Label>{'Team Name'}</Label>
            <AvField
              required
              type="search"
              name="teamName"
              autoComplete="off"
              clear
              validate={{
                required: {
                  value: true,
                  errorMessage: "Team Name is required",
                },
              }}
              value={`${teamName && teamName.name && teamName.player__username ? teamName.name + ' - ' + teamName.player__username : ''}`}
              placeholder="Search by team name"
              onChange={(e) => {
                setTeamName({
                  name: e.target.value,
                });

                debouncedHandleSearch(e.target.value);
              }}
            />


          </AvGroup>
        </div>

        <div className="col-5 d-flex">
          <FormGroup className="d-flex align-items-end">
            <Button
              type="submit"
              color="primary"
              className="ms-1"
              disabled={disableSubmit}
            >
              Add Team
            </Button>
          </FormGroup>
        </div>
      </div>

      <div className="col-5 mb-3">
        {spinnerLoader ? (
          <div className="search-loader">
            <span className="spinner-border spinner-border-md mt-3"></span>
          </div>
        ) : showAllTeamNames ? (
          <ListGroup className="addTeamMatesListing">
            {teamNames.length === 0 ? (
              <ListGroup.Item className="createList">
                No data found
              </ListGroup.Item>
            ) : (
              teamNames &&
              teamNames.map((data, idx) => {
                return (
                  <ListGroup.Item
                    className="d-flex justify-content-between searchTeamList"
                    key={data.id}
                    onClick={() => {
                      setTeamName(data)

                      setShowAllTeamNames(false);
                    }}
                  >
                    <p>{`${data.name} - ${data.player__username}`}</p>
                  </ListGroup.Item>
                );
              })
            )}
          </ListGroup>
        ) : null}
      </div>


      {errorMessage ? (
        <p className="error-msg">{errorMessage}</p>
      ) : null}




    </AvForm>
  );


}


const FormAndAddTeamToLobby = (props) => {

  const lobbyId = props.match.params.lobbyId;

  const goToLobby = () => {
    props.history.push(`/lobby/${lobbyId}`);
  };




  const [showLoader, setShowLoader] = useState(false);



  const [addNewTeamForm, setAddNewTeamForm] = useState({
    teamName: "",
    teamMates: [],
  });


  const handleChange = (event) => {
    const value = event.target.value;
    setAddNewTeamForm({
      ...addNewTeamForm,
      [event.target.name]: value,
    });
  };



  const [lobby, setLobby] = useState(null);



  const getLobbyData = async () => {
    setShowLoader(true);
    const res = await getLobbyDetail(lobbyId);
    setLobby(res);
    setAddNewTeamForm({
      ...addNewTeamForm,
      teamMates: Array(res.mode).fill({
        username: '',
        id: null
      })
    });

    setShowLoader(false)


  };


  const [disableSubmit, setSubmitDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);




  const handleSelect = (value, index) => {
    const temp = addNewTeamForm.teamMates;
    temp[index] = value;
    setAddNewTeamForm({
      ...addNewTeamForm,
      teamMates: temp,

    })
  };


  useEffect(() => {
    getLobbyData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);







  const handleValidSubmit = (event, values) => {
    event.preventDefault();
    setSubmitDisabled(true);


    for (let i = 0; i < addNewTeamForm.teamMates.length; i++) {
      if (addNewTeamForm.teamMates[i] === null || addNewTeamForm.teamMates[i].id === null) {
        const temp = addNewTeamForm.teamMates;
        temp[i] = {
          username: '',
          id: null
        };
        setAddNewTeamForm({
          ...addNewTeamForm,
          teamMates: temp,

        });
        setErrorMessage("Please select valid team members");
        return;
      }
    }


    const model = {
      "name": addNewTeamForm.teamName,
      "player_ids": addNewTeamForm.teamMates.slice(1).map((item) => item.id), // slice(1) to remove the owner from the list of players
      "owner_id": addNewTeamForm.teamMates[0].id,
      "mode": lobby['mode'],
      "game_id": lobby['game']['id'],
    }

    setErrorMessage(null);


    formNewTeam(model).then(() => {

      setSubmitDisabled(false);
      setShowLoader(false);

      toast.success("Team Created Successfully", toastrOptions);



    }, (err) => {
      setShowLoader(false);
      setSubmitDisabled(false);
      setErrorMessage(err)

    })

  }




  // ======================== START OF username search ========================
  const [showAllUserNames, setShowAllUserNames] = useState(-1);
  const [spinnerLoader, setSpinnerLoader] = useState(-1);
  const [userNames, setUserNames] = useState([]);

  const [currentSelected, setCurrentSelected] = useState('')


  const debouncedHandleSearch = useCallback(debounce(handleSearch, 400), []);

  function handleSearch(value, index) {
    if (value.length !== 0) {
      setShowAllUserNames(index);
      setSpinnerLoader(index);
      getUsers(value)
        .then((res) => {
          setSpinnerLoader(-1);
          setUserNames(res);
        })
        .catch(() => {
          setSpinnerLoader(-1);
        });
    } else {
      setUserNames([]);
      setShowAllUserNames(-1);
    }
  }

  // ======================== END OF username search ========================

  return (
    <React.Fragment>
      <Loader showLoader={showLoader} />
      {lobby && (
        <div className="page-content">
          <Breadcrumbs
            breadcrumbItem={"Create and Add Team To Lobby"}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="mb-0">
                    <Col>
                      <p>
                        <Link to={`/lobby/${lobbyId}`} onClick={goToLobby}>
                          <i className="mdi mdi-arrow-left"></i> back
                        </Link>
                      </p>
                    </Col>
                  </Row>

                  <h3>Add Team to Lobby</h3>

                  <AddTeam lobby={lobby} />


                  <h5 className="mt-8 pt-4">Quick Add a New Team</h5>

                  <AvForm
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <div className="col-5 mb-3">
                      <AvGroup>

                        <AvField
                          name="teamName"
                          label="Team Name*"
                          placeholder="Enter Team Name here"
                          type="text"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Team Name is required",
                            },
                            maxLength: {
                              value: 50,
                              errorMessage: "Name can have 50 characters max",
                            },
                          }}
                          value={addNewTeamForm.teamName}
                          onChange={handleChange}

                        />
                      </AvGroup>

                    </div>

                    {[...Array(lobby.mode)].map((e, i) => <div className="col-5 mb-3">
                      <AvGroup key={i}>
                        <Label>{i === 0 ? 'Team Owner' : `Team Player ${i}`}</Label>
                        <AvField
                          required
                          type="search"
                          name={`teamPlayer${i}`}
                          autoComplete="off"
                          clear
                          validate={{
                            required: {
                              value: true,
                              errorMessage: `${i === 0 ? "Team Owner" : "Team Player " + i} is required`,
                            },
                          }}
                          value={addNewTeamForm.teamMates[i]?.username || ''}
                          placeholder="Search by Username"
                          onChange={(e) => {
                            if (e.target.value === '') handleSelect(null, i); setCurrentSelected(e.target.value); debouncedHandleSearch(e.target.value, i);
                          }}
                        />
                        {spinnerLoader === i ? (
                          <div className="search-loader">
                            <span className="spinner-border spinner-border-md mt-3"></span>
                          </div>
                        ) : showAllUserNames === i ? (
                          <ListGroup className="addTeamMatesListing">
                            {userNames.length === 0 ? (
                              <ListGroup.Item className="createList">
                                No data found
                              </ListGroup.Item>
                            ) : (
                              userNames &&
                              userNames.map((data, idx) => {
                                return (
                                  <ListGroup.Item
                                    className="d-flex justify-content-between searchTeamList"
                                    key={data.username}
                                    onClick={() => {
                                      setCurrentSelected(data.username)
                                      setShowAllUserNames(-1);
                                      handleSelect(data, i)
                                    }}
                                  >
                                    <p>{data.username}</p>
                                  </ListGroup.Item>
                                );
                              })
                            )}
                          </ListGroup>
                        ) : null}
                        {/* <SelectUsername handleSelect={handleSelect} index={i} /> */}
                      </AvGroup>
                    </div>
                    )}


                    {errorMessage ? (
                      <p className="error-msg">{errorMessage}</p>
                    ) : null}

                    <FormGroup className="mt-4">
                      <div>
                        <Button
                          type="submit"
                          color="primary"
                          className="ms-1"
                          disabled={disableSubmit}
                        >
                          Create Team
                        </Button>
                      </div>
                    </FormGroup>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>

          </Row>


        </div>)}

    </React.Fragment>
  );
}


const mapStateToProps = (state) => {
  return {

  };
};


export default withRouter(connect(mapStateToProps)(FormAndAddTeamToLobby));