import { axiosApi } from "./index";

export async function getTeamList(searchTerm = null) {
    let params = new URLSearchParams();
    if (searchTerm) params.append("name", searchTerm);

    return await axiosApi.get("admin/teams/", { params });
}

export async function getTeamDetail(teamId) {
    return await axiosApi.get(`admin/teams-detail/${teamId}`);
}


