import React from "react";

import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Button,
    Label,
    InputGroup,
    CustomInput
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

// import Dropdown Select
import Select from "react-select";

// import Editor
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// import switch
import Switch from "react-switch";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
    addLobby,
    getLobbyDetail,
    editLobby,
} from "../../services/lobby_api_helper";
import { Link, withRouter } from "react-router-dom";
import Loader from "../../components/Common/Loader";

import { toast } from "react-toastify";
import toastrOptions from "../../helpers/toastr-options/toastr-options";
import { getAdminUserListLobby } from "../../services/admin_user_api_helper";
import {
    convertTime12To24,
    parseIntKeysForCompare,
    removeNotValidKeysForComparison,
    trimObjectKeysForCompare,
} from "./data";
import { cloneDeep, isEmpty, isEqual } from "lodash";
//Editor
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import EditorToolbar, { formats } from "../CMS/EditorToolbar";
import {
    canEditLobbyField,
    gameTypeFilters,
    getModeLabel,
    getModeLabelsSelect,
    passwordCharacterData,
    randomPasswordGenerator,
} from "../../helpers/util";
import { getGlobalSettings } from "../../services/globalSettings_api_helper";
import { IMG } from "../../components/Common/StatsCodeToolTip";
import { connect } from "react-redux";
import { resetServerContext } from "react-beautiful-dnd";
import { getPresignedUrl, uploadToS3 } from '../../services/file_upload_api_helper';
import ImageCropper from "../../components/ImageClipper/ImageCropper";
const Offsymbol = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
                color: "#fff",
                paddingRight: 2,
            }}
        >
            {" "}
            No
        </div>
    );
};

const OnSymbol = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
                color: "#fff",
                paddingRight: 2,
            }}
        >
            {" "}
            Yes
        </div>
    );
};

class LobbyForm extends React.Component {
    constructor(props) {
        super(props);
        let d = new Date();
        let estTime = d.toLocaleTimeString("en-US", {
            timeZone: "America/New_York",
        });
        this.intialRef = React.createRef();
        this.startTimeRef = React.createRef();
        this.regex = React.createRef();
        this.regex.current = /(<([^>]+)>)/gi;
        this.state = {
            isLoading: false,
            editMode: false,
            lobbyId: 0,
            optionGroup: null,
            errorMsg: null,
            previousSelectedDate: null,
            admins: [],
            gameNew: "",
            game: null,
            name: null,
            minPlayer: 0,
            maxPlayer: 0,
            min_Player: null,
            max_Player: null,
            startDate: null,
            startTime: estTime,
            startTimeCheck: null,
            endTime: null,
            entryFee: "",
            rules: "",
            prevGameId: "",
            displayRules: "",
            verified: false,
            status: true,
            is_manual: false,
            freeStatus: false,
            current_status: null,
            stats_code: "",
            participant_code: null,
            admin_code: null,
            admin_id: null,
            isPlayerEnrolled: false,
            editSubmitDisable: true,
            startDateError: {
                startDateBoolean: false,
                startDateMsg: "",
            },
            disableSubmitButton: false,
            gamemap: "",
            region: "",
            ruleFieldError: "",
            raw_html: "",
            show_raw: false,
            lobby_password: "",
            isPasswordProtected: false,
            addMinutesLastEntryTimeSolo: 10,
            addMinutesLastEntryTimeTeam: 0,
            endTimeRequiredMsg: "",
            styleButton: false,
            gameTypeMode: { value: 1, label: "PPK" },
            modeSelected: null,
            spinnerLoader: false,
            coverImagePreview: '',
            coverImageText: '',
            coverImageError: '',
            disabled: false,
            coverImageFieldError: '',
            spinnerLoaderBg: false,
            bgImagePreview: '',
            bgImageText: '',
            bgImageError: '',
            disabledBg: false,
            bgImageFieldError: '',
            coverImageToCrop: undefined,
            bgImageToCrop: undefined,
            croppedCoverImage: undefined,
            croppedBgImage: undefined,



        };
        this.handleChange = this.handleChange.bind(this);
        this.handleInvalidSubmit = this.handleInvalidSubmit.bind(this);
    }

    componentDidMount() {
        let lobbyId = this.props.match.params.lobbyId;
        this.adminList();
        this.getGlobalListing();
        if (lobbyId) {
            this.setState({
                editMode: true,
                lobbyId: lobbyId,
            });
            this.lobbyDetail(lobbyId);
        } else {
            if (!this.state.editMode && this.props?.game?.ppkGames?.length) {
                this.handleSelectGroup(this.props?.game?.ppkGames?.[0]);
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.modeSelected?.mode !== this.state.modeSelected?.mode) {
            this.subtractMinutesModeChange();
        }
        if (
            this.props.game?.allGames?.length !== prevProps?.game?.allGames?.length
        ) {
            if (!this.state.editMode) {
                this.handleSelectGroup(this.props?.game?.ppkGames?.[0]);
            }
        }
    }

    async getGlobalListing() {
        try {
            this.showLoader(true);
            const { button_grey_out_time } = await getGlobalSettings();
            this.setState({
                addMinutesLastEntryTimeTeam: button_grey_out_time,
            });
            this.showLoader(false);
        } catch (error) {
            this.showLoader(false);
        }
    }

    async lobbyDetail(lobbyId) {
        try {
            this.showLoader(true);
            const res = await getLobbyDetail(lobbyId);
            this.mapDetailsToForm(res);
            this.showLoader(false);
        } catch (error) {
            this.showLoader(false);
        }
    }

    mapDetailsToForm(res) {
        this.setState(
            {
                previousSelectedDate: new Date(res.start_date),
                game: {
                    ...res.game,
                    value: res.game.id,
                    label: res.game?.name,
                    game_rules: res?.rules,
                    min_players: (res.game.slug == "apex-legends" && res.game_type == 3) ? res?.mode * 2 : res.min_players,
                    max_players: (res.game.slug == "apex-legends" && res.game_type == 3) ? res?.mode * 2 : res.max_players,
                },
                gameTypeMode: gameTypeFilters[res.game_type - 1],
                modeSelected: {
                    mode: res.mode,
                    value: res.mode,
                    label: getModeLabel(res.game_type, res.mode),
                },
                prevGameId: res.game.id,
                name: res.name,
                minPlayer: (res.game.slug == "apex-legends" && res.game_type == 3) ? res?.mode * 2 : res.min_players,
                maxPlayer: (res.game.slug == "apex-legends" && res.game_type == 3) ? res?.mode * 2 : res.max_players,
                startDate: res.start_date,
                startTime: res.start_time,
                startTimeCheck: res.start_time,
                endTime: res.last_entry_time,
                entryFee: res.entry_fee,
                rules: res.rules,
                displayRules: res.rules.replace(this.regex.current, ""),
                verified: res.is_verified,
                status: res.status === 1 ? true : false,
                current_status: res.current_status,
                admin_code: res.admin_code,
                stats_code: res.stats_code,
                participant_code: res.participant_code,
                gamemap: res.gamemap,
                region: res.region,
                lobby_password: res.lobby_password,
                coverImagePreview: res.cover_image,
                bgImagePreview: res.background_image,
                bgImageToCrop: res.background_image,
                coverImageToCrop: res.cover_image,
                freeStatus: res.free_lobby === "Yes" ? true : false,
                admin_id: {
                    label: res.admin.email,
                    value: res.admin.id,
                },
                isPlayerEnrolled: res.enrolled_players === 0 ? false : true,
                isPasswordProtected: res?.is_password_protected,
                is_manual: res.is_manual,
                startTimeObject: null,
            },
            () => {
                let filterMinMaxValues = this.state?.optionGroup?.filter(function (
                    item
                ) {
                    return item.value === res.game.id;
                });

                this.intialRef.current = cloneDeep(this.state);
                this.intialRef.current = {
                    ...this.intialRef.current,
                    min_Player:
                        res.game.slug == "call-of-duty-warzone"
                            ? filterMinMaxValues?.[0]?.min_players * res?.mode : (res.game.slug == "apex-legends" && res.game_type == 3) ? res?.mode * 2
                                : filterMinMaxValues?.[0]?.min_players,
                    max_Player:
                        res.game.slug == "call-of-duty-warzone"
                            ? filterMinMaxValues?.[0]?.max_players * res?.mode : (res.game.slug == "apex-legends" && res.game_type == 3) ? res?.mode * 2
                                : filterMinMaxValues?.[0]?.max_players,
                    isLoading: false,
                };
                this.setState({
                    min_Player:
                        res.game.slug == "call-of-duty-warzone"
                            ? filterMinMaxValues?.[0]?.min_players * res?.mode : (res.game.slug == "apex-legends" && res.game_type == 3) ? res?.mode * 2
                                : filterMinMaxValues?.[0]?.min_players,
                    max_Player:
                        res.game.slug == "call-of-duty-warzone"
                            ? filterMinMaxValues?.[0]?.max_players * res?.mode : (res.game.slug == "apex-legends" && res.game_type == 3) ? res?.mode * 2
                                : filterMinMaxValues?.[0]?.max_players,
                });
                this.showLoader(false);
            }
        );
    }

    adminList() {
        getAdminUserListLobby()
            .then((res) => {
                if (!isEmpty(res) && res?.length > 0) {
                    let data = res.map((item) => ({
                        label: item.email,
                        value: item.id,
                    }));
                    this.setState({
                        admins: data,
                    });
                    if (!this.state.editMode)
                        this.setState({
                            admin_id: data[0],
                        });
                }
            })
            .catch((err) => { });
    }

    handleSelectGroup(selected, gameTypeValue = -1) {
        console.log("===================selected===============");
        console.log(selected);
        this.setState({
            rules: selected.game_rules,
            displayRules: selected.game_rules.replace(this.regex.current, ""),
            modeSelected: getModeLabelsSelect(
                typeof gameTypeValue != "object" && gameTypeValue != -1
                    ? { value: gameTypeValue }
                    : this.state.gameTypeMode,
                this.props?.game?.modeGameTypeObj?.[selected?.slug]
            )?.[0],
        });
        if (this.state.editMode) {
            this.setState({
                game: selected,
                min_Player: selected.slug == 'apex-legends' && selected.slug == 'apex-legends' && (gameTypeValue == -1 ? this.state.gameTypeMode.value : gameTypeValue) == 3 ? 6 : selected.min_players,
                max_Player: selected.slug == 'apex-legends' && selected.slug == 'apex-legends' && (gameTypeValue == -1 ? this.state.gameTypeMode.value : gameTypeValue) == 3 ? 6 : selected.max_players,
                gameNew: selected.value,
                maxPlayer: selected.slug == 'apex-legends' && selected.slug == 'apex-legends' && (gameTypeValue == -1 ? this.state.gameTypeMode.value : gameTypeValue) == 3 ? 6 : selected.max_players,
                minPlayer: selected.slug == 'apex-legends' && selected.slug == 'apex-legends' && (gameTypeValue == -1 ? this.state.gameTypeMode.value : gameTypeValue) == 3 ? 6 : selected.min_players,
            });
        } else {
            this.setState({
                game: selected,
                min_Player: selected.slug == 'apex-legends' && selected.slug == 'apex-legends' && (gameTypeValue == -1 ? this.state.gameTypeMode.value : gameTypeValue) == 3 ? 6 : selected.min_players,
                max_Player: selected.slug == 'apex-legends' && selected.slug == 'apex-legends' && (gameTypeValue == -1 ? this.state.gameTypeMode.value : gameTypeValue) == 3 ? 6 : selected.max_players,
                gameNew: selected.value,
            });
        }
    }

    handleAdminSelectGroup(selected) {
        this.setState({ admin_id: selected });
    }

    async handleValidSubmit(event, values) {
        event.preventDefault();
        await this.setImagesState();
        let amount_per_kill = this.state.entryFee * 0.75;
        let fee = parseFloat(amount_per_kill);
        const passwordPayload = this.state.isPasswordProtected
            ? { lobby_password: this.state.lobby_password }
            : {};
        if (this.state.rules?.length === 11) {
            this.setState({
                ruleFieldError: "Please add some rules",
                endTimeRequiredMsg:
                    this.state.endTime === null ? "Start time is required" : "",
            });
        } else if (this.state.endTime === null) {
            this.setState({
                endTimeRequiredMsg: "Start time is required",
            });
        } else if (this.state.startTime === this.state.startTimeCheck) {
            let newConvertedStartTime =
                this.state.startTime.split(" ")[0] +
                " " +
                this.state.startTime.split(" ")[1];
            let hours = Number(newConvertedStartTime.match(/^(\d+)/)[1]);
            let minutes = Number(newConvertedStartTime.match(/:(\d+)/)[1]);
            let AMPM = newConvertedStartTime.match(/\s(.*)$/)[1];
            if (AMPM === "PM" && hours < 12) hours = hours + 12;
            if (AMPM === "AM" && hours === 12) hours = hours - 12;
            let sHours = hours.toString();
            let sMinutes = minutes.toString();
            if (hours < 10) sHours = "0" + sHours;
            if (minutes < 10) sMinutes = "0" + sMinutes;
            let HrStartTime = sHours + ":" + sMinutes;
            let newConvertedEndTime =
                this.state.endTime.split(" ")[0] +
                " " +
                this.state.endTime.split(" ")[1];
            let hoursEnd = Number(newConvertedEndTime.match(/^(\d+)/)[1]);
            let minutesEnd = Number(newConvertedEndTime.match(/:(\d+)/)[1]);
            let AMPMEnd = newConvertedEndTime.match(/\s(.*)$/)[1];
            if (AMPMEnd === "PM" && hoursEnd < 12) hoursEnd = hoursEnd + 12;
            if (AMPMEnd === "AM" && hoursEnd === 12) hoursEnd = hoursEnd - 12;
            let sHoursEnd = hoursEnd.toString();
            let sMinutesEnd = minutesEnd.toString();
            if (hoursEnd < 10) sHoursEnd = "0" + sHoursEnd;
            if (minutesEnd < 10) sMinutesEnd = "0" + sMinutesEnd;
            let HrEndTime = sHoursEnd + ":" + sMinutesEnd;
            let model = {
                game_id: this.state.game.value,
                name: values.name,
                min_players: parseInt(values.minPlayer),
                max_players: parseInt(values.maxPlayer),
                start_date: values.startDate,
                reward: fee,
                entry_fee: values.entryFee,
                rules: this.state.rules,
                start_time: HrStartTime,
                last_entry_time: HrEndTime,
                is_verified: this.state.verified,
                status: this.state.status ? 1 : 2,
                stats_code: values.stats_code.replace(/\s/g, ""),
                participant_code: values.participant_code.replace(/\s/g, ""),
                admin_code: values.admin_code.replace(/\s/g, ""),
                admin_id: this.state.admin_id.value,
                free_lobby: this.state.freeStatus,
                gamemap: this.state.gamemap,
                region: this.state.region,
                game_type: this.state.gameTypeMode?.value,
                mode: this.state.modeSelected.value,
                cover_image: this.state.coverImagePreview,
                background_image: this.state.bgImagePreview,
                ...passwordPayload,
                is_manual: this.state.is_manual,
            };
            if (this.state.editMode) {
                model["current_status"] = this.state.current_status;
            }
            this.showLoader(true);
            this.setState({ errorMsg: null });
            if (this.state.editMode) {
                await editLobby(this.state.lobbyId, model).then(
                    (_) => {
                        this.showLoader(false);
                        if (_ !== undefined) {
                            toast.success(
                                `Lobby ${this.state.editMode ? "Updated" : "Added"
                                } Successfully`,
                                toastrOptions
                            );
                        }
                        this.goToListing();
                        this.setState({
                            show_raw: false,
                        });
                    },
                    (err) => {
                        this.showLoader(false);
                        this.setState({ errorMsg: err });
                        if (err.length === 51) {
                            this.props.history.push("/lobby");
                        }
                        this.setState({
                            show_raw: false,
                        });
                    }
                );
            } else {
                await addLobby(model).then(
                    (_) => {
                        this.showLoader(false);
                        if (_ !== undefined) {
                            toast.success(
                                `Lobby ${this.state.editMode ? "Updated" : "Added"
                                } Successfully`,
                                toastrOptions
                            );
                        }
                        this.goToListing();
                        this.setState({
                            show_raw: false,
                        });
                    },
                    (err) => {
                        this.showLoader(false);
                        this.setState({ errorMsg: err });
                        if (err.length === 51) {
                            this.props.history.push("/lobby");
                        }
                        this.setState({
                            show_raw: false,
                        });
                    }
                );
            }
        } else {
            let model = {
                game_id: this.state.game.value,
                name: values.name,
                min_players: parseInt(values.minPlayer),
                max_players: parseInt(values.maxPlayer),
                start_date: this.state.startDate,
                start_time: this.state.startTime,
                last_entry_time: this.state.endTime,
                reward: fee,
                entry_fee: values.entryFee,
                rules: this.state.rules,
                is_verified: this.state.verified,
                status: this.state.status ? 1 : 2,
                stats_code: values.stats_code.replace(/\s/g, ""),
                participant_code: values.participant_code.replace(/\s/g, ""),
                admin_code: values.admin_code.replace(/\s/g, ""),
                admin_id: this.state.admin_id?.value,
                free_lobby: this.state.freeStatus,
                gamemap: this.state.gamemap,
                region: this.state.region,
                game_type: this.state.gameTypeMode?.value,
                mode: this.state.modeSelected.value,
                cover_image: this.state.coverImagePreview,
                background_image: this.state.bgImagePreview,
                ...passwordPayload,
                is_manual: this.state.is_manual,
            };
            if (this.state.editMode) {
                model["current_status"] = this.state.current_status;
            }
            this.showLoader(true);
            this.setState({ errorMsg: null });
            if (this.state.editMode) {
                await editLobby(this.state.lobbyId, model).then(
                    (_) => {
                        this.showLoader(false);
                        if (_ !== undefined) {
                            toast.success(
                                `Lobby ${this.state.editMode ? "Updated" : "Added"
                                } Successfully`,
                                toastrOptions
                            );
                        }
                        this.goToListing();
                        this.setState({
                            show_raw: false,
                        });
                    },
                    (err) => {
                        this.showLoader(false);
                        this.setState({ errorMsg: err });
                        if (err.length === 51) {
                            this.props.history.push("/lobby");
                        }
                        this.setState({
                            show_raw: false,
                        });
                    }
                );
            } else {
                await addLobby(model).then(
                    (_) => {
                        this.showLoader(false);
                        if (_ !== undefined) {
                            toast.success(
                                `Lobby ${this.state.editMode ? "Updated" : "Added"
                                } Successfully`,
                                toastrOptions
                            );
                        }
                        this.goToListing();
                        this.setState({
                            show_raw: false,
                        });
                    },
                    (err) => {
                        this.showLoader(false);
                        this.setState({ errorMsg: err });
                        if (err.length === 51) {
                            this.props.history.push("/lobby");
                        }
                        this.setState({
                            show_raw: false,
                        });
                    }
                );
            }
        }
    }
    handleInvalidSubmit(event, errors, values) {
        this.setImagesState();
        this.setState({
            styleButton: values.entryFee == "" ? true : false,
            endTimeRequiredMsg:
                this.state.endTime == "" || this.state.endTime == null
                    ? "Start time is required"
                    : "",
        });
    }

    goToListing() {
        this.props.history.push("/lobby");
    }

    showLoader(isLoad) {
        this.setState({
            isLoading: isLoad,
        });
    }

    handleChange(event) {
        if (event.target.name === "startDate") {
            let getEstDate = new Date();
            let estDate = getEstDate.toLocaleDateString("en-US", {
                timeZone: "America/New_York",
            });
            let fromatExtractedDate = new Date(estDate);
            let month = "" + (fromatExtractedDate.getMonth() + 1);
            let day = "" + fromatExtractedDate.getDate();
            let year = fromatExtractedDate.getFullYear();
            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;
            let joinDate = [year, month, day].join("-");
            if (joinDate > event.target.value && event.target.value.length != 0) {
                this.setState({
                    startDateError: {
                        startDateBoolean: true,
                        startDateMsg: "Please select future date",
                    },
                    disableSubmitButton: true,
                });
            } else {
                this.setState({
                    startDateError: {
                        startDateBoolean: false,
                        startDateMsg: "",
                    },
                    disableSubmitButton: false,
                });
            }
        }

        if (
            this.state.editMode &&
            (event.target.name === "maxPlayer" || event.target.name === "minPlayer")
        ) {
            this.setState({ gameNew: "" });
            this.setState({ [event.target.name]: event.target.value });
        } else {
            this.setState({ [event.target.name]: event.target.value });
        }
    }
    addMinutes(date) {
        this.setState({
            endTimeRequiredMsg: "",
        });
        this.setState({ startTimeObject: date });
        let minusTime =
            date.getTime() -
            (this.state?.modeSelected?.value == 1
                ? this.state.addMinutesLastEntryTimeSolo
                : this.state.addMinutesLastEntryTimeTeam) *
            60000;
        let minusT = new Date(minusTime);

        this.setState({
            endTime: minusT.toLocaleString("en-GB", {
                hour: "2-digit",
                minute: "2-digit",
            }),
            startTime: date.toLocaleTimeString("en-GB", {
                hour: "2-digit",
                minute: "2-digit",
            }),
        });
    }

    // subtract minutes from start time based upon mode of game

    subtractMinutesModeChange() {
        const currentSelectedStartTime =
            this.startTimeRef?.current?.flatpickr?.selectedDates?.[0];
        if (!currentSelectedStartTime) return;
        let minusTime =
            currentSelectedStartTime.getTime() -
            (this.state?.modeSelected?.mode == 1
                ? this.state.addMinutesLastEntryTimeSolo
                : this.state.addMinutesLastEntryTimeTeam) *
            60000;
        let minusT = new Date(minusTime);
        this.setState({
            endTime: minusT.toLocaleString("en-GB", {
                hour: "2-digit",
                minute: "2-digit",
            }),
        });
    }
    // for enable / disable submit button for edit lobby
    shouldComponentUpdate(prevProps, nextState) {
        // create clone for manipulation to compare intial state and changed state
        const temp = cloneDeep(nextState);
        const refTemp = cloneDeep(this.intialRef.current);
        if (refTemp) {
            // set 24 hours for intial state values as it is in EDT intially in AM/PM type
            refTemp["startTime"] = convertTime12To24(refTemp?.startTime);
            refTemp["endTime"] = convertTime12To24(refTemp?.endTime);

            // if changed state also in EDT AM/Pm format change that also to 24 hours for fair comparison of values
            if (temp["startTime"]?.includes("EDT")) {
                temp["startTime"] = convertTime12To24(temp?.startTime);
            }
            if (temp["endTime"]?.includes("EDT")) {
                temp["endTime"] = convertTime12To24(temp?.endTime);
            }

            // trim whitespace for fair comparison
            trimObjectKeysForCompare(temp);
            // onChange state led to string type but intially ref has int type so make them same type as int for comparison
            parseIntKeysForCompare(temp);
            // remove extra data which we get during changing values in form which is not needed for comparison
            removeNotValidKeysForComparison(temp, refTemp);
            // check whether updated state samew as intial
            const disableSubmit = isEqual(refTemp, temp);
            if (this.state.editSubmitDisable != disableSubmit) {
                this.setState({ editSubmitDisable: disableSubmit });
            }
        }

        return true;
    }
    handleRules = (content, delta, source, editor) => {
        let updatedRules = editor.getHTML();
        this.setState({
            rules: updatedRules + "",
            ruleFieldError: "",
        });
    };
    handleClickShowRaw = () => {
        this.setState({
            show_raw: !this.state.show_raw,
        });
        if (this.state.show_raw)
            this.setState({
                raw_html: this.state.rules,
            });

        this.syncViews(this.state.show_raw);
    };

    syncViews = (fromRaw) => {
        if (fromRaw) {
            this.setState({
                rules: this.state.raw_html + "",
            });
        } else {
            this.setState({
                raw_html: this.state.rules,
            });
        }
    };
    handleChangeRaw = (html) => {
        this.setState({
            raw_html: html + "",
        });
        this.syncViews(this.state.show_raw);
    };

    handleGeneratePassword = (e) => {
        e.preventDefault();
        this.setState({
            lobby_password: randomPasswordGenerator({
                data: passwordCharacterData,
                passWordLength: 15,
            }),
        });
    };

    setSpinnerLoader = (staus) => {
        this.setState({
            spinnerLoader: staus
        })
    }

    setCoverImagePreview = (imgUrl) => {
        this.setState({
            coverImagePreview: imgUrl
        })
    }
    setCoverImageText = (text) => {
        this.setState({
            coverImageText: text
        })
    }
    showCoverImageError = (error) => {
        this.setState({
            coverImageError: error
        })
    }

    setDisabledCoverImage = (status) => {
        this.setState({
            disabled: status
        })
    }

    setSpinnerLoaderBg = (staus) => {
        this.setState({
            spinnerLoaderBg: staus
        })
    }

    setBgImagePreview = (imgUrl) => {
        console.log('image url----', imgUrl)
        this.setState({
            bgImagePreview: imgUrl
        })
    }
    setBgImageText = (text) => {
        this.setState({
            bgImageText: text
        })
    }
    showBgImageError = (error) => {
        this.setState({
            bgImageError: error
        })
    }

    setDisabledBgImage = (status) => {
        this.setState({
            disabledBg: status
        })
    }

    setCoverImageToCrop = (val) => {
        this.setState({
            coverImageToCrop: val
        })

    }

    setBgImageToCrop = (val) => {
        this.setState({
            bgImageToCrop: val
        })

    }

    setCroppedCoverImage = (val) => {
        this.setState({
            croppedCoverImage: val
        })
    }
    setCroppedBgImage = (val) => {
        this.setState({
            croppedBgImage: val
        })
    }
    handleImageUpload = async (imageLink, setPreviewFunction) => {
        try {
            if (!imageLink) return;

            const response = await fetch(imageLink);
            if (!response.ok) {
                throw new Error('Failed to fetch image');
            }

            const blob = await response.blob();
            const file = new File([blob], 'image.jpeg', { type: blob.type });
            const presignedUrl = await getPresignedUrl(blob.type);
            const uploadedImageUrl = await uploadToS3(presignedUrl, file, blob.type);

            if (uploadedImageUrl) {
                setPreviewFunction(uploadedImageUrl);
            }
        } catch (error) {
            console.error("Error during image upload:", error);
        }
    };
    setImagesState = async () => {
        const coverImageLink = localStorage.getItem("coverImageLink");
        const backgroundImageLink = localStorage.getItem("backgroundImageLink");
        // Upload the cover image if it exists
        if (coverImageLink) {
            await this.handleImageUpload(coverImageLink, this.setCoverImagePreview);
            localStorage.removeItem("coverImageLink"); // Clear from localStorage after upload
        }

        // Upload the background image if it exists
        if (backgroundImageLink) {
            await this.handleImageUpload(backgroundImageLink, this.setBgImagePreview);
            localStorage.removeItem("backgroundImageLink"); // Clear from localStorage after upload
        }
    };

    //   setImagesState = async()=> {
    //     const coverImageLink = localStorage.getItem("coverImageLink");
    //     const backgroundImageLink = localStorage.getItem("backgroundImageLink");

    //     try {
    //         if(coverImageLink) {
    //             const response = await fetch(coverImageLink);
    //             const blob = await response.blob();
    //             const file = new File([blob], 'image.jpeg', { type: blob.type });
    //             const presignedUrl = await getPresignedUrl(blob.type);
    //             const uploadedImageUrl = await uploadToS3(presignedUrl, file, blob.type);
    //             if (uploadedImageUrl) {
    //                 this.setCoverImagePreview(uploadedImageUrl)
    //             }
    //         }
    //         if(backgroundImageLink) {
    //             const response = await fetch(backgroundImageLink);
    //             const blob = await response.blob();
    //             const file = new File([blob], 'image.jpeg', { type: blob.type });
    //             const presignedUrl = await getPresignedUrl(blob.type);
    //             const uploadedImageUrl = await uploadToS3(presignedUrl, file, blob.type);

    //             if (uploadedImageUrl) {
    //                 this.setBgImagePreview(uploadedImageUrl)
    //             }
    //         }
    //     } catch (error) {
    //         console.log("Error during image upload:", error);
    //     }

    //   }

    onUpload = async (
        event,
        setSpinnerLoaderFunc,
        showErrorFunc,
        setDisabledFunc,
        isBackground = false
    ) => {
        setSpinnerLoaderFunc(true);
        event.preventDefault();

        const regex = new RegExp("([a-zA-Z0-9s_\\.-:])+(.jpg|.jpeg|.png)$");
        if (!event.target.value || !regex.test(event.target.value.toLowerCase())) {
            const errorMsg = "Please select a valid Image file.";
            showErrorFunc(errorMsg);
            toast.error(errorMsg);
            setSpinnerLoaderFunc(false);
            setDisabledFunc(true);
            return false;
        }

        if (!event.target.files || !event.target.files[0]) {
            setSpinnerLoaderFunc(false);
            return false;
        }
        const reader = new FileReader();
        if (isBackground) {
            reader.addEventListener("load", () => this.setBgImageToCrop(reader.result));
            reader.readAsDataURL(event.target.files[0]);
        } else {
            reader.addEventListener("load", () => this.setCoverImageToCrop(reader.result));
            reader.readAsDataURL(event.target.files[0]);
        }


        reader.onload = (e) => {
            // Ensure e.target.result is valid
            if (!e.target?.result) {
                setSpinnerLoaderFunc(false);
                return;
            }

            const image = new Image();
            image.src = e.target.result;

            image.onload = async () => {
                try {
                    const height = image.height ?? 0;
                    const width = image.width ?? 0;

                } catch (error) {
                    showErrorFunc(error.message || 'Upload failed');
                    toast.error(error.message || 'Upload failed');
                } finally {
                    setSpinnerLoaderFunc(false);
                }
            };

            // Add error handler for image load failures
            image.onerror = () => {
                showErrorFunc('Failed to load image');
                toast.error('Failed to load image');
                setSpinnerLoaderFunc(false);
            };
        };

        // Add error handler for FileReader
        reader.onerror = () => {
            showErrorFunc('Failed to read file');
            toast.error('Failed to read file');
            setSpinnerLoaderFunc(false);
        };
    };

    handleGameTypeChange = (event) => {
        const isGameExistInCurrentType = this.props?.game?.modeObj?.[
            event?.value
        ]?.find((gameObj) => gameObj.value == this.state.game?.value);
        // if (
        //   !isGameExistInCurrentType &&
        //   this.props?.game?.modeObj?.[event?.value]?.[0]
        // ) {
        this.handleSelectGroup(
            this.props?.game?.modeObj?.[event?.value]?.[0],
            event?.value
        );
        this.setState({ gameTypeMode: event });
    };

    render() {
        const { coverImageError, bgImageError } = this.state

        return (
            <React.Fragment>
                <Loader showLoader={this.state.isLoading} />
                <div className="page-content">
                    <Breadcrumbs
                        breadcrumbItem={(this.state.editMode ? "Edit" : "Add") + " Lobby"}
                    />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <Row className="mb-4">
                                        <Col>
                                            <p>
                                                <Link to="/lobby">
                                                    <i className="mdi mdi-arrow-left"></i> back
                                                </Link>
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="add-lobby-row">
                                        <Col className="col-lg-8 col-md-8 col-sm-12">
                                            <AvForm
                                                onValidSubmit={(e, v) => {
                                                    this.handleValidSubmit(e, v);
                                                }}
                                                onInvalidSubmit={this.handleInvalidSubmit}
                                            >

                                                <div className="mb-3">
                                                    <Label>Game Type</Label>
                                                    <Select
                                                        value={this.state.gameTypeMode}
                                                        options={gameTypeFilters}
                                                        name="game_type"
                                                        classNamePrefix="select2-selection"
                                                        onChange={this.handleGameTypeChange}
                                                        isDisabled={canEditLobbyField({
                                                            editMode: this.state.editMode,
                                                            current_status: this.state.current_status,
                                                            is_manual: this.state.is_manual,
                                                            editIfActive: false,
                                                        })}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <Label>Select Game</Label>
                                                    {this.state.editMode ? (
                                                        <Select
                                                            key={this.state.gameTypeMode?.value}
                                                            value={this.state.game}
                                                            onChange={(event) => {
                                                                this.handleSelectGroup(event);
                                                            }}
                                                            options={
                                                                this.state.current_status !== 1
                                                                    ? ""
                                                                    : this.props?.game?.modeObj?.[
                                                                    this.state.gameTypeMode?.value
                                                                    ]
                                                            }
                                                            name="game"
                                                            classNamePrefix="select2-selection"
                                                            isDisabled={canEditLobbyField({
                                                                editMode: this.state.editMode,
                                                                current_status: this.state.current_status,
                                                                is_manual: this.state.is_manual,
                                                                editIfActive: false,
                                                            })}
                                                        />
                                                    ) : (
                                                        <Select
                                                            key={this.state.gameTypeMode?.value}
                                                            value={this.state.game}
                                                            onChange={(event) => {
                                                                this.handleSelectGroup(event);
                                                            }}
                                                            options={
                                                                this.props?.game?.modeObj?.[
                                                                this.state.gameTypeMode?.value
                                                                ]
                                                            }
                                                            name="game"
                                                            classNamePrefix="select2-selection"
                                                            isDisabled={canEditLobbyField({
                                                                editMode: this.state.editMode,
                                                                current_status: this.state.current_status,
                                                                is_manual: this.state.is_manual,
                                                                editIfActive: false,
                                                            })}
                                                        />
                                                    )}
                                                </div>

                                                <div className="mb-3">
                                                    <Label>Lobby Mode</Label>
                                                    <Select
                                                        key={this.state.gameTypeMode?.value}
                                                        value={this.state.modeSelected}
                                                        onChange={(mode) => {
                                                            if (
                                                                this.state.game?.slug ==
                                                                "call-of-duty-warzone" &&
                                                                mode.value <= 2
                                                            ) {
                                                                this.setState({
                                                                    minPlayer:
                                                                        this.state.game?.min_players * mode.value,
                                                                    min_Player:
                                                                        this.state.game?.min_players * mode.value,
                                                                    maxPlayer:
                                                                        this.state.game?.max_players * mode.value,
                                                                    max_Player:
                                                                        this.state.game?.max_players * mode.value,
                                                                });
                                                            }

                                                            if (
                                                                this.state.game?.slug ==
                                                                "apex-legends" &&
                                                                this.state.gameTypeMode?.value == 3
                                                            ) {
                                                                this.setState({
                                                                    minPlayer:
                                                                        2 * mode.value,
                                                                    min_Player:
                                                                        2 * mode.value,
                                                                    maxPlayer:
                                                                        2 * mode.value,
                                                                    max_Player:
                                                                        2 * mode.value,
                                                                });
                                                            }


                                                            this.setState({
                                                                modeSelected: mode,
                                                            });
                                                        }}
                                                        options={getModeLabelsSelect(
                                                            this.state.gameTypeMode,
                                                            this.props?.game?.modeGameTypeObj?.[
                                                            this.state?.game?.slug
                                                            ]
                                                        )}
                                                        name="game"
                                                        classNamePrefix="select2-selection"
                                                        isDisabled={canEditLobbyField({
                                                            editMode: this.state.editMode,
                                                            current_status: this.state.current_status,
                                                            is_manual: this.state.is_manual,
                                                            editIfActive: false,
                                                        })}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <AvField
                                                        name="name"
                                                        label="Lobby Name"
                                                        placeholder="Enter lobby name here"
                                                        type="text"
                                                        value={this.state.name}
                                                        validate={{
                                                            required: {
                                                                value: true,
                                                                errorMessage: "Lobby name is required",
                                                            },
                                                            maxLength: {
                                                                value: 50,
                                                                errorMessage: "Name can have 50 characters max",
                                                            },
                                                        }}
                                                        onChange={this.handleChange}
                                                        disabled={canEditLobbyField({
                                                            editMode: this.state.editMode,
                                                            current_status: this.state.current_status,
                                                            is_manual: this.state.is_manual,
                                                            editIfActive: false,
                                                        })}
                                                    />
                                                </div>
                                                <Row className="flex-align-item-center">
                                                    <Col className="col-lg-8 col-md-8 col-sm-8 col-12">
                                                        <div className="mb-3">
                                                            <AvField
                                                                name="entryFee"
                                                                label="Entry Fee/Player"
                                                                placeholder="Enter entry fee"
                                                                type="text"
                                                                value={this.state.entryFee}
                                                                validate={{
                                                                    required: {
                                                                        value: true,
                                                                        errorMessage: "Entry fee is required",
                                                                    },
                                                                    number: {
                                                                        value: true,
                                                                        errorMessage: "Please enter number only",
                                                                    },
                                                                    min: {
                                                                        value: 0,
                                                                        errorMessage:
                                                                            "Lobby type value must be between 0 and 1000 value",
                                                                    },
                                                                    max: {
                                                                        value: 1000,
                                                                        errorMessage:
                                                                            "Entry fee must be between 1 and 1000 value",
                                                                    },
                                                                    pattern: {
                                                                        value: /^\d+$/,
                                                                        errorMessage:
                                                                            "Entry fee can't be in decimals.",
                                                                    },
                                                                }}
                                                                onChange={this.handleChange}
                                                                className="test"
                                                                disabled={canEditLobbyField({
                                                                    editMode: this.state.editMode,
                                                                    current_status: this.state.current_status,
                                                                    is_manual: this.state.is_manual,
                                                                    editIfActive: false,
                                                                })}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col
                                                        className={
                                                            this.state.styleButton
                                                                ? "validationMessage"
                                                                : "col-lg-2 col-md-2 col-sm-2 col-4 lobby-fees"
                                                        }
                                                    >
                                                        <Button
                                                            className="btn btn-sm btn-secondary custom-lobbytype-btn"
                                                            onClick={() => {
                                                                this.setState({
                                                                    entryFee: "1",
                                                                });
                                                            }}
                                                            disabled={canEditLobbyField({
                                                                editMode: this.state.editMode,
                                                                current_status: this.state.current_status,
                                                                is_manual: this.state.is_manual,
                                                                editIfActive: false,
                                                            })}
                                                        >
                                                            $1
                                                        </Button>
                                                    </Col>
                                                    <Col
                                                        className={
                                                            this.state.styleButton
                                                                ? "validationMessage"
                                                                : "col-lg-2 col-md-2 col-sm-2 col-4 lobby-fees"
                                                        }
                                                    >
                                                        <Button
                                                            className="btn btn-sm btn-secondary custom-lobbytype-btn"
                                                            onClick={() => {
                                                                this.setState({
                                                                    entryFee: "5",
                                                                });
                                                            }}
                                                            disabled={canEditLobbyField({
                                                                editMode: this.state.editMode,
                                                                current_status: this.state.current_status,
                                                                is_manual: this.state.is_manual,
                                                                editIfActive: false,
                                                            })}
                                                        >
                                                            $5
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                <div className="mb-3">
                                                    <AvField
                                                        name="minPlayer"
                                                        label="Minimum Players"
                                                        placeholder="Enter minimum players"
                                                        type="number"
                                                        value={
                                                            this.state.editMode
                                                                ? this.state.gameNew === ""
                                                                    ? this.state.minPlayer
                                                                    : this.state.gameNew !== this.state.prevGameId
                                                                        ? this.state.min_Player
                                                                        : this.state.minPlayer
                                                                : this.state.min_Player === null
                                                                    ? this.state.minPlayer
                                                                    : this.state.min_Player
                                                        }
                                                        validate={{
                                                            required: {
                                                                value: true,
                                                                errorMessage: "Minimum Players is required",
                                                            },
                                                            min: {
                                                                value:
                                                                    this.state.min_Player === undefined
                                                                        ? this.state.minPlayer
                                                                        : this.state.min_Player,
                                                                errorMessage: `Minimum players has to be atleast ${this.state.min_Player === undefined
                                                                        ? this.state.minPlayer
                                                                        : this.state.min_Player
                                                                    }`,
                                                            },
                                                            max: {
                                                                value: this.state.editMode
                                                                    ? this.state.gameNew === ""
                                                                        ? this.state.max_Player <=
                                                                            this.state.maxPlayer
                                                                            ? this.state.max_Player
                                                                            : this.state.maxPlayer
                                                                        : this.state.gameNew !==
                                                                            this.state.prevGameId
                                                                            ? this.state.max_Player
                                                                            : this.state.maxPlayer
                                                                    : this.state.max_Player === null
                                                                        ? this.state.maxPlayer
                                                                        : this.state.max_Player,
                                                                errorMessage: `Minimum players has to be less than or equals to maximum players i.e. ${this.state.editMode
                                                                        ? this.state.gameNew === ""
                                                                            ? this.state.max_Player <=
                                                                                this.state.maxPlayer
                                                                                ? this.state.max_Player
                                                                                : this.state.maxPlayer
                                                                            : this.state.gameNew !==
                                                                                this.state.prevGameId
                                                                                ? this.state.max_Player
                                                                                : this.state.maxPlayer
                                                                        : this.state.max_Player === null
                                                                            ? this.state.maxPlayer
                                                                            : this.state.max_Player
                                                                    }`,
                                                            },
                                                        }}
                                                        onChange={this.handleChange}
                                                        disabled={
                                                            this.state.game?.slug == "call-of-duty-warzone" ||
                                                                canEditLobbyField({
                                                                    editMode: this.state.editMode,
                                                                    current_status: this.state.current_status,
                                                                    is_manual: this.state.is_manual,
                                                                    editIfActive: false,
                                                                })
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <AvField
                                                        name="maxPlayer"
                                                        label="Maximum Players"
                                                        placeholder="Enter maximum players"
                                                        type="number"
                                                        value={
                                                            this.state.editMode
                                                                ? this.state.gameNew === ""
                                                                    ? this.state.maxPlayer
                                                                    : this.state.gameNew !== this.state.prevGameId
                                                                        ? this.state.max_Player
                                                                        : this.state.maxPlayer
                                                                : this.state.max_Player === null
                                                                    ? this.state.maxPlayer
                                                                    : this.state.max_Player
                                                        }
                                                        validate={{
                                                            required: {
                                                                value: true,
                                                                errorMessage: "Maximum Players is required",
                                                            },
                                                            min: {
                                                                value:
                                                                    this.state.min_Player === undefined
                                                                        ? this.state.minPlayer
                                                                        : this.state.min_Player,
                                                                errorMessage: `Maximum players has to be greater than or equals to ${this.state.min_Player === undefined
                                                                        ? this.state.minPlayer
                                                                        : this.state.min_Player
                                                                    }`,
                                                            },
                                                            max: {
                                                                value: this.state.editMode
                                                                    ? this.state.gameNew === ""
                                                                        ? this.state.max_Player <=
                                                                            this.state.maxPlayer
                                                                            ? this.state.max_Player
                                                                            : this.state.maxPlayer
                                                                        : this.state.gameNew !==
                                                                            this.state.prevGameId
                                                                            ? this.state.max_Player <=
                                                                                this.state.maxPlayer
                                                                                ? this.state.max_Player
                                                                                : this.state.maxPlayer
                                                                            : this.state.maxPlayer
                                                                    : this.state.max_Player === null
                                                                        ? this.state.maxPlayer
                                                                        : this.state.max_Player,
                                                                errorMessage: `Maximum players should be equals to ${this.state.editMode
                                                                        ? this.state.gameNew === ""
                                                                            ? this.state.max_Player <=
                                                                                this.state.maxPlayer
                                                                                ? this.state.max_Player
                                                                                : this.state.maxPlayer
                                                                            : this.state.gameNew !==
                                                                                this.state.prevGameId
                                                                                ? this.state.max_Player <=
                                                                                    this.state.maxPlayer
                                                                                    ? this.state.max_Player
                                                                                    : this.state.maxPlayer
                                                                                : this.state.maxPlayer
                                                                        : this.state.max_Player === null
                                                                            ? this.state.maxPlayer
                                                                            : this.state.max_Player
                                                                    } players`,
                                                            },
                                                        }}
                                                        onChange={this.handleChange}
                                                        disabled={
                                                            this.state.game?.slug == "call-of-duty-warzone" ||
                                                                canEditLobbyField({
                                                                    editMode: this.state.editMode,
                                                                    current_status: this.state.current_status,
                                                                    is_manual: this.state.is_manual,
                                                                    editIfActive: false,
                                                                })
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </div>
                                                <div>
                                                    <AvField
                                                        className="uppercase"
                                                        name="startDate"
                                                        label="Start Date (EDT)"
                                                        placeholder="DD-MM-YYYY"
                                                        type="date"
                                                        value={this.state.startDate}
                                                        validate={{
                                                            required: {
                                                                value: true,
                                                                errorMessage: "Start Date is required",
                                                            },
                                                        }}
                                                        onChange={this.handleChange}
                                                        disabled={canEditLobbyField({
                                                            editMode: this.state.editMode,
                                                            current_status: this.state.current_status,
                                                            is_manual: this.state.is_manual,
                                                            editIfActive: true,
                                                        })}
                                                    />

                                                    <label
                                                        style={{
                                                            width: "100%",
                                                            marginTop: "0.25rem",
                                                            fontSize: "0.875em",
                                                            color: "#ff715b",
                                                        }}
                                                    >
                                                        {this.state.startDateError.startDateBoolean
                                                            ? this.state.startDateError.startDateMsg
                                                            : null}
                                                    </label>
                                                </div>
                                                <FormGroup className="mb-3">
                                                    <Label
                                                        className={
                                                            this.state.endTimeRequiredMsg.length === 0
                                                                ? ""
                                                                : "errorMsgGames"
                                                        }
                                                    >
                                                        Start Time (EDT){" "}
                                                    </Label>
                                                    <InputGroup>
                                                        <Flatpickr
                                                            className="form-control d-block startTimeBackground"
                                                            placeholder="Enter start time"
                                                            value={this.state.startTime}
                                                            ref={this.startTimeRef}
                                                            name="startTime"
                                                            required
                                                            options={{
                                                                enableTime: true,
                                                                noCalendar: true,
                                                                minuteIncrement: 1,
                                                                dateFormat: "H:i K",
                                                                time_24hr: true,
                                                            }}
                                                            onChange={(e, value, options) => {
                                                                this.addMinutes(options.latestSelectedDateObj);
                                                            }}
                                                            disabled={canEditLobbyField({
                                                                editMode: this.state.editMode,
                                                                current_status: this.state.current_status,
                                                                is_manual: this.state.is_manual,
                                                                editIfActive: true,
                                                            })}
                                                        />
                                                    </InputGroup>
                                                    <label
                                                        className="errorMsgGames"
                                                        style={{ paddingTop: "10px" }}
                                                    >
                                                        {this.state.endTimeRequiredMsg === ""
                                                            ? null
                                                            : this.state.endTimeRequiredMsg}
                                                    </label>
                                                </FormGroup>
                                                <FormGroup className="mb-3">
                                                    <Label
                                                        className={
                                                            this.state.endTimeRequiredMsg.length === 0
                                                                ? ""
                                                                : "errorMsgGames"
                                                        }
                                                    >
                                                        Last Entry Time (EDT){" "}
                                                    </Label>
                                                    <InputGroup>
                                                        <Flatpickr
                                                            className="form-control d-block"
                                                            placeholder="Enter last entry time"
                                                            value={this.state.endTime}
                                                            name="endTime"
                                                            required
                                                            options={{
                                                                enableTime: true,
                                                                noCalendar: true,
                                                                minuteIncrement: 5,
                                                                dateFormat: "H:i K",
                                                                time_24hr: true,
                                                            }}
                                                            onChange={(e, value) => {
                                                                this.setState({
                                                                    endTime: value,
                                                                });
                                                            }}
                                                            disabled
                                                        />
                                                    </InputGroup>
                                                    <label
                                                        className="errorMsgGames"
                                                        style={{ paddingTop: "10px" }}
                                                    >
                                                        {this.state.endTimeRequiredMsg === ""
                                                            ? null
                                                            : "Last entry time is required"}
                                                    </label>
                                                </FormGroup>

                                                <div
                                                    className={
                                                        this.state.show_raw ? "mb-3 showRaw" : "mb-3"
                                                    }
                                                >
                                                    <Label
                                                        className={
                                                            this.state.ruleFieldError.length === 0
                                                                ? ""
                                                                : "errorMsgGames"
                                                        }
                                                    >
                                                        Game Rules
                                                    </Label>
                                                    <EditorToolbar
                                                        toolbarId="toolbar-1"
                                                        onClickRaw={this.handleClickShowRaw}
                                                        disabled={canEditLobbyField({
                                                            editMode: this.state.editMode,
                                                            current_status: this.state.current_status,
                                                            is_manual: this.state.is_manual,
                                                            editIfActive: true,
                                                        })}
                                                    />
                                                    <ReactQuill
                                                        theme="snow"
                                                        modules={{
                                                            toolbar: {
                                                                container: `#toolbar-${1}`,
                                                            },
                                                            clipboard: {
                                                                matchVisual: false,
                                                            },
                                                        }}
                                                        formats={formats}
                                                        onChange={this.handleRules}
                                                        value={this.state.rules}
                                                        placeholder="Enter game rules here"
                                                    />
                                                    <textarea
                                                        className={"raw-editor"}
                                                        onChange={(e) =>
                                                            this.handleChangeRaw(e.target.value)
                                                        }
                                                        value={this.state.raw_html}
                                                    />
                                                    <label className="errorMsgGames">
                                                        {this.state.ruleFieldError}
                                                    </label>
                                                </div>

                                                <div className="mb-3">
                                                    <Label>Select Admin</Label>
                                                    <Select
                                                        value={this.state.admin_id}
                                                        onChange={(event) => {
                                                            this.handleAdminSelectGroup(event);
                                                        }}
                                                        options={this.state.admins}
                                                        name="admin_id"
                                                        classNamePrefix="select2-selection"
                                                        disabled={canEditLobbyField({
                                                            editMode: this.state.editMode,
                                                            current_status: this.state.current_status,
                                                            is_manual: this.state.is_manual,
                                                            editIfActive: true,
                                                        })}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <AvField
                                                        name="admin_code"
                                                        label="Admin Code*"
                                                        placeholder="Enter admin code here"
                                                        type="text"
                                                        value={this.state.admin_code}
                                                        validate={{
                                                            required: {
                                                                value:
                                                                    this.state.game?.slug === "fortnite" ||
                                                                        this.state.game?.slug === "cod-mobile" ||
                                                                        this.state.game?.slug === "pubg-mobile" ||
                                                                        this.state.game?.slug === "valorant" ||
                                                                        // (this.state.game?.slug =='apex-legends' && this.state.gameTypeMode?.value=="3") ||

                                                                        this.state.game?.slug ==
                                                                        "call-of-duty-warzone"
                                                                        ? false
                                                                        : true,
                                                                errorMessage: "Admin code is required",
                                                            },
                                                        }}
                                                        onChange={this.handleChange}
                                                        disabled={
                                                            this.state.game?.slug === "fortnite" ||
                                                                this.state.game?.slug === "valorant" ||
                                                                // (this.state.game?.slug =='apex-legends' && this.state.gameTypeMode?.value=="3") ||

                                                                this.state.game?.slug == "call-of-duty-warzone" ||
                                                                canEditLobbyField({
                                                                    editMode: this.state.editMode,
                                                                    current_status: this.state.current_status,
                                                                    is_manual: this.state.is_manual,
                                                                    editIfActive: true,
                                                                })
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <AvField
                                                        name="participant_code"
                                                        label="Participant Code*"
                                                        placeholder="Enter participant code here"
                                                        type="text"
                                                        value={this.state.participant_code}
                                                        validate={{
                                                            required: {
                                                                value:
                                                                    this.state.game?.slug === "fortnite" ||
                                                                        this.state.game?.slug === "cod-mobile" ||
                                                                        this.state.game?.slug === "pubg-mobile" ||
                                                                        this.state.game?.slug === "valorant" ||
                                                                        //  (this.state.game?.slug =='apex-legends' && this.state.gameTypeMode?.value=="3") ||

                                                                        this.state.game?.slug ==
                                                                        "call-of-duty-warzone"
                                                                        ? false
                                                                        : true,
                                                                errorMessage: "Participant code is required",
                                                            },
                                                        }}
                                                        onChange={this.handleChange}
                                                        disabled={
                                                            this.state.game?.slug === "valorant" ||
                                                                // (this.state.game?.slug =='apex-legends' && this.state.gameTypeMode?.value=="3") ||

                                                                this.state.game?.slug == "call-of-duty-warzone" ||
                                                                canEditLobbyField({
                                                                    editMode: this.state.editMode,
                                                                    current_status: this.state.current_status,
                                                                    is_manual: this.state.is_manual,
                                                                    editIfActive: true,
                                                                })
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <AvField
                                                        name="stats_code"
                                                        label={
                                                            <>
                                                                <IMG data={this.state.game?.slug} />
                                                            </>
                                                        }
                                                        placeholder="Enter statistic code here"
                                                        type="text"
                                                        value={this.state.stats_code}
                                                        validate={{
                                                            required: {
                                                                value:
                                                                    this.state.game === null
                                                                        ? true
                                                                        : this.state.game?.value === 2 ||
                                                                            canEditLobbyField({
                                                                                editMode: this.state.editMode,
                                                                                current_status:
                                                                                    this.state.current_status,
                                                                                is_manual: this.state.is_manual,
                                                                                editIfActive: true,
                                                                            })
                                                                            ? true
                                                                            : false,
                                                                errorMessage: "Statistic code is required",
                                                            },
                                                        }}
                                                        onChange={this.handleChange}
                                                        disabled={
                                                            this.state.game?.slug === "fortnite" ||
                                                                this.state.game?.slug === "cod-mobile" ||
                                                                this.state.game?.slug === "valorant"
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <AvField
                                                        name="gamemap"
                                                        label="Map"
                                                        placeholder="Enter map information here"
                                                        type="text"
                                                        value={this.state.gamemap}
                                                        validate={{
                                                            maxLength: {
                                                                value: 40,
                                                                errorMessage:
                                                                    "Map information can have 40 characters max",
                                                            },
                                                        }}
                                                        onChange={this.handleChange}
                                                        disabled={canEditLobbyField({
                                                            editMode: this.state.editMode,
                                                            current_status: this.state.current_status,
                                                            is_manual: this.state.is_manual,
                                                            editIfActive: true,
                                                        })}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <AvField
                                                        name="region"
                                                        label={`Region${this.state.game?.slug === "valorant" ? "*" : ""
                                                            }`}
                                                        placeholder="Enter region information here"
                                                        type="text"
                                                        value={this.state.region}
                                                        validate={{
                                                            required: {
                                                                value:
                                                                    this.state.game?.slug === "valorant"
                                                                        ? true
                                                                        : false,
                                                                errorMessage: "Region is required",
                                                            },
                                                            maxLength: {
                                                                value: 40,
                                                                errorMessage:
                                                                    "Region information can have 40 characters max",
                                                            },
                                                        }}
                                                        onChange={this.handleChange}
                                                        disabled={canEditLobbyField({
                                                            editMode: this.state.editMode,
                                                            current_status: this.state.current_status,
                                                            is_manual: this.state.is_manual,
                                                            editIfActive: true,
                                                        })}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <AvField
                                                        name="lobby_password"
                                                        label={
                                                            <div>
                                                                <Row className="verified-check-row">
                                                                    <Col>
                                                                        <p className="verified-check">Password</p>
                                                                    </Col>
                                                                    <Col className="col-lg-3 col-sm-3">
                                                                        <Switch
                                                                            uncheckedIcon={<Offsymbol />}
                                                                            checkedIcon={<OnSymbol />}
                                                                            onColor="#626ed4"
                                                                            className={
                                                                                this.state.isPasswordProtected
                                                                                    ? "form-label active-toggle"
                                                                                    : "form-label"
                                                                            }
                                                                            onChange={() => {
                                                                                this.setState({
                                                                                    isPasswordProtected:
                                                                                        !this.state.isPasswordProtected,
                                                                                });
                                                                            }}
                                                                            checked={this.state.isPasswordProtected}
                                                                            disabled={canEditLobbyField({
                                                                                editMode: this.state.editMode,
                                                                                current_status:
                                                                                    this.state.current_status,
                                                                                is_manual: this.state.is_manual,
                                                                                editIfActive: true,
                                                                            })}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        }
                                                        placeholder="Enter password here"
                                                        type="text"
                                                        onKeyDown={(event) => {
                                                            if (event.code === "Space")
                                                                event.preventDefault();
                                                        }}
                                                        value={this.state.lobby_password}
                                                        validate={{
                                                            required: {
                                                                value: this.state.isPasswordProtected
                                                                    ? true
                                                                    : false,
                                                                errorMessage:
                                                                    "Password must be between 8-15 characters",
                                                            },
                                                            maxLength: {
                                                                value: 15,
                                                                errorMessage:
                                                                    "Password should have 15 characters max",
                                                            },
                                                            minLength: {
                                                                value: this.state.isPasswordProtected
                                                                    ? 8
                                                                    : Math.max(),
                                                                errorMessage:
                                                                    "Password should have 8 characters min",
                                                            },
                                                        }}
                                                        onChange={this.handleChange}
                                                        disabled={!this.state.isPasswordProtected}
                                                    />
                                                    <button
                                                        className="mt-3 ms-1 btn btn-primary"
                                                        onClick={this.handleGeneratePassword}
                                                        disabled={!this.state.isPasswordProtected}
                                                    >
                                                        Generate Password
                                                    </button>
                                                </div>
                                                <div className="mt-3">
                                                    <Row className="align-items-center verified-check-row">
                                                        <Col className="col-lg-3 col-sm-3">
                                                            <p className="verified-check">Verified</p>
                                                        </Col>
                                                        <Col className="col-lg-3 col-sm-3">
                                                            <Switch
                                                                uncheckedIcon={<Offsymbol />}
                                                                checkedIcon={<OnSymbol />}
                                                                onColor="#626ed4"
                                                                className={
                                                                    this.state.verified
                                                                        ? "form-label active-toggle"
                                                                        : "form-label"
                                                                }
                                                                onChange={() => {
                                                                    this.setState({
                                                                        verified: !this.state.verified,
                                                                    });
                                                                }}
                                                                checked={this.state.verified}
                                                                disabled={canEditLobbyField({
                                                                    editMode: this.state.editMode,
                                                                    current_status: this.state.current_status,
                                                                    is_manual: this.state.is_manual,
                                                                    editIfActive: false,
                                                                })}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className="mt-3">
                                                    <Row className="align-items-center verified-check-row">
                                                        <Col className="col-lg-3 col-sm-3">
                                                            <p className="verified-check">Active Lobby</p>
                                                        </Col>
                                                        <Col className="col-lg-3 col-sm-3">
                                                            <Switch
                                                                uncheckedIcon={<Offsymbol />}
                                                                checkedIcon={<OnSymbol />}
                                                                onColor="#626ed4"
                                                                className={
                                                                    this.state.status
                                                                        ? "form-label active-toggle"
                                                                        : "form-label"
                                                                }
                                                                onChange={() => {
                                                                    this.setState({
                                                                        status: !this.state.status,
                                                                    });
                                                                }}
                                                                checked={this.state.status}
                                                                disabled={
                                                                    this.state.isPlayerEnrolled ||
                                                                    canEditLobbyField({
                                                                        editMode: this.state.editMode,
                                                                        current_status: this.state.current_status,
                                                                        is_manual: this.state.is_manual,
                                                                        editIfActive: false,
                                                                    })
                                                                }
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <div className="mt-3">
                                                        <Row className="align-items-center verified-check-row">
                                                            <Col className="col-lg-3 col-sm-3">
                                                                <p className="verified-check">Manual Scoring</p>
                                                            </Col>
                                                            <Col className="col-lg-3 col-sm-3">
                                                                <Switch
                                                                    uncheckedIcon={<Offsymbol />}
                                                                    checkedIcon={<OnSymbol />}
                                                                    onColor="#626ed4"
                                                                    className={
                                                                        this.state.is_manual
                                                                            ? "form-label active-toggle"
                                                                            : "form-label"
                                                                    }
                                                                    onChange={() => {
                                                                        this.setState({
                                                                            is_manual: !this.state.is_manual,
                                                                        });
                                                                    }}
                                                                    checked={this.state.is_manual}
                                                                    disabled={this.state.editMode}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                                <div className="mt-3">
                                                    <Row className="align-items-center verified-check-row">
                                                        <Col className="col-lg-3 col-sm-3">
                                                            <p className="verified-check">Free Lobby</p>
                                                        </Col>
                                                        <Col className="col-lg-3 col-sm-3">
                                                            <Switch
                                                                uncheckedIcon={<Offsymbol />}
                                                                checkedIcon={<OnSymbol />}
                                                                onColor="#626ed4"
                                                                className={
                                                                    this.state.freeStatus
                                                                        ? "form-label active-toggle"
                                                                        : "form-label"
                                                                }
                                                                onChange={() => {
                                                                    this.setState({
                                                                        freeStatus: !this.state.freeStatus,
                                                                    });
                                                                }}
                                                                checked={this.state.freeStatus}
                                                                disabled={
                                                                    this.state.isPlayerEnrolled ||
                                                                    canEditLobbyField({
                                                                        editMode: this.state.editMode,
                                                                        current_status: this.state.current_status,
                                                                        is_manual: this.state.is_manual,
                                                                        editIfActive: false,
                                                                    })
                                                                }
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg={12} className=" mt-3 chooseImage">
                                                            {" "}
                                                            <CustomInput
                                                                type="file"
                                                                id="exampleCustomFileBrowser"
                                                                name="customFile"
                                                                label={"Choose an cover image file"}
                                                                onChange={(event) =>
                                                                    this.onUpload(
                                                                        event,
                                                                        this.setSpinnerLoader,
                                                                        this.showCoverImageError,
                                                                        this.setDisabledCoverImage
                                                                    )
                                                                }
                                                            />
                                                            <div >
                                                                <div class="upload-img">
                                                                    <ImageCropper
                                                                        imageToCrop={this.state.coverImageToCrop}
                                                                        onImageCropped={(newCroppedImage) => {
                                                                            this.setCroppedCoverImage(newCroppedImage);


                                                                        }}
                                                                        type="cover"
                                                                    />
                                                                </div>
                                                                {this.state.croppedCoverImage && (
                                                                    <div class="upload-img">
                                                                        <h2>Cropped Image</h2>
                                                                        <img
                                                                            alt="Cropped"
                                                                            src={this.state.croppedCoverImage}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <label className={
                                                                coverImageError.length == 0
                                                                    ? ""
                                                                    : "errorMsgGames invalid-feedback"
                                                            }>
                                                                {coverImageError?.length === 0
                                                                    ? null
                                                                    : coverImageError}
                                                            </label>

                                                        </Col>
                                                        <Col lg={12} className="mt-3 chooseImage">
                                                            {" "}
                                                            <CustomInput
                                                                type="file"
                                                                id="exampleCustomFileBrowserBg"
                                                                name="customFileBg"
                                                                label={"Choose an Background image file"}
                                                                onChange={(event) =>
                                                                    this.onUpload(
                                                                        event,
                                                                        this.setSpinnerLoaderBg,
                                                                        this.showBgImageError,
                                                                        this.setDisabledBgImage,
                                                                        true
                                                                    )
                                                                }
                                                            />
                                                            <div class="upload-img">
                                                                <ImageCropper
                                                                    imageToCrop={this.state.bgImageToCrop}
                                                                    onImageCropped={(newCroppedImage) => {
                                                                        this.setCroppedBgImage(newCroppedImage)


                                                                    }}
                                                                    type="background"
                                                                />
                                                            </div>
                                                            {this.state.croppedBgImage && (
                                                                <div class="upload-img">
                                                                    <h2>Cropped Image</h2>
                                                                    <img
                                                                        alt="Cropped"
                                                                        src={this.state.croppedBgImage}
                                                                    />
                                                                </div>
                                                            )}

                                                            {bgImageError?.length == 0 ? null : (
                                                                <label
                                                                    className={
                                                                        bgImageError.length == 0
                                                                            ? ""
                                                                            : "errorMsgGames invalid-feedback"
                                                                    }>
                                                                    {bgImageError}
                                                                </label>
                                                            )}

                                                        </Col>
                                                    </Row>
                                                </div>
                                                {this.state.errorMsg ? (
                                                    <p className="error-msg">{this.state.errorMsg}</p>
                                                ) : null}
                                                <FormGroup className="mt-4">
                                                    <div>
                                                        <Button
                                                            type="submit"
                                                            color="primary"
                                                            className="ms-1"
                                                            disabled={
                                                                this.state.isLoading ||
                                                                (this.state.editMode &&
                                                                    this.state.editSubmitDisable) ||
                                                                this.state.disableSubmitButton
                                                            }
                                                        >
                                                            Submit
                                                        </Button>
                                                    </div>
                                                </FormGroup>
                                            </AvForm>
                                        </Col>
                                    </Row>

                                </CardBody>

                            </Card>
                        </Col>
                    </Row>

                </div>

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    //remove apex from traditional as lobby of it not created
    const newGameObj = {
        ...state.Games?.game,
        modeObj: {
            ...state.Games?.game.modeObj,
            // 3: state.Games?.game.modeObj[3]?.filter(
            //   (game) => game.slug !== "apex-legends"
            // ),
        },
    };
    return {
        game: newGameObj,
    };
};

export default withRouter(connect(mapStateToProps)(LobbyForm));
