import React, { useEffect, useState } from "react";

import { Col, Row, FormGroup, Button, Label } from "reactstrap";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
    getGlobalSettings,
    setGlobalSettings,
} from "../../services/globalSettings_api_helper";
import { toast } from "react-toastify";
import toastrOptions from "../../helpers/toastr-options/toastr-options";
import {
    AvForm,
    AvField,
    AvInput,
    AvGroup,
} from "availity-reactstrap-validation";
import SweetAlert from "react-bootstrap-sweetalert";
import FilterPermission from "../../helpers/FilterPermission";
import { filterOutPermissionToShowHide } from "../../helpers/PermissionUtils";
import info from "../../assets/images/info-icon.svg";
import {
    MESSAGES,
    permissionsStrings,
    SUCCESS_MESSAGES,
} from "../../helpers/StringConstant";
const GlobalSettings = (props) => {
    const [globals, setGlobal] = useState([]);
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [loader, setLoader] = useState(false);
    const [state, setState] = useState({
        referee_percentage: "",
        ref_bonus_amount: "",
        affiliate_days: 0,
        ref_threshold_amount: "",
        ref_threshold_expire: 0,
        favourite_friend: "",
        team_owner_mod_time: "",
        team_member_mod_time: "",
        max_pinned_demo_count: "",
        inactivity_timeline: "",
        inactivity_charges: "",
        inactivity_reminder: "",
        free_lobby_limit: "",
        pay_per_sign_up_referral_system: false,
        tier_1_amount_free_lobby: 0,
        tier_2_amount_paid_lobby: 0,
        tier_system_payout_days: 0,
    });
    const [openModal, setOpenModal] = useState(false);
    const [values, setValues] = useState({});
    const [changePermission, setChange] = useState(false);
    useEffect(() => {
        getGlobalListing();
        if (props.permission.length !== 0) {
            callSetPermission();
        } else {
            setChange(true);
        }
    }, []);
    const callSetPermission = () => {
        const type = permissionsStrings.globalSetting;
        const typeChange = permissionsStrings.typeChangeConfig;
        const filteredPermission = FilterPermission(props.permission, type);
        if (filteredPermission.length !== 0) {
            const setchange = filterOutPermissionToShowHide(
                filteredPermission[0].permissions,
                typeChange
            );
            setChange(setchange);
        }
    };
    const getGlobalListing = () => {
        setLoader(true);
        getGlobalSettings().then((res) => {
            setGlobal(res);
            setState(res);
            setLoader(false);
        });
    };

    const handleValidSubmit = async (event, formValues) => {
        event.preventDefault();
        openAlert();
        setValues({ event, values: formValues });
    };
    const handleValidSubmit2 = async (event, valuesToSubmit) => {
        const model = {
            referee_percentage: valuesToSubmit.referee_percentage,
            ref_bonus_amount: valuesToSubmit.ref_bonus_amount,
            affiliate_days: valuesToSubmit.affiliate_days,
            favourite_friend: valuesToSubmit.favourite_friend,
            ref_threshold_amount: valuesToSubmit.ref_threshold_amount,
            ref_threshold_expire: valuesToSubmit.ref_threshold_expire,
            team_owner_mod_time: parseInt(valuesToSubmit.team_owner_mod_time),
            team_member_mod_time: parseInt(valuesToSubmit.team_member_mod_time),
            max_pinned_demo_count: parseInt(valuesToSubmit.max_pinned_demo_count),
            inactivity_timeline: parseInt(valuesToSubmit.inactivity_timeline),
            inactivity_charges: parseInt(valuesToSubmit.inactivity_charges),
            inactivity_reminder: parseInt(valuesToSubmit.inactivity_reminder),
            free_lobby_limit: valuesToSubmit.free_lobby_limit,
            pay_per_sign_up_referral_system: valuesToSubmit.pay_per_sign_up_referral_system,
            tier_1_amount_free_lobby: valuesToSubmit.tier_1_amount_free_lobby,
            tier_2_amount_paid_lobby: valuesToSubmit.tier_2_amount_paid_lobby,
            tier_system_payout_days: valuesToSubmit.tier_system_payout_days,
     
        };
        setLoader(true);
        await setGlobalSettings(model)
            .then((res) => {
                setLoader(false);
                toast.success(SUCCESS_MESSAGES.updateSuccess, toastrOptions);
                getGlobalListing();
                setState({
                    referee_percentage: "",
                    ref_bonus_amount: "",
                    affiliate_days: 0,
                    favourite_friend: "",
                    ref_threshold_amount: "",
                    ref_threshold_expire: 0,
                    team_owner_mod_time: "",
                    team_member_mod_time: "",
                    max_pinned_demo_count: "",
                    inactivity_timeline: "",
                    inactivity_charges: "",
                    inactivity_reminder: "",
                    free_lobby_limit: "",
                    pay_per_sign_up_referral_system: false,
                    tier_1_amount_free_lobby: 0,
                    tier_2_amount_paid_lobby: 0,
                    tier_system_payout_days: 0,
                
                });
            })
            .catch((err) => {
                setLoader(false);
            });
    };
    const handleChange = (event) => {
        const value = event.target.value;
        setState({
            ...state,
            [event.target.name]: value,
        });
    };

    const openAlert = () => {
        setOpenModal(true);
    };
    const confirmBox = () => {
        handleValidSubmit2(values.event, values.values);
        setOpenModal(false);
    };
    const closeAlert = () => {
        setOpenModal(false);
    };

    useEffect(() => {
        if (
            state?.affiliate_days == globals.affiliate_days &&
            state.ref_bonus_amount == globals.ref_bonus_amount &&
            state.referee_percentage == globals.referee_percentage &&
            state.favourite_friend == globals?.favourite_friend &&
            state.ref_threshold_amount == globals?.ref_threshold_amount &&
            state.ref_threshold_expire == globals?.ref_threshold_expire &&
            state.team_owner_mod_time == globals?.team_owner_mod_time &&
            state.team_member_mod_time == globals?.team_member_mod_time &&
            state.max_pinned_demo_count == globals?.max_pinned_demo_count &&
            state.inactivity_timeline == globals?.inactivity_timeline &&
            state.inactivity_charges == globals?.inactivity_charges &&
            state.inactivity_reminder == globals?.inactivity_reminder &&
            state.free_lobby_limit == globals?.free_lobby_limit &&
            state.pay_per_sign_up_referral_system == globals?.pay_per_sign_up_referral_system &&
            state.tier_1_amount_free_lobby == globals?.tier_1_amount_free_lobby &&
            state.tier_2_amount_paid_lobby == globals?.tier_2_amount_paid_lobby &&
            state.tier_system_payout_days == globals?.tier_system_payout_days 
        ) {
            setDisableSubmit(true);
        } else {
            setDisableSubmit(false);
        }
    }, [state, globals]);

    const validateInactivityRemnder = (value, ctx, input, cb) => {
        cb(
            parseInt(value) <= parseInt(state.inactivity_timeline)
                ? true
                : MESSAGES.InactivityReminder
        );
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs breadcrumbItem="GLOBAL SETTINGS" />
                <Row>
                    {loader ? (
                        <div
                            class="spinner-grow spinner-class"
                            role="status"
                            style={{ marginTop: "40px" }}
                        >
                            <span class="sr-only">Loading...</span>
                        </div>
                    ) : (
                        <Col className="col-12">
                            <AvForm
                                onValidSubmit={(e, v) => {
                                    handleValidSubmit(e, v);
                                }}
                            >
                                <fieldset disabled={!changePermission}>
                                    <div className="col-5 mb-3 d-flex earningTimelines-box">
                                        <AvField
                                            name="referee_percentage"
                                            label={
                                                <>
                                                    Manage Referrer Earnings Percentage*
                                                    <span className="info-icon stats-infoIcon">
                                                        <img
                                                            src={info}
                                                            alt="info"
                                                            className="info-image"
                                                            data-tip
                                                            data-for="loginTip"
                                                        />
                                                        <span class="tooltiptext">
                                                            The deposit transferred to referee's account after
                                                            successful signup of a referral.
                                                        </span>
                                                    </span>
                                                </>
                                            }
                                            value={globals.referee_percentage}
                                            onChange={handleChange}
                                            type="number"
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage: "Manage referrer earnings percentage is required",
                                                },
                                                pattern: {
                                                    value: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/,
                                                    errorMessage:
                                                        "Manage referrer earnings percentage can't be negative",
                                                },
                                            }}
                                        />
                                        <div className="daysBox">
                                            <span class="input-group-addon manageDeposit">%</span>
                                        </div>
                                    </div>
                                    <div className="col-5 mb-3 d-flex earningTimelines-box">
                                        <AvField
                                            name="affiliate_days"
                                            label={
                                                <>
                                                    Referrer Earnings Timeline*
                                                    <span className="info-icon stats-infoIcon">
                                                        <img
                                                            src={info}
                                                            alt="info"
                                                            className="info-image"
                                                            data-tip
                                                            data-for="loginTip"
                                                        />
                                                        <span class="tooltiptext">
                                                            No. of days the referee will earn from the
                                                            referral's lobbies.
                                                        </span>
                                                    </span>
                                                </>
                                            }
                                            defaultValue={globals.affiliate_days}
                                            onChange={handleChange}
                                            type="number"
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage: "Referrer earnings timeline is required",
                                                },
                                                pattern: {
                                                    value: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/,
                                                    errorMessage: "Referrer earnings timeline can't be negative",
                                                },
                                            }}
                                        />
                                        <div className="daysBox">
                                            <span class="input-group-addon">
                                                {state.affiliate_days == 0 &&
                                                    globals.affiliate_days >= 1
                                                    ? globals.affiliate_days <= 1
                                                        ? "Day"
                                                        : "Days"
                                                    : state.affiliate_days <= 1
                                                        ? "Day"
                                                        : "Days"}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-5 mb-3 d-flex earningTimelines-box">
                                        <AvGroup>
                                            <Label> Manage Referee Promo Bonus*</Label>
                                            <AvInput
                                                name="ref_bonus_amount"
                                                defaultValue={globals.ref_bonus_amount}
                                                onChange={handleChange}
                                                type="number"
                                                validate={{
                                                    required: {
                                                        value: true,
                                                        errorMessage: "Manage Referee Promo Bonus is required",
                                                    },
                                                    pattern: {
                                                        value: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/,
                                                        errorMessage:
                                                            "Manage Referee Promo Bonus can't be negative",
                                                    },
                                                }}
                                            />
                                        </AvGroup>
                                        <div className="daysBox">
                                            <span class="input-group-addon managePromoBonus">$</span>
                                        </div>
                                    </div>

                                    <div className="col-5 mb-3 d-flex earningTimelines-box">
                                        <AvField
                                            name="ref_threshold_amount"
                                            label={
                                                <>
                                                    Manage Referee Threshold Amount*
                                                    <span className="info-icon stats-infoIcon">
                                                        <img
                                                            src={info}
                                                            alt="info"
                                                            className="info-image"
                                                            data-tip
                                                            data-for="loginTip"
                                                        />
                                                        <span class="tooltiptext">
                                                            The minimum amount required to add into the wallet
                                                            to avail promo bonus by the referral.
                                                        </span>
                                                    </span>
                                                </>
                                            }
                                            defaultValue={globals.ref_threshold_amount}
                                            onChange={handleChange}
                                            type="number"
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage: "Manage Referee threshold amount is required",
                                                },
                                                pattern: {
                                                    value: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/,
                                                    errorMessage:
                                                        "Manage Referee threshold amount can't be negative",
                                                },
                                            }}
                                        />
                                        <div className="daysBox">
                                            <span class="input-group-addon managePromoBonus">$</span>
                                        </div>
                                    </div>
                                    <div className="col-5 mb-3 d-flex earningTimelines-box">
                                        <AvField
                                            name="ref_threshold_expire"
                                            label={
                                                <>
                                                    Manage Referee Threshold Expiree*
                                                    <span className="info-icon stats-infoIcon">
                                                        <img
                                                            src={info}
                                                            alt="info"
                                                            className="info-image"
                                                            data-tip
                                                            data-for="loginTip"
                                                        />
                                                        <span class="tooltiptext">
                                                            No. of days by which the promo bonus will expire.
                                                        </span>
                                                    </span>
                                                </>
                                            }
                                            defaultValue={globals.ref_threshold_expire}
                                            onChange={handleChange}
                                            type="number"
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage: "Manage referee threshold expiree is required",
                                                },
                                                pattern: {
                                                    value: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/,
                                                    errorMessage:
                                                        "Manage referee threshold expiree can't be negative",
                                                },
                                            }}
                                        />
                                        <div className="daysBox">
                                            <span class="input-group-addon">
                                                {state.ref_threshold_expire == 0 &&
                                                    globals.ref_threshold_expire >= 1
                                                    ? globals.ref_threshold_expire <= 1
                                                        ? "Day"
                                                        : "Days"
                                                    : state.ref_threshold_expire <= 1
                                                        ? "Day"
                                                        : "Days"}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-5 mb-3 d-flex earningTimelines-box">
                                        <AvField
                                            name="favourite_friend"
                                            label="Favorite Friends*"
                                            defaultValue={globals.favourite_friend}
                                            onChange={handleChange}
                                            type="text"
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage: "Favorite friends is required",
                                                },
                                                number: {
                                                    value: true,
                                                    errorMessage: "Please enter number only",
                                                },
                                                min: {
                                                    value: 3,
                                                    errorMessage: "Favorite friends can't be less than 3",
                                                },
                                                max: {
                                                    value: 6,
                                                    errorMessage:
                                                        "Favorite friends can't be greater than 6",
                                                },
                                            }}
                                        />
                                    </div>
                                    <div className="col-5 mb-3 d-flex earningTimelines-box">
                                        <AvField
                                            name="team_owner_mod_time"
                                            label={
                                                <>
                                                    Team Creator Modification Time*
                                                    <span className="info-icon stats-infoIcon">
                                                        <img
                                                            src={info}
                                                            alt="info"
                                                            className="info-image"
                                                            data-tip
                                                            data-for="loginTip"
                                                        />
                                                        <span class="tooltiptext">
                                                            The buffer time during which a team creator can
                                                            make changes before the lobby starts.
                                                        </span>
                                                    </span>
                                                </>
                                            }
                                            defaultValue={globals.team_owner_mod_time}
                                            onChange={handleChange}
                                            type="text"
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage:
                                                        "Team creator modification time is required",
                                                },
                                                number: {
                                                    value: true,
                                                    errorMessage: "Please enter number only",
                                                },
                                                min: {
                                                    value: 10,
                                                    errorMessage:
                                                        "Team creator modification time can't be less than 10 minutes",
                                                },
                                                pattern: {
                                                    value: /^[1-9][0-9]*$/,
                                                    errorMessage:
                                                        "Team creator modification time can't be in decimals",
                                                },
                                            }}
                                        />
                                        <div className="daysBox">
                                            <span class="input-group-addon minutesBox">Minutes</span>
                                        </div>
                                    </div>
                                    <div className="col-5 mb-3 d-flex earningTimelines-box">
                                        <AvField
                                            name="team_member_mod_time"
                                            label={
                                                <>
                                                    Team Member Modification Time*
                                                    <span className="info-icon stats-infoIcon">
                                                        <img
                                                            src={info}
                                                            alt="info"
                                                            className="info-image"
                                                            data-tip
                                                            data-for="loginTip"
                                                        />
                                                        <span class="tooltiptext">
                                                            The buffer time during which a team member can
                                                            make changes before the lobby starts.
                                                        </span>
                                                    </span>
                                                </>
                                            }
                                            defaultValue={globals.team_member_mod_time}
                                            onChange={handleChange}
                                            type="text"
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage:
                                                        "Team member modification time is required",
                                                },
                                                number: {
                                                    value: true,
                                                    errorMessage: "Please enter number only",
                                                },
                                                min: {
                                                    value: 15,
                                                    errorMessage:
                                                        "Team member modification time can't be less than 15 minutes",
                                                },
                                                pattern: {
                                                    value: /^[1-9][0-9]*$/,
                                                    errorMessage:
                                                        "Team member modification time can't be in decimals",
                                                },
                                            }}
                                        />
                                        <div className="daysBox">
                                            <span class="input-group-addon minutesBox">Minutes</span>
                                        </div>
                                    </div>
                                    <div className="col-5 mb-3 d-flex earningTimelines-box">
                                        <AvField
                                            name="max_pinned_demo_count"
                                            label="Maximum Pinned Demo*"
                                            value={globals.max_pinned_demo_count}
                                            onChange={handleChange}
                                            type="text"
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage: "Maximum pinned demo is required",
                                                },
                                                number: {
                                                    value: true,
                                                    errorMessage: "Please enter number only",
                                                },
                                                min: {
                                                    value: 0,
                                                    errorMessage:
                                                        "Maximum pinned demo value can't be less than 0",
                                                },
                                                pattern: {
                                                    value: /^[0-9][0-9]*$/,
                                                    errorMessage:
                                                        "Maximum pinned demo value can't be in decimals",
                                                },
                                            }}
                                        />
                                    </div>
                                    <div className="col-5 mb-3 d-flex earningTimelines-box">
                                        <AvField
                                            name="inactivity_timeline"
                                            label={
                                                <>
                                                    Inactivity Timeline*
                                                    <span className="info-icon stats-infoIcon">
                                                        <img
                                                            src={info}
                                                            alt="info"
                                                            className="info-image"
                                                            data-tip
                                                            data-for="loginTip"
                                                        />
                                                        <span class="tooltiptext">
                                                            No. of days the player has not enrolled in any
                                                            lobby.
                                                        </span>
                                                    </span>
                                                </>
                                            }
                                            value={globals.inactivity_timeline}
                                            onChange={handleChange}
                                            type="text"
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage: "Inactivity Timeline is required",
                                                },
                                                number: {
                                                    value: true,
                                                    errorMessage: "Please enter number only",
                                                },
                                                min: {
                                                    value: 0,
                                                    errorMessage:
                                                        "Inactivity Timeline value can't be less than 0",
                                                },
                                                pattern: {
                                                    value: /^[0-9][0-9]*$/,
                                                    errorMessage:
                                                        "Inactivity Timeline value can't be in decimals",
                                                },
                                            }}
                                        />
                                        <div className="daysBox">
                                            <span class="input-group-addon">Days</span>
                                        </div>
                                    </div>
                                    <div className="col-5 mb-3 d-flex earningTimelines-box">
                                        <AvField
                                            name="inactivity_reminder"
                                            label="Inactivity Reminder*"
                                            value={`${state.inactivity_reminder}`}
                                            onChange={handleChange}
                                            type="text"
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage: "Inactivity Reminder is required",
                                                },
                                                number: {
                                                    value: true,
                                                    errorMessage: "Please enter number only",
                                                },
                                                min: {
                                                    value: 0,
                                                    errorMessage:
                                                        "Inactivity Reminder value can't be less than 0",
                                                },
                                                pattern: {
                                                    value: /^[0-9][0-9]*$/,
                                                    errorMessage:
                                                        "Inactivity Reminder value can't be in decimals",
                                                },
                                                async: validateInactivityRemnder,
                                            }}
                                        />
                                        <div className="daysBox">
                                            <span class="input-group-addon">Days</span>
                                        </div>
                                    </div>
                                    <div className="col-5 mb-3 d-flex earningTimelines-box">
                                        <AvField
                                            name="inactivity_charges"
                                            label={
                                                <>
                                                    Inactivity Charges*
                                                    <span className="info-icon stats-infoIcon">
                                                        <img
                                                            src={info}
                                                            alt="info"
                                                            className="info-image"
                                                            data-tip
                                                            data-for="loginTip"
                                                        />
                                                        <span class="tooltiptext">
                                                            The amount deducted for the inactive account on
                                                            monthly basis.
                                                        </span>
                                                    </span>
                                                </>
                                            }
                                            value={`${globals.inactivity_charges}`}
                                            onChange={handleChange}
                                            type="text"
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage: "Inactivity Charges is required",
                                                },
                                                number: {
                                                    value: true,
                                                    errorMessage: "Please enter number only",
                                                },
                                                min: {
                                                    value: 0,
                                                    errorMessage:
                                                        "Inactivity Charges can't be less than 0",
                                                },
                                                pattern: {
                                                    value: /^[0-9][0-9]*$/,
                                                    errorMessage:
                                                        "Inactivity Charges can't be in decimals",
                                                },
                                            }}
                                        />
                                        <div className="daysBox">
                                            <span class="input-group-addon managePromoBonus">$</span>
                                        </div>
                                    </div>{" "}
                                    <div className="col-5 mb-3 d-flex earningTimelines-box">
                                        <AvField
                                            name="free_lobby_limit"
                                            label={
                                                <>
                                                    Free Lobby Limit*
                                                    <span className="info-icon stats-infoIcon">
                                                        <img
                                                            src={info}
                                                            alt="info"
                                                            className="info-image"
                                                            data-tip
                                                            data-for="loginTip"
                                                        />
                                                        <span class="tooltiptext">
                                                            Maximum number of free lobbies per users (changes
                                                            will be applied for new users).
                                                        </span>
                                                    </span>
                                                </>
                                            }
                                            value={`${state.free_lobby_limit}`}
                                            onChange={handleChange}
                                            type="text"
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage: "Free lobby limit is required",
                                                },
                                                number: {
                                                    value: true,
                                                    errorMessage: "Please enter number only",
                                                },
                                                min: {
                                                    value: 0,
                                                    errorMessage: "Free lobby limit can't be less than 0",
                                                },
                                                pattern: {
                                                    value: /^(0|[1-9]\d*)(e-?(0|[1-9]\d*))?$/,
                                                    errorMessage:
                                                        "Free lobby limit value can't be in decimals",
                                                },
                                            }}
                                        />
                                    </div>
                             
                                    <div className="col-5 mb-3 d-flex earningTimelines-box">
                                        <AvField
                                            style={{ backgroundColor: 'white' }}
                                            name="pay_per_sign_up_referral_system"
                                            label={
                                                <>
                                                    Enable Pay Per Signup Referral*
                                                </>
                                            }
                                            type="select"
                                            onChange={handleChange}
                                            value={state.pay_per_sign_up_referral_system.toString()}
                                            required
                                        >
                                            <option value={true}>Enabled</option>
                                            <option value={false}>Disabled</option>
                                        </AvField>
                                    </div>
                            
                                    <div className="col-5 mb-3 d-flex earningTimelines-box">
                                        <AvGroup>
                                            <Label> Manage Tier 1 (free lobby amount)*</Label>
                                            <AvInput
                                                name="tier_1_amount_free_lobby"
                                                defaultValue={globals.tier_1_amount_free_lobby}
                                                onChange={handleChange}
                                                type="number"
                                                validate={{
                                                    required: {
                                                        value: true,
                                                        errorMessage: "Manage Tier 1 (free lobby amount) is required",
                                                    },
                                                    pattern: {
                                                        value: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/,
                                                        errorMessage:
                                                            "Manage Tier 1 (free lobby amount) can't be negative",
                                                    },
                                                }}
                                            />
                                        </AvGroup>
                                        <div className="daysBox">
                                            <span class="input-group-addon managePromoBonus">$</span>
                                        </div>
                                    </div>
                                    <div className="col-5 mb-3 d-flex earningTimelines-box">
                                        <AvGroup>
                                            <Label> Manage Tier 2 (paid lobby amount)*</Label>
                                            <AvInput
                                                name="tier_2_amount_paid_lobby"
                                                defaultValue={globals.tier_2_amount_paid_lobby}
                                                onChange={handleChange}
                                                type="number"
                                                validate={{
                                                    required: {
                                                        value: true,
                                                        errorMessage: "Manage Tier 2 (paid lobby amount) is required",
                                                    },
                                                    pattern: {
                                                        value: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/,
                                                        errorMessage:
                                                            "Manage Tier 2 (paid lobby amount) can't be negative",
                                                    },
                                                }}
                                            />
                                        </AvGroup>
                                        <div className="daysBox">
                                            <span class="input-group-addon managePromoBonus">$</span>
                                        </div>
                                    </div>
                                    <div className="col-5 mb-3 d-flex earningTimelines-box">
                                        <AvField
                                            name="tier_system_payout_days"
                                            label={
                                                <>
                                                    Manage Tier Threshold Expiree*
                                                    <span className="info-icon stats-infoIcon">
                                                        <img
                                                            src={info}
                                                            alt="info"
                                                            className="info-image"
                                                            data-tip
                                                            data-for="loginTip"
                                                        />
                                                        <span class="tooltiptext">
                                                            No. of days Manage Tier Threshold Expiree.
                                                        </span>
                                                    </span>
                                                </>
                                            }
                                            defaultValue={globals.tier_system_payout_days}
                                            onChange={handleChange}
                                            type="number"
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage: "Manage Tier Threshold Expiree is required",
                                                },
                                                pattern: {
                                                    value: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/,
                                                    errorMessage: "Manage Tier Threshold Expiree can't be negative",
                                                },
                                            }}
                                        />
                                        <div className="daysBox">
                                            <span class="input-group-addon">
                                                {state.tier_system_payout_days == 0 &&
                                                    globals.tier_system_payout_days >= 1
                                                    ? globals.tier_system_payout_days <= 1
                                                        ? "Day"
                                                        : "Days"
                                                    : state.tier_system_payout_days <= 1
                                                        ? "Day"
                                                        : "Days"}
                                            </span>
                                        </div>
                                    </div>
                                    <FormGroup className="mt-4">
                                        <div>
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className="ms-1"
                                                disabled={disableSubmit}
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                        {openModal ? (
                                            <SweetAlert
                                                title="Confirmation"
                                                warning
                                                showCancel
                                                confirmButtonText="Yes"
                                                confirmBtnBsStyle="success"
                                                cancelButtonText="No"
                                                cancelBtnBsStyle="danger"
                                                onConfirm={() => confirmBox()}
                                                onCancel={() => closeAlert()}
                                                focusCancelBtn
                                            >
                                                Are you sure you want to continue ?
                                            </SweetAlert>
                                        ) : null}
                                    </FormGroup>
                                </fieldset>
                            </AvForm>
                        </Col>
                    )}
                </Row>
            </div>
        </React.Fragment>
    );
};

export default GlobalSettings;
